<template>
  <div :id="'wisdom_param_main_element_' + f_getOptionLocId()">
    <!-- <b-row :style="(['object_list_options', 'list_parameter', 'object_list'].indexOf(option_param_mother.parameters[param].type) === -1 || option_loc.length === 2) && option_param_mother.view_mode && option_param_mother.view_mode === 'column_mode' ? 'margin: 1px; width: 33%; display: inline-grid; border-top: solid 1px #7bd9b3; border: solid 1px #7bd9b3; border-left: solid 1px #7bd9b3; border-bottom: solid 1px #7bd9b3; height: 100%;' : 'margin: 1px; border: 1px solid rgba(158, 193, 179, 0.3); min-height: 30px;'" v-if="data[param] && option_param_mother.parameters[param]" :key="'wisdom_param_key_' + f_getOptionLocId()"> -->
    <b-row style="margin: 1px; border: 1px solid rgba(158, 193, 179, 0.3); min-height: 30px;" v-if="data[param] && option_param_mother.parameters[param]" :key="'wisdom_param_key_' + f_getOptionLocId()">
      <!-- <b-col sm="12" md="12" :lg="option_param_mother.view_mode && option_param_mother.view_mode === 'column_mode' ? '12' : '4'"> -->
      <b-col sm="12" md="12" lg="12">
        <b-row>
          <b-col cols="12" style="padding-left: 3px; padding-top: 0px;">
            <template v-if="f_aiParamControl()">
              <img src="@/icon/2324511.png" style="width: 2em;" />
              <span :class="[CalculateParamClass()]" style="color: red;">
                <template v-if="option_param_mother.parameters[param].image_info && option_param_mother.parameters[param].image_info.data !== '' && option_param_mother.parameters[param].image_info.side === 'icon'" style="margin: 0px;">
                  <img :src="option_param_mother.parameters[param].image_info.data" style="width: 2em; cursor: zoom-in;" @click="f_previewImage(option_param_mother.parameters[param].image_info.data)"/>
                </template>
                {{ option_param_mother.parameters[param].name.label.toLocaleLowerCase('TR') }}
              </span>
            </template>
            <template v-else>
              <template v-if="option_param_mother.parameters[param].image_info && option_param_mother.parameters[param].image_info.data !== '' && option_param_mother.parameters[param].image_info.side === 'icon'" style="margin: 0px;">
                <img :src="option_param_mother.parameters[param].image_info.data" style="width: 2em; cursor: zoom-in;" @click="f_previewImage(option_param_mother.parameters[param].image_info.data)" />
              </template>
              <span :class="[CalculateParamClass()]">
              {{ option_param_mother.parameters[param].name.label.toLocaleLowerCase('TR') }}
            </span>
            </template>
            <template v-if="option_param_mother.parameters[param].info">
              <b-badge :id="'param-info_' + param" variant="info">i</b-badge>
              <!-- <i class="fa fa-warning" :id="'param-info_' + param" style="padding-left: 3px; color: red"></i> -->
              <b-popover :target="'param-info_' + param" triggers="hover focus">
                <template slot="title">{{ $t('wdm16.1675') }}</template>
                {{ option_param_mother.parameters[param].info.toLocaleLowerCase('TR') }}
              </b-popover>
            </template>
            <span v-if="option_param_mother.parameters[param].required === 'yes' && !data[param]['val']" style="margin: 3px; color: red;">*</span>
            <div v-if="false && param ==='staging_group'" style="margin: 3px;">
              <b-button variant="success" v-if="show_staging_group_selectable" @click="get_staging_details()">
                <i class="fa fa-check-circle"></i> {{ $t('wdm16.11450') }}
              </b-button>
              <b-button variant="success" v-else @click="new_staging_group()">
                <i class="fa fa-check-circle"></i> {{ $t('wdm16.11451') }}
              </b-button>
            </div>
            <div v-if="param ==='stage'">
              <span @click="stage_automatic_func()" v-if="stage_automatic" style="cursor: pointer; margin: 3px; color: green;">
                {{ $t('wdm16.11452') }}:
                <i class="fa fa-check-circle"></i>
              </span>
              <span @click="stage_automatic_func()" v-else style="cursor: pointer; margin: 3px; color: red;">
                {{ $t('wdm16.11452') }}:
                <i class="fa fa-minus-circle"></i>
              </span>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="option_param_mother.parameters[param].image_info && option_param_mother.parameters[param].image_info.data !== '' && option_param_mother.parameters[param].image_info.side === 'bottom'">
          <b-col cols="12" style="padding-left: 3px; padding-top: 0px;">
            <img :src="option_param_mother.parameters[param].image_info.data" style="width: 100px; cursor: zoom-in;" @click="f_previewImage(option_param_mother.parameters[param].image_info.data)" />
          </b-col>
        </b-row>
        <b-row>
          <!-- <b-col sm="12" md="12" lg="9" style="padding: 3px;" @dblclick="f_dblClickToParamType()"> -->
          <b-col sm="12" md="12" lg="9" style="padding: 3px;">
            <span v-if="!show_staging_group_selectable && !stage_data.stage_order && param === 'staging_group'">{{ $t('wdm16.11453') }}... ({{ $t('wdm16.11454') }})</span>
            <template v-if="option_param_mother.parameters[param].type === 'list_parameter'">
              <template v-if="option_param_mother.parameters[param].disabled === undefined || (option_param_mother.parameters[param].disabled !== undefined && option_param_mother.parameters[param].disabled !== 1)">
                <b-button variant="success" size="sm" style="min-width: 100px;" :title="$t('wdm16.11456')" @click="add_list_param(data, param, option_param_mother.parameters[param])">
                  <i class="fa fa-plus"></i> <small>{{ option_param_mother.parameters[param].name.label }}</small>
                </b-button>
              </template>
            </template>
            <template v-if="option_param_mother.parameters[param].option_search && option_param_mother.parameters[param].option_search === 'get_icd_codes'">
              <!-- <template v-if="is_all_diagnosis"> -->
              <template v-if="diagnosis_filter_type === 'all'">
                <span style="color: red;">{{ $t('wdm16.11455') }}</span>
                <b-input-group-append>
                  <b-form-input :disabled="option_param_mother.parameters[param].disabled === 1 && !rule_engine && !polestar" style="color: green;" @keydown.enter.native="GetClickKeyDisease" v-model="searching_disease_text" :placeholder="$t('wdm16.11457')"></b-form-input>
                  <b-button size="sm" variant="primary" @click="SearchWithSearchingDiseaseText()"><i class="fa fa-plus"></i> {{ $t('wdm16.10985') }}</b-button>
                </b-input-group-append>
                <v-select :ref="'wisdom_param_input_' + f_getOptionLocId()" :disabled="option_param_mother.parameters[param].disabled === 1 && !rule_engine && !polestar" v-model="d_valTextTimer.text" name="diagnosis" :placeholder="diagnosis_options.length.toString() + ' ' + $t('wdm16.11458')" :options="diagnosis_options">
                  <template slot="option" slot-scope="option">
                    <div style="white-space: pre-line; border-bottom: solid 1px #c8ced3; vertical-align: middle; color: #323442;">
                      <template v-if="option.image_info && option.image_info.data">
                        <template v-if="option.image_info.side === 'icon'">
                          <img :src="option.image_info.data" style="cursor: zoom-in;width: 30px;"> {{ option.label }}
                        </template>
                        <template v-else-if="option.image_info.side === 'bottom'">
                          {{ option.label }}
                          <br>
                          <img :src="option.image_info.data" style="cursor: zoom-in;width: 100px;">
                        </template>
                      </template>
                      <template v-else>
                        {{ option.label }}
                      </template>
                    </div>
                  </template>
                </v-select>
                <!-- v-select içerisinden direk arama yapmak için fonk. @search="SearchDiagnosisOptions" -->
              </template>
              <template v-else>
                <span :id="'wisdom_param_input_' + f_getOptionLocId()"></span>
                <v-select :ref="'wisdom_param_input_' + f_getOptionLocId()" :disabled="option_param_mother.parameters[param].disabled === 1 && !rule_engine && !polestar" v-model="d_valTextTimer.text" name="diagnosis" :options="calculate_options(param, option_param_mother.parameters[param])">
                  <template slot="option" slot-scope="option">
                    <div style="white-space: pre-line; border-bottom: solid 1px #c8ced3; vertical-align: middle; color: #323442;">
                      <template v-if="option.image_info && option.image_info.data">
                        <template v-if="option.image_info.side === 'icon'">
                          <img :src="option.image_info.data" style="cursor: zoom-in;width: 30px;"> {{ option.label }}
                        </template>
                        <template v-else-if="option.image_info.side === 'bottom'">
                          {{ option.label }}
                          <br>
                          <img :src="option.image_info.data" style="cursor: zoom-in;width: 100px;">
                        </template>
                      </template>
                      <template v-else>
                        {{ option.label }}
                      </template>
                    </div>
                  </template>
                </v-select>
              </template>
            </template>
            <template v-if="option_param_mother.parameters[param].option_search && option_param_mother.parameters[param].option_search === 'drugs'">
              <template v-if="drug_filter_type === 'all'">
                <span style="color: red;">{{ $t('wdm16.11455') }}</span>
                <b-input-group-append>
                  <b-form-input :disabled="option_param_mother.parameters[param].disabled === 1 && !rule_engine && !polestar" style="color: green;" @keydown.enter.native="GetClickKey" v-model="searching_drug_text" :placeholder="$t('wdm16.11459')"></b-form-input>
                  <b-button :disabled="option_param_mother.parameters[param].disabled === 1 && !rule_engine && !polestar" size="sm" variant="primary" @click="SearchWithSearchingDrugText()"><i class="fa fa-plus"></i> ara</b-button>
                </b-input-group-append>
                <v-select :disabled="option_param_mother.parameters[param].disabled === 1 && !rule_engine && !polestar" v-model="d_valTextTimer.text" name="drug_search" :placeholder="$t('wdm16.11460')" :options="drug_search_list">
                  <template slot="option" slot-scope="option">
                    <div style="white-space: pre-line; border-bottom: solid 1px #c8ced3; vertical-align: middle; color: #323442;">
                      <span> {{ option.label }} </span>
                    </div>
                  </template>
                </v-select>
                <!-- v-select içerisinden direkt arama yapmak için fonk :on-search="drug_search"  -->
              </template>
              <template v-else>
                <!-- <span :id="'wisdom_param_input_' + f_getOptionLocId()"></span> -->
                <v-select :ref="'wisdom_param_input_' + f_getOptionLocId()" :disabled="option_param_mother.parameters[param].disabled === 1 && !rule_engine && !polestar" v-model="d_valTextTimer.text" name="favorite_drugs" :options="calculate_options(param, option_param_mother.parameters[param])">
                  <template slot="option" slot-scope="option">
                    <div style="white-space: pre-line; border-bottom: solid 1px #c8ced3; vertical-align: middle; color: #323442;">
                      <template v-if="option.image_info && option.image_info.data">
                        <template v-if="option.image_info.side === 'icon'">
                          <img :src="option.image_info.data" style="cursor: zoom-in;width: 30px;"> {{ option.label }}
                        </template>
                        <template v-else-if="option.image_info.side === 'bottom'">
                          {{ option.label }}
                          <br>
                          <img :src="option.image_info.data" style="cursor: zoom-in;width: 100px;">
                        </template>
                      </template>
                      <template v-else>
                        {{ option.label }}
                      </template>
                    </div>
                  </template>
                </v-select>
              </template>
            </template>
            <template v-if="option_param_mother.parameters[param].option_search && option_param_mother.parameters[param].option_search === 'drug_medical_equipment_brands'">
              <template>
                <span style="color: red;">{{ $t('wdm16.11455') }}</span>
                <b-input-group-append>
                  <b-form-input :disabled="option_param_mother.parameters[param].disabled === 1 && !rule_engine && !polestar" style="color: green;" @keydown.enter.native="GetClickKeyBrand" v-model="searching_brand_text" :placeholder="$t('wdm16.11459')"></b-form-input>
                  <b-button :disabled="option_param_mother.parameters[param].disabled === 1 && !rule_engine && !polestar" size="sm" variant="primary" @click="f_searchBrand()"><i class="fa fa-plus"></i> ara</b-button>
                </b-input-group-append>
                <v-select :disabled="option_param_mother.parameters[param].disabled === 1 && !rule_engine && !polestar" v-model="d_valTextTimer.text" name="drug_medical_equipment_brands" :placeholder="$t('wdm16.11461')" :options="d_brandSearchList">
                  <template slot="option" slot-scope="option">
                    <div style="white-space: pre-line; border-bottom: solid 1px #c8ced3; vertical-align: middle; color: #323442;">
                      <template v-if="option.image_info && option.image_info.data">
                        <template v-if="option.image_info.side === 'icon'">
                          <img :src="option.image_info.data" style="cursor: zoom-in;width: 30px;"> {{ option.label }}
                        </template>
                        <template v-else-if="option.image_info.side === 'bottom'">
                          {{ option.label }}
                          <br>
                          <img :src="option.image_info.data" style="cursor: zoom-in;width: 100px;">
                        </template>
                      </template>
                      <template v-else>
                        {{ option.label }}
                      </template>
                    </div>
                  </template>
                </v-select>
                <!-- v-select içerisinden direkt arama yapmak için fonk :on-search="drug_search"  -->
              </template>
            </template>
            <b-form-file v-if="option_param_mother.parameters[param].type === 'image'" @input="f_addDocument()" v-model="d_tmpFile" accept="jpeg,jpg,png" :placeholder="$t('wdm16.11462')" :drop-placeholder="$t('wdm16.11463')"></b-form-file>
            <b-form-input v-if="option_param_mother.parameters[param].type === 'date'" :disabled="option_param_mother.parameters[param].disabled === 1 && !rule_engine && !polestar" type="date" v-model="data[param]['val']" :name="param" @input="option_selection" :id="'wisdom_param_input_' + f_getOptionLocId()"></b-form-input>
            <b-form-input v-if="option_param_mother.parameters[param].type === 'time'" :disabled="option_param_mother.parameters[param].disabled === 1 && !rule_engine && !polestar" type="time" v-model="data[param]['val']" :name="param" @input="option_selection" :id="'wisdom_param_input_' + f_getOptionLocId()"></b-form-input>
            <datepicker v-if="option_param_mother.parameters[param].type === 'datetime'" :disabled="option_param_mother.parameters[param].disabled === 1 && !rule_engine && !polestar" :config="{ enableTime: true }" class="form-control" v-model="data[param]['val']" :name="param" @input="f_paramChangeFunc()" :id="'wisdom_param_input_' + f_getOptionLocId()"></datepicker>
            <template v-if="CalculateShowVselect() &&(!option_param_mother.parameters[param].selectbox_type || (option_param_mother.parameters[param].selectbox_type && option_param_mother.parameters[param].selectbox_type === 'vselect'))">
              <span :id="'wisdom_param_input_' + f_getOptionLocId()"></span>
              <template v-if="stage_automatic && param === 'stage'">
                {{ data.stage.val && data.stage.val.label ? data.stage.val.label : $t('wdm16.11464') }}
              </template>
              <template v-else>
                <v-select :disabled="(option_param_mother.parameters[param].disabled === 1 && !rule_engine && !polestar)" name="selectop" :options="calculate_options(param, option_param_mother.parameters[param])" v-model="d_valTextTimer.text" :ref="'wisdom_param_input_' + f_getOptionLocId()" :id="'wisdom_param_input_' + f_getOptionLocId()">
                  <template slot="option" slot-scope="option">
                    <div style="white-space: pre-line; border-bottom: solid 1px #c8ced3; vertical-align: middle; color: #323442;">
                      <template v-if="option.image_info && option.image_info.data">
                        <template v-if="option.image_info.side === 'icon'">
                          <img :src="option.image_info.data" style="cursor: zoom-in;width: 30px;"> {{ option.label }}
                        </template>
                        <template v-else-if="option.image_info.side === 'bottom'">
                          {{ option.label }}
                          <br>
                          <img :src="option.image_info.data" style="cursor: zoom-in;width: 100px;">
                        </template>
                      </template>
                      <template v-else>
                        {{ option.label }}
                      </template>
                    </div>
                  </template>
                </v-select>
              </template>
            </template>
            <template v-if="CalculateShowVselect() && option_param_mother.parameters[param].selectbox_type === 'listbox'">
              <b-row>
                <b-col cols="12">
                  <b-form-input v-model="d_searchInOptions" :placeholder="$t('wdm16.11465')"></b-form-input>
                </b-col>
              </b-row>
              <template v-if="stage_automatic && param === 'stage'">
                {{ data.stage.val && data.stage.val.label ? data.stage.val.label : $t('wdm16.11464') }}
              </template>
              <template v-else>
                <b-form-select :disabled="(option_param_mother.parameters[param].disabled === 1 && !rule_engine && !polestar)" name="selectop" ref="param" v-model="data[param]['val']" v-on:change="option_selection" :select-size="4" :id="'wisdom_param_input_' + f_getOptionLocId()">
                  <template v-for="opt in calculate_options(param, option_param_mother.parameters[param])">
                    <template v-if="f_searchInOptions(opt)">
                      <template v-if="option_param_mother.parameters[param].type === 'object_list'">
                        <option :value="opt" v-on:dblclick="f_addOptionAsObject(data, data[param], option_param_mother.parameters[param])">{{ opt.label }}</option>
                      </template>
                      <template v-else>
                        <option :value="opt">{{ opt.label }}</option>
                      </template>
                    </template>
                  </template>
                </b-form-select>
              </template>
            </template>
            <input v-if="['integer','float'].indexOf(option_param_mother.parameters[param].type) !== -1" :disabled="option_param_mother.parameters[param].disabled === 1 && !rule_engine && !polestar" v-model="data[param]['val']" class="form-control" type="number" @mouseup="f_paramChangeFunc()" @keyup="f_paramChangeFunc()" :name="param" :min="option_param_mother.parameters[param].min ? option_param_mother.parameters[param].min : ''" :max="option_param_mother.parameters[param].max && option_param_mother.parameters[param].max > 0 ? option_param_mother.parameters[param].max : ''" :id="'wisdom_param_input_' + f_getOptionLocId()">
            <input v-if="option_param_mother.parameters[param].type === 'text'" :disabled="option_param_mother.parameters[param].disabled === 1 && !rule_engine && !polestar" v-model="data[param]['val']" class="form-control" type="text" :name="param" :maxlength="option_param_mother.parameters[param].max && option_param_mother.parameters[param].max > 0 ? option_param_mother.parameters[param].max : ''" :id="'wisdom_param_input_' + f_getOptionLocId()">
            <b-textarea v-if="option_param_mother.parameters[param].type === 'textarea'" :disabled="option_param_mother.parameters[param].disabled === 1 && !rule_engine && !polestar" v-model="d_valTextTimer.text" style="width: 100%;" :rows="option_param_mother.parameters[param].rows ? option_param_mother.parameters[param].rows : ''" :maxlength="option_param_mother.parameters[param].max && option_param_mother.parameters[param].max > 0 ? option_param_mother.parameters[param].max : ''" :id="'wisdom_param_input_' + f_getOptionLocId()"></b-textarea>
            <template v-if="option_param_mother.parameters[param].type === 'plain_text'">
              <span :id="'wisdom_param_input_' + f_getOptionLocId()" v-if="data[param]['val'] && data[param]['val'].label">{{ data[param]['val'].label }}</span>
              <span :id="'wisdom_param_input_' + f_getOptionLocId()" v-else>{{ data[param]['val'] }}</span>
            </template>
            <template v-if="option_param_mother.parameters[param].type === 'checkbox'">
              <b-form-group label="" :id="'wisdom_param_input_' + f_getOptionLocId()">
                <b-form-checkbox-group :disabled="option_param_mother.parameters[param].disabled === 1 && !rule_engine && !polestar" stacked v-model="d_valTextTimer.text" :id="'id-' + option_param_mother.parameters[param].name.value" :name="option_param_mother.parameters[param].name.value" @input="f_paramChangeFunc('checkbox')" @change="f_paramChangeFunc('checkbox')">
                  <template v-for="(op, op_ind) in calculate_options(param, option_param_mother.parameters[param])">
                    <b-form-checkbox :key="'calculated_options_'+ op_ind" :value="op.value">
                      <template v-if="op.image_info && op.image_info.data">
                        <template v-if="op.image_info.side === 'icon'">
                          <img :src="op.image_info.data" style="cursor: zoom-in;width: 30px;" @click="f_previewImage(op.image_info.data)">
                        </template>
                      </template>
                      {{ op.label }}
                    </b-form-checkbox>
                    <template v-if="op.image_info && op.image_info.data">
                      <template v-if="op.image_info.side === 'bottom'">
                        <b-row>
                          <b-col cols="12">
                            <img :src="op.image_info.data" style="cursor: zoom-in;width: 100px;" @click="f_previewImage(op.image_info.data)">
                          </b-col>
                        </b-row>
                      </template>
                    </template>
                  </template>
                </b-form-checkbox-group>
              </b-form-group>
            </template>
            <template v-if="option_param_mother.parameters[param].type === 'html_editor'">
              <html-editor :p_editorData="d_valTextTimer"></html-editor>
            </template>
            <template v-if="option_param_mother.parameters[param].type === 'radiogroup'">
              <b-form-group label="" :id="'wisdom_param_input_' + f_getOptionLocId()">
                <b-form-radio-group :ref="'wisdom_param_input_' + f_getOptionLocId()" :disabled="option_param_mother.parameters[param].disabled === 1 && !rule_engine && !polestar" v-model="d_valTextTimer.text" @input="f_paramChangeFunc('radiogroup')" @change="f_paramChangeFunc('radiogroup')">
                  <template v-for="(op, op_ind) in calculate_options(param, option_param_mother.parameters[param])">
                    <b-row>
                      <b-col cols="12">
                        <b-form-radio :key="'radiogroup_' + op_ind.toString() + '_' +  f_getOptionLocId()" :value="op.value">
                          <template v-if="op.image_info && op.image_info.data">
                            <template v-if="op.image_info.side === 'icon'">
                              <img :src="op.image_info.data" style="cursor: zoom-in;width: 30px;" @click="f_previewImage(op.image_info.data)">
                            </template>
                          </template>
                          {{ op.label }}
                        </b-form-radio>
                      </b-col>
                    </b-row>
                    <template v-if="op.image_info && op.image_info.data">
                      <template v-if="op.image_info.side === 'bottom'">
                        <b-row style="margin: 0px;">
                          <b-col cols="12">
                            <img :src="op.image_info.data" style="cursor: zoom-in;width: 100px;" @click="f_previewImage(op.image_info.data)">
                          </b-col>
                        </b-row>
                      </template>
                    </template>
                  </template>
                </b-form-radio-group>
              </b-form-group>
            </template>
            <template v-if="rule_engine">
              <span v-if="!data[param]['val'] && option_param_mother.parameters[param].rule" style="color: red;">lütfen bir değer giriniz</span>
            </template>
          </b-col>
          <b-col sm="12" md="12" lg="3" style="padding: 3px;">
            <!-- <b-col sm="12" md="2" :lg="option_param_mother.view_mode && option_param_mother.view_mode === 'column_mode' ? '12' : '2'"> -->
            <b-form-select :disabled="option_param_mother.parameters[param].disabled === 1 && !rule_engine && !polestar" v-if="option_param_mother.parameters[param].option_search && option_param_mother.parameters[param].option_search === 'get_icd_codes'" v-model="diagnosis_filter_type">
              <option name="patient" value="patient">{{ $t('wdm16.4234') }}</option>
              <option name="favorite" value="favorite">{{ $t('wdm16.3778') }}</option>
              <option name="all" value="all">{{ $t('wdm16.3732') }}</option>
              <!-- <option v-for="size in d_sizeUnitList" :value="size">{{ size.label }}</option> -->
            </b-form-select>
            <b-form-select :disabled="option_param_mother.parameters[param].disabled === 1 && !rule_engine && !polestar" v-if="option_param_mother.parameters[param].option_search && option_param_mother.parameters[param].option_search === 'drugs'" v-model="drug_filter_type">
              <!-- <option name="patient" value="patient">Hasta İlaçları</option> -->
              <option name="favorite" value="favorite">{{ $t('wdm16.3778') }}</option>
              <option name="all" value="all">{{ $t('wdm16.3732') }}</option>
              <!-- <option v-for="size in d_sizeUnitList" :value="size">{{ size.label }}</option> -->
            </b-form-select>
            <b-button :disabled="option_param_mother.parameters[param].disabled === 1 && !rule_engine && !polestar" block variant="success" v-if="data_type === 'wdm5' && param === 'treatment_chemotherapy' && data[param] && data[param].val && data[param].val.value && data[param].val.value !== ''" @click="f_addDrugFromChemotherapy()">
              <i class="fa fa-plus"></i> {{ $t('wdm16.11466') }}
            </b-button>
            <b-form-select :disabled="option_param_mother.parameters[param].disabled === 1 && !rule_engine && !polestar" v-if="option_param_mother.parameters[param].show_dosage_units" v-model="data[param]['unit']" name="d_dosageUnitList" v-on:change="f_paramChangeFunc">
              <option v-for="dosage in d_dosageUnitList" :value="dosage">{{ dosage.label }}</option>
            </b-form-select>
            <b-form-select :disabled="option_param_mother.parameters[param].disabled === 1 && !rule_engine && !polestar" v-if="option_param_mother.parameters[param].show_size_units" v-model="data[param]['unit']" name="d_sizeUnitList" v-on:change="f_paramChangeFunc">
              <option v-for="size in d_sizeUnitList" :value="size">{{ size.label }}</option>
            </b-form-select>
            <b-form-select :disabled="option_param_mother.parameters[param].disabled === 1 && !rule_engine && !polestar" v-if="option_param_mother.parameters[param].show_weight_units" v-model="data[param]['unit']" name="d_weightUnitList" v-on:change="f_paramChangeFunc">
              <option v-for="weight in d_weightUnitList" :value="weight">{{ weight.label }}</option>
            </b-form-select>
            <b-button :id="'wisdom_param_input_' + f_getOptionLocId()" :disabled="option_param_mother.parameters[param].disabled === 1 && !rule_engine && !polestar" size="sm" variant="success" v-if="option_param_mother.parameters[param].type === 'object_list'" @click="f_addOptionAsObject(data, data[param], option_param_mother.parameters[param])">
              <i class="fa fa-plus"></i>
            </b-button>
            <!-- SAMI -->
            <b-button :disabled="option_param_mother.parameters[param].disabled === 1 && !rule_engine && !polestar" size="sm" variant="success" v-if="data_type === 'wdm30' && param === 'protocol_list'" @click="f_selectProtocolForWdm30()">
              <i class="fa fa-plus"></i>
            </b-button>
            <b-button v-if="rule_engine && rule_param_type_list.indexOf(option_param_mother.parameters[param].type) !== -1" size="sm" variant="secondary" @click="f_addEditRule()"> {{ $t('wdm16.11468') }} </b-button>
            <!-- <b-dropdown id="rule_engine_form" text="rule" ref="dropdown" class="p-1" size="sm"> <b-dropdown-text style="width: 500px; padding: 0px;"> </b-dropdown-text> </b-dropdown> -->
            <b-row v-if="option_param_mother.parameters[param].type === 'image' && data[param].val !== ''" style="margin: 0px;">
              <b-col cols="12">
                <b-button variant="danger" size="md" @click="f_clearImage()">{{ $t('wdm16.11467') }}</b-button>
              </b-col>
            </b-row>
            <b-row v-if="data[param].val && data[param].val.image_info && data[param].val.image_info.data !== ''" style="margin: 0px;">
              <b-col cols="12">
                <b-button variant="secondary" :title="$t('wdm16.11469')" size="md" @click="f_previewImage(data[param].val.image_info.data)">
                  <img src="@/icon/2910059.png" style="width: 2em;" />
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <!-- xxxxxxxxxxxxxxxxxxxxxxxxx -->
      <!-- <b-col sm="10" md="10" :lg="option_param_mother.view_mode && option_param_mother.view_mode === 'column_mode' ? '12' : '6'"></b-col> -->
      <!-- xxxxxxxxxxxxxxxxxxxxxxxxx -->
    </b-row>
    <b-row v-if="option_param_mother.parameters[param].type === 'image' && data[param].val !== ''" style="margin: 0px;">
      <b-col cols="12">
        <img :src="data[param].val" style="width: 50%;">
        <!-- <a :href="data[param].val" download>Dökümanı İndir</a> -->
      </b-col>
    </b-row>
    <template v-if="f_calculatePatientSearchModalButton()">
      <b-row>
        <b-col cols="12" class="text-center">
          <b-button variant="success" block @click="f_showSearchPatient()"><i class="fa fa-search"></i> {{ $t('wdm16.10985') }}</b-button>
        </b-col>
      </b-row>
    </template>
    <wisdom-data-rule v-if="d_ruleEditMode" :option_data="option_data" :column_list="column_list" :option_loc="option_loc" :query_list="query_list" :data_type="data_type" :query_list_type="query_list_type" :record_loc="data" :param_data="option_param_mother.parameters[param]" :wdm_name="wdm_name" :param_change="param_change" :polestar="polestar"></wisdom-data-rule>
    <b-modal v-if="d_previewImage.show" :title="$t('wdm16.11213')" centered class="modal-success" v-model="d_previewImage.show" @ok="d_previewImage.show = false" ok-variant="success" hide-footer>
      <b-row>
        <b-col cols="12">
          <img :src="d_previewImage.data" style="width: 100%;">
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  default as HtmlEditor
} from '@/components/widgets/HtmlEditor';
import vSelect from "vue-select";
import Datepicker from "wisdom-vue-datepicker";
// import { tr } from "../../../node_modules/flatpickr/dist/l10n/tr";
import { mapGetters } from "vuex";
// import moment from 'moment';
import {
  default as Anatomy
} from "./Anatomy";
import {
  default as WisdomDataRule
} from "./WisdomDataRule";
import {
  default as WisdomDataRuleB
} from "./WisdomDataRuleB";
import {
  default as Modal
} from "@/components/widgets/Modal";
import {
  default as WisdomDataPoleStar
} from "./WisdomDataPoleStar";
import OptionService from "@/services/option";
import RulePackageService from "@/services/rule_package";
// import DiagnosisService from '@/services/diagnosis';
import HospitalService from '@/services/hospital';
import ProtocolService from "@/services/public/protocol";
import MessengerService from "@/services/messenger";
import OrganizationService from "@/services/organization";
import PatientService from '@/services/patient';
import GlobalService from '@/services/global';

export default {
  name: "WisdomParam",
  props: {
    wdm_version_wisdomera: {
      type: Object,
      required: false
    },
    data: {
      type: Object,
      required: false
    },
    option_data: {
      type: Object,
      required: false
    },
    stage_data: {
      type: Object,
      required: false
    },
    option_param_mother: {
      type: Object,
      required: true
    },
    data_type: {
      type: String,
      required: true
    },
    wdm_name: {
      type: Object,
      required: true
    },
    anatomy: {
      type: Object,
      required: false
    },
    param: {
      type: String,
      required: true
    },
    level: {
      type: String,
      required: true
    },
    param_change: {
      type: Object,
      required: true
    },
    query_list_type: {
      type: String,
      required: false
    },
    rule_engine: {
      type: Boolean,
      required: false,
      default: false
    },
    polestar: {
      type: Boolean,
      required: false
    },
    query_list: {
      type: Array,
      required: false
    },
    column_list: {
      type: Array,
      required: false
    },
    option_loc: {
      type: Array,
      required: false
    },
    external_patient_data: {
      type: Object,
      required: false
    },
    p_wdmrSettings: {
      type: Object,
      required: true
    },
    test_mode: {
      type: Boolean,
      required: false,
      default: false
    },
    d_showModal: {
      type: Object,
      required: false
    },
    d_patientSearchModalData: {
      type: Object,
      required: false
    },
    p_aiRobotMatchParameterResult: {
      type: Object,
      required: false
    },
    p_wdmrData: {
      type: Object,
      required: false
    }
  },
  created () {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (!this.data[this.param]) {
      if (['object_list_options', 'list_parameter'].indexOf(this.option_param_mother.parameters[this.param].type) === -1) {
        if (['checkbox'].indexOf(this.option_param_mother.parameters[this.param].type) !== -1) {
          this.data[this.param] = { 'val': [] };
        } else {
          this.data[this.param] = { 'val': '' };
        }
      } else {
        this.data[this.param] = {};
      }
    }
    if (this.external_patient_data) {
      this.patient = JSON.parse(JSON.stringify(this.external_patient_data))
    } else {
      this.patient = JSON.parse(JSON.stringify(this.patient_tmp))
    }
    if (this.option_param_mother.parameters[this.param].show_size_units) {
      this.data[this.param]['unit'] = { 'label': 'cm', 'value': 'cm' };
      this.d_sizeUnitList = [{ 'label': 'cm', 'value': 'cm' }, { 'label': 'inch', 'value': 'inch' }];
    }
    if (this.option_param_mother.parameters[this.param].show_dosage_units) {
      // this.data[this.param]['unit'] = {};
      this.d_dosageUnitList = require("@/options/unit").options;
    }
    if (this.option_param_mother.parameters[this.param].show_weight_units) {
      this.data[this.param]['unit'] = { 'label': 'kg', 'value': 'kg' };
      this.d_weightUnitList = [{ 'label': 'kg', 'value': 'kg' }, { 'label': 'lbs', 'value': 'lbs' }];
    }
    if (['wdm17'].indexOf(this.data_type) !== -1 && ['wdm_group', 'wdm_type'].indexOf(this.param) !== -1) {
      if (this.data.selected_patient_hospital_group && this.data.selected_patient_hospital_group.val && this.data.selected_patient_hospital_group.val !== '' && this.data.selected_patient_hospital_id && this.data.selected_patient_hospital_id.val && this.data.selected_patient_hospital_id.val !== '') {
        this.d_wdmTypeAndVersionList = { 'wisdom': [], 'user': [], 'hospital': [] };
        let hospital_bucket_key_list = [];
        let user_key = 'wdm_version_user';
        let hospital_key = 'wdm_version_hospital_' + this.data.selected_patient_hospital_group.val + '_' + this.data.selected_patient_hospital_id.val;
        hospital_bucket_key_list.push(user_key);
        hospital_bucket_key_list.push(hospital_key);
        GlobalService.get_multiple_cb_documents({ 'key_list': hospital_bucket_key_list, 'bucket': 'hospital' })
          .then(resp => {
            if (resp.data.status === 'success') {
              if (resp.data.result[user_key]) {
                this.d_wdmTypeAndVersionList.user = JSON.parse(JSON.stringify(resp.data.result[user_key]));
              }
              if (resp.data.result[hospital_key]) {
                this.d_wdmTypeAndVersionList.hospital = JSON.parse(JSON.stringify(resp.data.result[hospital_key]));
              }
            }
            this.d_wdmTypeAndVersionList.wisdom = this.wdm_version_wisdomera;
            let data = {}
            data.hospital_group = this.data.selected_patient_hospital_group.val;
            data.hospital_id = this.data.selected_patient_hospital_id.val;
            data.wtl_type = 'department';
            HospitalService.get_hospital_wtl_as_select(data)
              .then(resp => {
                // console.log(resp.data.result);
                this.option_param_mother.parameters['selected_hospital_department']['options'] = resp.data.result;
                this.f_paramChangeFunc();
              });
          });
      }
    }
    this.d_paramData = this.data[this.param];
    this.$forceUpdate();
  },
  mounted () {
    // console.log(this.param);
    // console.log('mounted', this.param);
    if (this.option_param_mother.parameters[this.param].option_search) {
      if (this.option_param_mother.parameters[this.param].option_search === "get_diagnosis" && !this.rule_engine && !this.polestar) {
        this.get_patient_diagnosis_options();
      } else if (this.option_param_mother.parameters[this.param].option_search === "get_icd_codes") {
        this.get_patient_diagnosis_options();
      } else if (this.option_param_mother.parameters[this.param].option_search === "drugs") {
        // this.get_patient_drug_options();
      } else if (this.option_param_mother.parameters[this.param].option_search === "get_oncology_treatment_protocols") {
        this.get_chemotherapy_protocols();
      } else if (this.option_param_mother.parameters[this.param].option_search === "rule_packages") {
        // rule bucket silindiği için hata vermemesi için kaldırıldı
        // this.BringPackages();
      } else if (this.option_param_mother.parameters[this.param].option_search === "get_frequency_list") {
        this.d_frequencyList = require("@/options/frequency").options;
      } else if (this.option_param_mother.parameters[this.param].option_search === "fluids") {
        this.d_fluidList = require('@/options/fluids').options;
      } else if (this.option_param_mother.parameters[this.param].option_search === "get_dosage_form_list") {
        this.d_dosageFormList = require('@/options/dosage_form').options;
      } else if (this.option_param_mother.parameters[this.param].option_search === "get_user_hospital_code_list") {
        this.get_user_hospital_code_list();
      } else if (this.option_param_mother.parameters[this.param].option_search === "get_user_wdm_list") {
        this.get_user_wdm_list();
      } else if (this.option_param_mother.parameters[this.param].option_search === "get_members_for_messenger_group") {
        this.f_getMembersForMessengerGroup();
      } else if (this.option_param_mother.parameters[this.param].option_search === "get_patient_data_project_list") {
        this.f_getPatientDataProjectsList();
      } else if (this.option_param_mother.parameters[this.param].option_search === "get_laboratory_process_list") {
        this.f_getLaboratoryProcessList();
      } else if (this.option_param_mother.parameters[this.param].option_search === "get_radiology_process_list") {
        this.f_getRadiologyProcessList();
      } else if (this.option_param_mother.parameters[this.param].option_search === "get_other_process_list") {
        this.f_getOtherProcessList();
      } else if (this.option_param_mother.parameters[this.param].option_search === "get_patient_open_protocol_list") {
        this.f_getPatientOpenProtocolList();
      } else if (this.option_param_mother.parameters[this.param].option_search === "get_hospital_department_list") {
        this.f_getHospitalDepartmentList();
      } else if (this.option_param_mother.parameters[this.param].option_search === "get_hospital_wtl_list" && this.option_param_mother.parameters[this.param].option_search_hospital_wtl) {
        this.f_getHospitalWtlData();
      } else if (this.option_param_mother.parameters[this.param].option_search === "get_all_user_list") {
        this.f_getAllUsersList();
      } else if (this.option_param_mother.parameters[this.param].option_search === "get_hospital_groups_list") {
        this.f_hospitalGroupList();
      } else if (this.option_param_mother.parameters[this.param].option_search === "get_hospitals_of_selected_hospital_group") {
        this.f_hospitalGroupList('get_hospitals_of_selected_hospital_group');
      } else if (this.option_param_mother.parameters[this.param].option_search === "get_departments_of_selected_hospital_of_selected_hospital_group") {
        this.f_getDepartmentListOfSelectedHospitalOfSelectedHospitalGroup();
      } else if (this.option_param_mother.parameters[this.param].option_search && this.option_param_mother.parameters[this.param].option_search === "get_patient_wdmr_list" && this.option_param_mother.parameters[this.param].option_search_patient_wdmr) {
        this.f_calculatePatientWdmrList(this.option_param_mother.parameters[this.param].option_search_patient_wdmr);
      }
    }
    if (this.data_type === 'diagnosis') {
      this.diagnosis_filter_type = 'favorite';
    }
    if (this.data_type === 'wisdom_data_model_3') {
      this.drug_filter_type = 'all';
    }
    if (this.test_mode) {
      this.diagnosis_filter_type = 'favorite';
      this.drug_filter_type = 'favorite';
    }
    if (this.data.staging_group) {
      // this.get_staging_details();
    }
    // If we found a result that the selected parameter is this param by ai analyze, we open the options area.
    if (this.f_aiParamControl()) {
      this.f_controlToOpenSelectByAi();
    }
    if (['radiogroup'].indexOf(this.option_param_mother.parameters[this.param].type) !== -1) {
      if (this.data[this.param].val && this.data[this.param].val.value) {
        this.d_valTextTimer.text = this.data[this.param].val.value;
      }
    } else if (['select', 'object_list'].indexOf(this.option_param_mother.parameters[this.param].type) !== -1) {
      this.d_valTextTimer.text = this.data[this.param].val;
    } else if (['checkbox'].indexOf(this.option_param_mother.parameters[this.param].type) !== -1) {
      let opt_list = [];
      for (let i in this.data[this.param].val) {
        opt_list.push(this.data[this.param].val[i].value);
      }
      this.d_valTextTimer.text = opt_list;
    } else {
      if (['list_parameter', 'object_list_options'].indexOf(this.option_param_mother.parameters[this.param].type) === -1) {
        this.d_valTextTimer.text = this.data[this.param].val;
      }
    }

    /*
    if (this.option_param_mother.parameters[this.param].type === 'textarea') {
    }
    */
    if (this.p_wdmrSettings.wisdom_param.mode === 2 || this.p_wdmrSettings.wisdom_param.mode === 3) {
      if (['select', 'object_list'].indexOf(this.option_param_mother.parameters[this.param].type) !== -1) {
        setTimeout(function () {
          if (this.$refs['wisdom_param_input_' + this.f_getOptionLocId()]) {
            this.$refs['wisdom_param_input_' + this.f_getOptionLocId()].open = true;
            this.$refs['wisdom_param_input_' + this.f_getOptionLocId()].$refs.search.focus();
            // console.log(this.$refs['wisdom_param_input_' + this.f_getOptionLocId()].$refs);
            // this.$refs['wisdom_param_input_' + this.f_getOptionLocId()].$el.focus();
          }
        }.bind(this), 50)
      } else if (['radiogroup'].indexOf(this.option_param_mother.parameters[this.param].type) !== -1) {
        if (this.$refs['wisdom_param_input_' + this.f_getOptionLocId()]) {
          this.$refs['wisdom_param_input_' + this.f_getOptionLocId()].autofocus = true;
        }
      } else {
        setTimeout(function () {
          let input_id = 'wisdom_param_input_' + this.f_getOptionLocId();
          let doc = document.getElementById(input_id);
          if (doc) {
            doc.focus();
          }
        }.bind(this), 50);
      }
    }
  },
  computed: mapGetters({
    lang: "lang",
    patient_tmp: "patient_selected"
  }),
  data () {
    return {
      d_hoverImage: { 'timeout': '', 'data': '' },
      d_previewImage: { 'data': '', 'show': false },
      d_oldValue: '',
      d_tmpFile: '',
      d_valTextTimer: { 'timeout': '', 'text': '' },
      d_searchInOptions: '',
      d_hospitalsOfSelectedHospitalGroup: [],
      d_departmentsOfSelectedHospitalOfSelectedHospitalGroup: [],
      d_hospitalGroupList: [],
      d_hospitalGroupData: '',
      d_ruleEditMode: false,
      user: {},
      rule_param_type_list: ['radiogroup', 'checkbox', 'select', 'integer', 'float', 'textarea', 'text', 'date', 'time', 'datetime', 'object_list', 'object_list_options', 'plain_text'],
      polestar_param_type_list: ['radiogroup', 'checkbox', 'select', 'integer', 'float', 'textarea', 'text', 'date', 'time', 'datetime', 'object_list', 'object_list_options', 'plain_text'],
      patient: {},
      // datepicker_langs: { tr: tr, en: {} },
      packages: [],
      anatomy_use: "any",
      schema_scope: "public",
      patient_drug_options: [],
      patient_diagnosis_options: [],
      chemotherapy_protocol_options: [],
      diagnosis_options: [],
      is_all_drugs: false,
      is_all_diagnosis: false,
      drug_filter_type: 'favorite',
      diagnosis_filter_type: 'patient',
      show_staging_group_selectable: true,
      stage_automatic: true,
      favorite_drugs: require("@/options/favorite_drugs").options,
      favorite_diagnosis_list: require("@/options/favorite_diagnosis_c_codes").options,
      wdm10_delay_reasons: require("@/options/wdm10_delay_reasons").options,
      dosage_change_reasons: require("@/options/dosage_change_reasons").options,
      drug_search_list: [],
      select_types: ["select", "object_list"],
      selected_anatomy_region: {},
      searching_drug_text: '',
      searching_disease_text: '',
      d_sizeUnitList: [],
      d_dosageUnitList: [],
      d_weightUnitList: [],
      d_frequencyList: [],
      d_fluidList: [],
      d_dosageFormList: [],
      d_patientOpenProtocolList: [],
      d_hospitalDepartmentList: [],
      d_hospitalWtlData: [],
      user_hospital_code_list: [],
      d_user_wdm_list: [],
      d_patientWdmrList: [],
      laboratory_process_list: [],
      radiology_process_list: [],
      other_process_list: [],
      d_brandSearchList: [],
      searching_brand_text: '',
      d_membersForMessengerGroup: [],
      d_patientDataProjectsList: [],
      d_wdmTypeAndVersionList: { 'wisdom': [], 'user': [], 'hospital': [] },
      d_allUserList: [],
      d_paramData: {}
    };
  },
  methods: {
    f_previewImage: function (image_data) {
      this.d_previewImage.data = image_data;
      this.d_previewImage.show = true;
    },
    f_clearImage: function () {
      this.data[this.param] = { 'val': '' };
    },
    f_addDocument: function () {
      let file_name = this.d_tmpFile.name;
      let arr = this.d_tmpFile.name.split('.');
      let fileExtension = arr[arr.length - 1];
      // console.log(fileExtension);
      if (["jpg", "jpeg", "png"].indexOf(fileExtension.toLowerCase()) === -1) {
        this.d_tmpFile = '';
        this.$forceUpdate();
        alert(this.$t('wdm16.12634'));
        return;
      }
      if (this.d_tmpFile.size > 512000) {
        this.d_tmpFile = '';
        this.$forceUpdate();
        alert('Dosya boyutu 500 kb dan fazla olamaz. Lütfen resim boyutunu küçültünüz.');
        return;
      }

      var reader = new FileReader();
      var vm = this;
      let doc_data = {
        'user': this.user.username,
        'information': '',
        'fileExtension': fileExtension,
        'file_name': file_name
      };
      this.data[this.param].image_info = doc_data;
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        vm.data[vm.param].val = reader.result;
      }, false);
      reader.readAsDataURL(this.d_tmpFile);
      this.d_tmpFile = '';
    },
    f_getOptionLocId: function () {
      let str_index_list = '';
      for (let i in this.option_loc) {
        str_index_list += this.option_loc[i].type + '-wisdommodsiw-' + this.option_loc[i].value;
        if (parseInt(i) !== this.option_loc.length - 1) {
          str_index_list += '___';
        }
      }
      return str_index_list;
    },
    f_dblClickToParamType: function () {
      if (this.data[this.param] && (this.option_param_mother.parameters[this.param].disabled === undefined || (this.option_param_mother.parameters[this.param].disabled !== undefined && this.option_param_mother.parameters[this.param].disabled !== 1))) {
        if (['object_list_options', 'list_parameter'].indexOf(this.option_param_mother.parameters[this.param].type) === -1) {
          if (['checkbox'].indexOf(this.option_param_mother.parameters[this.param].type) !== -1) {
            this.data[this.param] = { 'val': [] };
          } else {
            this.data[this.param] = { 'val': '' };
          }
        } else {
          this.data[this.param] = {};
        }
      }
      this.$forceUpdate();
    },
    f_searchInOptions: function (opt) {
      if (this.d_searchInOptions) {
        if (opt.label.toLocaleLowerCase().indexOf(this.d_searchInOptions) !== -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    f_getDepartmentListOfSelectedHospitalOfSelectedHospitalGroup: function () {
      let data = {}
      data.hospital_group = 'MLPCARE';
      data.hospital_id = this.option_loc[this.option_loc.length - 2].value;
      data.wtl_type = 'department';
      HospitalService.get_hospital_wtl_as_select(data)
        .then(resp => {
          this.d_departmentsOfSelectedHospitalOfSelectedHospitalGroup = resp.data.result;
          this.f_paramChangeFunc();
        });
    },
    f_hospitalGroupList: function (type) {
      if (this.user) {
        HospitalService.get_hospital_group()
          .then(resp => {
            if (resp.data.status === 'success') {
              this.d_hospitalGroupData = resp.data.result.hospital_groups;
              for (let i in resp.data.result.hospital_groups) {
                let hos_group = { 'label': resp.data.result.hospital_groups[i].label, 'value': resp.data.result.hospital_groups[i].value };
                this.d_hospitalGroupList.push(hos_group);
                if (type === 'get_hospitals_of_selected_hospital_group') {
                  // -2 will be the hospital_group detail key which is selected.
                  if (hos_group.value === this.option_loc[this.option_loc.length - 2].value) {
                    this.d_hospitalsOfSelectedHospitalGroup = resp.data.result.hospital_groups[i].hospital_list;
                  }
                }
              }
            } else {
              console.log('Error: ', resp.data.message);
            }
          });
      }
    },
    f_getAllUsersList: function () {
      let allusers = JSON.parse(localStorage.getItem('all_users'));
      for (let i in allusers) {
        this.d_allUserList.push({ 'label': allusers[i]['first_name'] + ' ' + allusers[i]['last_name'], 'value': allusers[i]['username'] });
      }
    },
    f_getHospitalWtlData: function () {
      let data = {}
      data.hospital_group = this.user.hospital_group;
      data.hospital_id = this.user.hospital_id;
      data.wtl_type = this.option_param_mother.parameters[this.param].option_search_hospital_wtl;
      HospitalService.get_hospital_wtl_as_select(data)
        .then(resp => {
          // console.log(resp.data.result);
          this.d_hospitalWtlData = resp.data.result;
          this.f_paramChangeFunc();
        });
    },
    f_getHospitalDepartmentList: function () {
      let data = {}
      data.hospital_group = this.user.hospital_group;
      data.hospital_id = this.user.hospital_id;
      data.wtl_type = 'department';
      HospitalService.get_hospital_wtl_as_select(data)
        .then(resp => {
          // console.log(resp.data.result);
          this.d_hospitalDepartmentList = resp.data.result;
          this.f_paramChangeFunc();
        });
    },
    f_getPatientOpenProtocolList: function () {
      let data = { 'patient_id': this.patient.id };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Hastanın açık protokolleri getiriliyor' } });
      PatientService.get_open_protocols_for_wdm_by_patient_id(data)
        .then(resp => {
          console.log(resp.data);
          this.d_patientOpenProtocolList = resp.data.result;
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        });
    },
    f_getLaboratoryProcessList: function () {
      GlobalService.get_one_cb_document({ 'key': 'option_processlist', 'bucket': 'option' })
        .then(resp => {
          for (let i in resp.data.result.list) {
            if (resp.data.result.list[i].type === 'laboratory') {
              this.laboratory_process_list.push(resp.data.result.list[i]);
            }
          }
        });
    },
    f_getRadiologyProcessList: function () {
      GlobalService.get_one_cb_document({ 'key': 'option_processlist', 'bucket': 'option' })
        .then(resp => {
          for (let i in resp.data.result.list) {
            if (resp.data.result.list[i].type === 'radiology') {
              this.radiology_process_list.push(resp.data.result.list[i]);
            }
          }
        });
    },
    f_getOtherProcessList: function () {
      GlobalService.get_one_cb_document({ 'key': 'option_processlist', 'bucket': 'option' })
        .then(resp => {
          for (let i in resp.data.result.list) {
            if (resp.data.result.list[i].type === 'other') {
              this.other_process_list.push(resp.data.result.list[i]);
            }
          }
        });
    },
    f_controlToOpenSelectByAi: function () {
      if (['select', 'object_list'].indexOf(this.option_param_mother.parameters[this.param].type) !== -1) {
        setTimeout(function () {
          // console.log(this.$refs.param_options);
          // this.$refs.param_options.select();
          if (this.$refs['wisdom_param_input_' + this.f_getOptionLocId()]) {
            this.$refs['wisdom_param_input_' + this.f_getOptionLocId()].open = true;
          }
        }.bind(this), 1000)
      }
    },
    f_controlToCloseSelectByAi: function () {
      if (['select', 'object_list'].indexOf(this.option_param_mother.parameters[this.param].type) !== -1) {
        setTimeout(function () {
          // console.log(this.$refs['wisdom_param_input_' + this.f_getOptionLocId()]);
          // this.$refs['wisdom_param_input_' + this.f_getOptionLocId()].select();
          if (this.$refs['wisdom_param_input_' + this.f_getOptionLocId()] && this.$refs['wisdom_param_input_' + this.f_getOptionLocId()].open) {
            this.$refs['wisdom_param_input_' + this.f_getOptionLocId()].open = false;
          }
        }.bind(this), 1000)
      }
    },
    f_aiParamControl: function () {
      if (!this.p_aiRobotMatchParameterResult || (this.p_aiRobotMatchParameterResult.pr && this.p_aiRobotMatchParameterResult.pr.length === 0)) {
        return false;
      }
      let arr = [];
      for (let i in this.option_loc) {
        if (['list', 'anatomy', 'detail'].indexOf(this.option_loc[i].type) === -1) {
          arr.push(this.option_loc[i].value);
        }
      }
      if (arr.join(',') === this.p_aiRobotMatchParameterResult.pr.join(',')) {
        // this.f_controlToOpenSelectByAi();
        return true;
      }
      return false;
    },
    f_addEditRule: function () {
      if (!this.d_ruleEditMode) {
        this.d_ruleEditMode = true;
      } else {
        this.d_ruleEditMode = false;
      }
    },
    f_showSearchPatient: function () {
      if (this.param === 'patient_data_project_list' && this.data.patient_data_list_type && this.data.patient_data_list_type.val && this.data.patient_data_list_type.val.value && this.data.patient_data_list_type.val.value === 'patient_data_list_type_project' && this.data.patient_data_project_list && this.data.patient_data_project_list.val && this.data.patient_data_project_list.val.value && this.data.patient_data_project_list.val.value !== '') {
        this.d_patientSearchModalData.document_key = 'patient_data_project_' + this.data.patient_data_project_list.val.value;
        this.d_patientSearchModalData.document_bucket = 'default';
      }
      this.d_showModal.patient_search = true;
    },
    f_calculatePatientSearchModalButton: function () {
      if (this.data_type === 'wdm17' && !this.polestar && !this.rule_engine) {
        if (this.data.task_type && this.data.task_type.val && this.data.task_type.val.value && this.data.task_type.val.value === 'patient_data') {
          if (this.param === 'patient_data_project_list' && this.data.patient_data_list_type && this.data.patient_data_list_type.val && this.data.patient_data_list_type.val.value && this.data.patient_data_list_type.val.value === 'patient_data_list_type_project' && this.data.patient_data_project_list && this.data.patient_data_project_list.val && this.data.patient_data_project_list.val.value && this.data.patient_data_project_list.val.value !== '') {
            return true;
          }
        }
      }
      return false;
    },
    f_getPatientDataProjectsList: function () {
      OrganizationService.get_patient_data_project_list()
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_patientDataProjectsList = resp.data.result;
          } else {
            this.d_patientDataProjectsList = [];
            alert('Error: ', resp.data.message);
          }
        });
    },
    f_getMembersForMessengerGroup: function () {
      MessengerService.get_members_for_messenger_group()
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_membersForMessengerGroup = resp.data.result;
          } else {
            this.d_membersForMessengerGroup = [];
            alert('Error: ', resp.data.message);
          }
        });
    },
    f_calculatePatientWdmrList: function (wdmr_type) {
      let type_list = [];
      this.d_patientWdmrList = [];
      type_list.push(wdmr_type);
      let service_data = { 'patient_id': this.patient.id, 'type_list': type_list };
      PatientService.get_patient_wdmr_by_type(service_data)
        .then(resp => {
          let tmp_result = resp.data.result[wdmr_type];
          let result = [];
          // console.log(resp.data.result[wdmr_type]);
          for (let i in tmp_result) {
            if (tmp_result[i].status !== 'deleted') {
              this.d_patientWdmrList.push({ 'value': tmp_result[i].key, 'label': tmp_result[i].name ? tmp_result[i].name : tmp_result[i].label });
            }
          }
        });
    },
    get_user_wdm_list: function () {
      let wdm_version = JSON.parse(localStorage.getItem('wdm_version'));
      for (let v in wdm_version) {
        for (let i in wdm_version[v].all) {
          let x = wdm_version[v].all[i].name;
          x.permission = wdm_version[v].all[i].permission;
          this.d_user_wdm_list.push(x);
        }
      }
    },
    get_user_hospital_code_list: function () {
      if (this.user) {
        this.user_hospital_code_list = [];
        HospitalService.get_hospital_group()
          .then(resp => {
            if (resp.data.status === 'success') {
              let hospital_group_list = resp.data.result.hospital_groups;
              for (let i in hospital_group_list) {
                if (hospital_group_list[i].value === this.user.hospital_group) {
                  this.user_hospital_code_list = hospital_group_list[i].hospital_list;
                  break;
                }
              }
            }
          });
      }
    },
    GetClickKeyDisease (event) {
      if (event.which === 13) {
        this.SearchWithSearchingDiseaseText();
      }
    },
    GetClickKey (event) {
      if (event.which === 13) {
        this.SearchWithSearchingDrugText();
      }
    },
    f_searchBrand: function () {
      if (this.searching_brand_text && this.searching_brand_text.length > 0) {
        this.d_brandSearchList = [];
        this.data[this.param] = { 'val': '' };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Ürünler listeleniyor.' } });
        let data = { 'search': this.searching_brand_text };
        OptionService.search_brand(data)
          .then(resp => {
            if (resp.data.status === 'success') {
              this.d_brandSearchList = resp.data.result;
              if (this.d_brandSearchList.length > 0) {
                this.data[this.param]['val'] = this.d_brandSearchList[0];
              }
            } else {
              this.d_brandSearchList = [];
            }
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          })
      } else {
        alert('Minimum 1 karakter giriniz.');
      }
    },
    GetClickKeyBrand (event) {
      if (event.which === 13) {
        this.f_searchBrand();
      }
    },
    SearchWithSearchingDiseaseText: function () {
      if (this.searching_disease_text && this.searching_disease_text.length > 2) {
        this.diagnosis_options = [];
        this.data[this.param] = { 'val': '' };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Hastalıklar listeleniyor.' } });
        OptionService.search("icd10", { search: this.searching_disease_text.toLocaleLowerCase('tr') }).then(resp => {
          this.diagnosis_options = resp.data.results;
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
        });
      } else {
        alert('Minimum 2 karakter giriniz.');
      }
    },
    SearchWithSearchingDrugText: function () {
      if (this.searching_drug_text && this.searching_drug_text.length > 0) {
        this.drug_search_list = [];
        this.data[this.param] = { 'val': '' };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İlaçlar listeleniyor.' } });
        let data = { 'search': this.searching_drug_text, 'wdm_type': 'wdm2' };
        OptionService.search_wdm_option(data)
          .then(resp => {
            if (resp.data.status === 'success') {
              this.drug_search_list = resp.data.result;
              if (this.drug_search_list.length > 0) {
                this.data[this.param]['val'] = this.drug_search_list[0];
              }
            } else {
              this.drug_search_list = [];
            }
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          })
      } else {
        alert('Minimum 1 karakter giriniz.');
      }
    },
    CalculateShowVselect: function () {
      if (this.select_types.indexOf(this.option_param_mother.parameters[this.param].type) !== -1) {
        if (['select_drug'].indexOf(this.param) !== -1) {
          return false;
        }
        if (this.option_param_mother.parameters[this.param].option_search && ['get_icd_codes', 'drugs', 'drug_medical_equipment_brands'].indexOf(this.option_param_mother.parameters[this.param].option_search) !== -1) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    },
    CalculateParamClass: function () {
      if (this.level === 'first') {
        return 'level-1-param-caption';
      } else if (this.level === 'second') {
        return 'level-2-param-caption';
      } else if (this.level === 'third') {
        return 'level-3-param-caption';
      }
    },
    f_paramChangeFunc: function (type = '') {
      if (['select', 'radiogroup', 'object_list'].indexOf(this.option_param_mother.parameters[this.param].type) !== -1) {
        if ((this.d_oldValue === '' && this.data[this.param].val) || (this.d_oldValue !== '' && JSON.stringify(this.d_oldValue) !== JSON.stringify(this.data[this.param]))) {
          this.d_oldValue = JSON.parse(JSON.stringify(this.data[this.param]));
          this.f_paramChanged(type);
        }
      } else if (['checkbox'].indexOf(this.option_param_mother.parameters[this.param].type) !== -1) {
        if ((this.d_oldValue === '' && this.data[this.param].val.length > 0) || (this.d_oldValue !== '' && JSON.stringify(this.d_oldValue) !== JSON.stringify(this.data[this.param]))) {
          this.d_oldValue = JSON.parse(JSON.stringify(this.data[this.param]));
          this.f_paramChanged(type);
        }
      } else {
        if ((this.d_oldValue === '' && this.data[this.param].val !== '') || (this.d_oldValue !== '' && JSON.stringify(this.d_oldValue) !== JSON.stringify(this.data[this.param]))) {
          this.d_oldValue = JSON.parse(JSON.stringify(this.data[this.param]));
          this.f_paramChanged(type);
        }
      }
    },
    f_paramChanged: function (type) {
      setTimeout(function () {
        // console.log('this.d_oldValue ', this.d_oldValue);
        // console.log('this.data[this.param].val ', this.data[this.param].val);
        this.f_controlSomeOperations();
        this.param_change.type = type;
        this.param_change.param = this.param;
        // this.param_change.option_loc = this.option_loc;
        // this.param_change.data = this.data[this.param];
        if (this.param_change.id) {
          this.param_change.id = 0;
        } else {
          this.param_change.id = 1;
        }
      }.bind(this), 100);
    },
    f_controlSomeOperations: function () {
      // console.log('this.param ', this.param);
      if (this.data_type === "patient_staging") {
        if (["staging_group", "staging_method"].indexOf(this.param) !== -1) {
          // console.log('f_controlSomeOperations........');
          for (let i in this.stage_data.stage_order) {
            if (this.data[this.stage_data.stage_order[i]] && this.data[this.stage_data.stage_order[i]]['val']) {
              // this.$delete(this.data[this.stage_data.stage_order[i]], 'val');
              this.$delete(this.data, this.stage_data.stage_order[i]);
            }
          }
          this.send_staging_changed_message_to_mother_component();
          if (this.data.stage) {
            this.data.stage['val'] = "";
          }
          if (this.data.stage_code) {
            this.data.stage_code['val'] = "";
          }
        } else if (this.param !== 'stage') {
          this.calculate_staging_result();
        }
      }

      // wdm68 = GCS
      if (this.data_type === "wdm68") {
        // console.log('892');
        this.data.gcs_score = { 'val': '' };
        let gcs_score = this.f_calculateGlasgowComaScale();
        if (gcs_score > 0) {
          this.data.gcs_score = { 'val': gcs_score.toString() };
        } else {
          this.data.gcs_score = { 'val': '' };
        }
        this.f_paramChange('forceUpdate');
      }

      if (this.data_type === "toxicity_model") {
        if (["toxicity_group", "toxicity_list"].indexOf(this.param) !== -1) {
          this.send_toxicity_changed_message_to_mother_component();
        }
      }

      if (this.data_type === "wdm10" && this.param === 'fluid') {
        if (this.data[this.param] && this.data[this.param].val && this.data[this.param].val.fluid_value) {
          this.data.fluid_volume = { 'val': this.data[this.param].val.fluid_value };
        }
      }
    },
    f_paramChange: function (op) {
      if (this.param_change[op]) {
        this.param_change[op] = 0;
      } else {
        this.param_change[op] = 1;
      }
    },
    f_addDrugFromChemotherapy: function () {
      if (this.param_change.drug_from_chemotherapy) {
        this.param_change.drug_from_chemotherapy = 0;
      } else {
        this.param_change.drug_from_chemotherapy = 1;
      }
    },
    send_staging_changed_message_to_mother_component: function () {
      if (this.param_change.staging === 0) {
        this.param_change.staging = 1;
      } else {
        this.param_change.staging = 0;
      }
    },
    send_toxicity_changed_message_to_mother_component: function () {
      if (this.param_change.toxicity === 0) {
        this.param_change.toxicity = 1;
      } else {
        this.param_change.toxicity = 0;
      }
    },
    send_wdm3_wdm_type_group_change_to_mother_component: function () {
      if (this.param_change.wdm3_group_version === 0) {
        this.param_change.wdm3_group_version = 1;
      } else {
        this.param_change.wdm3_group_version = 0;
      }
    },
    // f_someOperations
    option_selection: function (option_selected) {
      // console.log('giriş', this.param);
      // console.log("this.data[this.param]['val'] === ", this.data[this.param]['val']);
      // console.log('1- option_selected', option_selected);
      /*
      if (this.data[this.param]['val'].value === option_selected.value) {
        console.log('same value');
        return;
      }
      */
      if (option_selected && option_selected.value !== undefined) {
        // console.log('2- option_selected', option_selected);
        this.data[this.param]['val'] = option_selected;

      } {
        // console.log('3- option_selected', option_selected);
        // this.data[this.param]['val'] = '';
      }
      this.f_paramChangeFunc();
      // console.log('option_selected', option_selected);
    },
    f_calculateGlasgowComaScale: function () {
      // console.log('968');
      let score = 0;
      if (!this.data.eye_response || !this.data.verbal_response || !this.data.motor_response || !this.data.eye_response.val || !this.data.verbal_response.val || !this.data.motor_response.val || !this.data.eye_response.val.value || !this.data.verbal_response.val.value || !this.data.motor_response.val.value) {
        return score;
      }
      let eye_response_score = parseInt(this.data.eye_response.val.value.split('_')[2]);
      let verbal_response_score = parseInt(this.data.verbal_response.val.value.split('_')[2]);
      let motor_response_score = parseInt(this.data.motor_response.val.value.split('_')[2]);

      if (eye_response_score === 0 || verbal_response_score === 0 || motor_response_score === 0) {
        return score;
      }
      // console.log('979');
      return eye_response_score + verbal_response_score + motor_response_score;

    },
    calculate_staging_result: function () {
      // console.log('calculate_staging_result');
      let st = "";
      /*
      console.log('calculate_staging_result: this.data.stage ', JSON.stringify(this.data.stage));
      if (this.data.stage) {
        this.data.stage['val'] = "";
      }
      if (this.data.stage_code) {
        this.data.stage_code['val'] = st;
      }
      */
      for (let i in this.stage_data.stage_order) {
        if (this.data[this.stage_data.stage_order[i]] && this.data[this.stage_data.stage_order[i]]['val'] && this.data[this.stage_data.stage_order[i]]['val'].value) {
          st += this.data[this.stage_data.stage_order[i]]['val'].value;
        }
      }
      // console.log("st..............", st);
      if (st) {
        if (this.data.stage_code['val'] !== st) {
          this.data.stage_code['val'] = st;
          if (this.stage_data.results[st]) {
            this.data.stage['val'] = this.stage_data.results[st];
            // console.log("this.data.stage['val'] ", this.data.stage['val']);
          } else {
            this.data.stage['val'] = "";
          }
        }
      } else {
        if (!this.stage_automatic) {
          this.data.stage['val'] = "";
        }
      }
      if (this.param_change.id) {
        this.param_change.id = 0;
      } else {
        this.param_change.id = 1;
      }
      // this.f_paramChangeFunc();
    },
    calculate_style () {
      if (this.level === "first") {
        return "";
      } else if (this.level === "second") {
        return "margin-left: 20px;";
      }
    },
    get_patient_diagnosis_options () {
      this.patient_diagnosis_options = this.patient.diagnosis;
    },
    get_patient_drug_options () {
      PatientService.get_patient_drugs(this.patient.id)
        .then(resp => {
          // console.log(resp.data.result);
          this.patient_drug_options = resp.data.result;
        });
    },
    add_list_param: function (data_loc, param, location_parameters) {
      if (!data_loc[param]) {
        data_loc[param] = { list: [] };
      }
      if (!data_loc[param]['list']) {
        data_loc[param] = { list: [] };
      }
      data_loc[param].list.push({});
      /*      for (let i in location_parameters.parameters) {
              console.log(i);
              data_loc[param].list[data_loc[param].list.length - 1][i] = '';
            } */
      this.f_paramChangeFunc();
    },
    SearchDiagnosisOptions: function (search) {
      OptionService.search("icd10", { search: search }).then(resp => {
        this.diagnosis_options = resp.data.results;
      });
    },
    get_chemotherapy_protocols: function () {
      this.chemotherapy_protocol_options = JSON.parse(localStorage.getItem('chemotherapy_protocols'));
      // ProtocolService.search({
      //   group: "",
      //   scope: ["shared", "public", "private"],
      //   favorite: false,
      //   detail: "not-detailed"
      // }).then(resp => {
      //   if (resp.data.results.length > 0) {
      //     this.chemotherapy_protocol_options = resp.data.results;
      //   } else {
      //     this.chemotherapy_protocol_options = [];
      //   }
      // });
    },
    BringPackages: function () {
      RulePackageService.get_eligible_packages().then(resp => {
        // console.log(resp.data.data);
        this.packages = resp.data.data;
      });
    },
    stage_automatic_func: function () {
      if (this.stage_automatic) {
        this.$delete(this.data, "stage_code");
        this.option_param_mother.parameters.stage_code.show = "no";
        // this.option_param_mother.parameters.stage.type = "select";
        if (this.stage_data.stage_order && this.stage_data.stage_order.length > 0) {
          for (let i in this.stage_data.stage_order) {
            this.option_param_mother.parameters[this.stage_data.stage_order[i]].show = "no";
            this.$delete(this.data, this.stage_data.stage_order[i]);
          }
        }
        this.stage_automatic = false;
      } else {
        this.option_param_mother.parameters.stage_code.show = "yes";
        // this.option_param_mother.parameters.stage.type = "plain_text";
        if (this.stage_data.stage_order && this.stage_data.stage_order.length > 0) {
          for (let i in this.stage_data.stage_order) {
            this.option_param_mother.parameters[this.stage_data.stage_order[i]].show = "yes";
          }
        }
        this.stage_automatic = true;
      }
      this.f_paramChangeFunc();
    },
    new_staging_group: function () {
      if (
        this.stage_data.stage_order &&
        this.stage_data.stage_order.length > 0
      ) {
        this.option_param_mother.parameters.stage.show = "no";
        this.option_param_mother.parameters.stage_code.show = "no";
        for (let i in this.stage_data.stage_order) {
          this.option_param_mother.parameters[
            this.stage_data.stage_order[i]
          ].show = "no";
          this.$delete(this.data, this.stage_data.stage_order[i]);
        }
      }
      this.data.stage = "";
      this.data.stage_code = "";
      this.option_param_mother.parameters.staging_group.type = "select";
      this.show_staging_group_selectable = true;
      this.f_paramChangeFunc();
    },
    get_staging_details: function () {
      if (this.param_change.staging === 0) {
        this.param_change.staging = 1;
      } else {
        this.param_change.staging = 0;
      }
    },
    calculate_options: function (param, param_data) {
      let option_list = [];
      if (param_data.option_search && param_data.option_search === "rule_packages") {
        if (this.packages) {
          option_list = JSON.parse(JSON.stringify(this.packages));
        }
      } else if (param_data.option_search && param_data.option_search === "get_diagnosis") {
        if (this.patient_diagnosis_options) {
          option_list = JSON.parse(JSON.stringify(this.patient_diagnosis_options));
        }
      } else if (param_data.option_search && param_data.option_search === "get_icd_codes") {
        if (this.diagnosis_filter_type === 'patient') {
          if (this.patient_diagnosis_options) {
            option_list = JSON.parse(JSON.stringify(this.patient_diagnosis_options));
          }
        } else if (this.diagnosis_filter_type === 'favorite') {
          if (this.favorite_diagnosis_list) {
            option_list = JSON.parse(JSON.stringify(this.favorite_diagnosis_list));
          }
        }
      } else if (param_data.option_search && param_data.option_search === "drugs") {
        if (this.drug_filter_type === 'patient') {
          if (this.patient_drug_options) {
            option_list = JSON.parse(JSON.stringify(this.patient_drug_options));
          }
        } else if (this.drug_filter_type === 'favorite') {
          if (this.favorite_drugs) {
            option_list = JSON.parse(JSON.stringify(this.favorite_drugs));
          }
        }
      } else if (param_data.option_search && param_data.option_search === "get_oncology_treatment_protocols") {
        if (this.chemotherapy_protocol_options) {
          option_list = JSON.parse(JSON.stringify(this.chemotherapy_protocol_options));
        }
      } else if (param_data.option_search && param_data.option_search === "get_toxicity_groups") {
        if (localStorage.getItem('toxicity')) {
          option_list = JSON.parse(localStorage.getItem('toxicity')).parameters.toxicity_group.options;
        }
      } else if (param_data.option_search && param_data.option_search === "get_frequency_list") {
        if (this.d_frequencyList) {
          option_list = JSON.parse(JSON.stringify(this.d_frequencyList));
        }
      } else if (param_data.option_search && param_data.option_search === "fluids") {
        if (this.d_fluidList) {
          option_list = JSON.parse(JSON.stringify(this.d_fluidList));
        }
      } else if (param_data.option_search && param_data.option_search === "get_members_for_messenger_group") {
        option_list = JSON.parse(JSON.stringify(this.d_membersForMessengerGroup));
      } else if (param_data.option_search && param_data.option_search === "get_patient_data_project_list") {
        option_list = JSON.parse(JSON.stringify(this.d_patientDataProjectsList));
      } else if (param_data.option_search && param_data.option_search === "get_dosage_form_list") {
        if (this.d_dosageFormList) {
          option_list = JSON.parse(JSON.stringify(this.d_dosageFormList));
        }
      } else if (param_data.option_search && param_data.option_search === "country") {
        option_list = require('@/options/countries').options;
      } else if (param_data.option_search && param_data.option_search === "turkish_city") {
        option_list = require('@/options/city').options;
      } else if (param_data.option_search && param_data.option_search === "get_user_hospital_code_list") {
        option_list = this.user_hospital_code_list;
      } else if (param_data.option_search && param_data.option_search === "get_user_wdm_list") {
        option_list = this.d_user_wdm_list;
      } else if (param_data.option_search && param_data.option_search === "get_tnm_options") {
        if (this.stage_data.stage_order && this.stage_data.stage_order.indexOf(param) !== -1) {
          param_data.show = "yes";
          option_list = JSON.parse(JSON.stringify(this.stage_data.stage[param].list));
        }
      } else if (param_data.option_search && param_data.option_search === "get_patient_wdmr_list" && param_data.option_search_patient_wdmr) {
        // option_list = this.f_calculatePatientWdmrList(param_data.option_search_patient_wdmr);
        option_list = JSON.parse(JSON.stringify(this.d_patientWdmrList));
      } else if (param_data.option_search && param_data.option_search === "get_wdm10_delay_reasons") {
        option_list = JSON.parse(JSON.stringify(this.wdm10_delay_reasons));
      } else if (param_data.option_search && param_data.option_search === "get_dosage_change_reasons") {
        option_list = JSON.parse(JSON.stringify(this.dosage_change_reasons));
      } else if (param_data.option_search && param_data.option_search === "get_laboratory_process_list") {
        option_list = JSON.parse(JSON.stringify(this.laboratory_process_list));
      } else if (param_data.option_search && param_data.option_search === "get_radiology_process_list") {
        option_list = JSON.parse(JSON.stringify(this.radiology_process_list));
      } else if (param_data.option_search && param_data.option_search === "get_other_process_list") {
        option_list = JSON.parse(JSON.stringify(this.other_process_list));
      } else if (param_data.option_search && param_data.option_search === "get_patient_open_protocol_list") {
        option_list = JSON.parse(JSON.stringify(this.d_patientOpenProtocolList));
      } else if (param_data.option_search && param_data.option_search === "get_hospital_department_list") {
        option_list = JSON.parse(JSON.stringify(this.d_hospitalDepartmentList));
      } else if (param_data.option_search && param_data.option_search === "get_hospital_wtl_list") {
        option_list = JSON.parse(JSON.stringify(this.d_hospitalWtlData));
      } else if (param_data.option_search && param_data.option_search === "get_all_user_list") {
        option_list = JSON.parse(JSON.stringify(this.d_allUserList));
      } else if (param_data.option_search && param_data.option_search === "get_hospital_groups_list") {
        option_list = JSON.parse(JSON.stringify(this.d_hospitalGroupList));
      } else if (param_data.option_search && param_data.option_search === "get_hospitals_of_selected_hospital_group") {
        option_list = JSON.parse(JSON.stringify(this.d_hospitalsOfSelectedHospitalGroup));
      } else if (param_data.option_search && param_data.option_search === "get_authorized_wdm_type_list_from_wdm_version") {
        option_list = this.f_calculateSomeOtherOptions();
      } else if (param_data.option_search && param_data.option_search === "get_departments_of_selected_hospital_of_selected_hospital_group") {
        option_list = JSON.parse(JSON.stringify(this.d_departmentsOfSelectedHospitalOfSelectedHospitalGroup));
      } else {
        if (param_data.options && param_data.options.length > 0) {
          option_list = JSON.parse(JSON.stringify(param_data.options));
        } else {
          option_list = [];
        }
      }
      if (param_data.op_if) {
        let new_option_list = [];
        for (let op in option_list) {
          if (this.calculate_if_option(this.data, param, option_list[op], this.option_param_mother, "", this.anatomy)) {
            new_option_list.push(option_list[op]);
          }
        }
        if (param_data.type === 'object_list' && param_data.name && param_data.name.together_param) {
          if (this.data[param_data.name.together_param]) {
            let together_param_list = [];
            let final_option_list = [];
            for (let tpl in this.data[param_data.name.together_param]) {
              together_param_list.push(tpl);
            }
            for (let fop in new_option_list) {
              if (together_param_list.indexOf(new_option_list[fop]['value']) === -1) {
                final_option_list.push(new_option_list[fop]);
              }
            }
            option_list = final_option_list;
          }
        }
        option_list = new_option_list;
      }
      if (param_data.type === 'object_list' && param_data.name && param_data.name.together_param) {
        if (this.data[param_data.name.together_param]) {
          let together_param_list = [];
          let final_option_list = [];
          for (let tpl in this.data[param_data.name.together_param]) {
            together_param_list.push(tpl);
          }
          for (let fop in option_list) {
            if (together_param_list.indexOf(option_list[fop]['value']) === -1) {
              final_option_list.push(option_list[fop]);
            }
          }
          option_list = final_option_list;
        }
      }
      if (this.data[this.param] && this.data[this.param]['val'] && typeof this.data[this.param]['val'] === 'object' && this.data[this.param]['val']['value'] && !this.data[this.param]['val']['translation']) {
        let new_opt_list = [];
        for (let o in option_list) {
          let x = JSON.parse(JSON.stringify(option_list[o]));
          this.$delete(x, 'translation');
          new_opt_list.push(x);
        }
        option_list = new_opt_list;
      }
      return option_list;
    },
    f_calculateSomeOtherOptions: function () {
      /*
        if we have in a option_param_mother wdm_group, wdm_type and wdm_version it automatically creates wdm type and version list.
      */
      let option_list = [];
      // console.log('this.param = ', this.param);
      if (['wdm3', 'rule', 'wdm24'].indexOf(this.data_type) !== -1) {
        if (this.param === 'wdm_group') {} else if (this.param === 'wdm_type') {
          let wdm_version = JSON.parse(localStorage.getItem('wdm_version'));
          let selected_wdm_group = this.data['wdm_group'] && this.data['wdm_group']['val'] ? this.data['wdm_group']['val']['value'] : '';
          // console.log('selected_wdm_group ', selected_wdm_group);
          option_list = [];
          if (selected_wdm_group) {
            for (let i in wdm_version[selected_wdm_group]['all']) {
              let x = wdm_version[selected_wdm_group]['all'][i];
              x.value = i;
              x.label = x.name.label + ' (' + selected_wdm_group + ')';
              option_list.push(x);
            }
            if (selected_wdm_group === 'wisdom') {
              for (let i in wdm_version['hospital']['all']) {
                let x = this.wdm_version_wisdomera['all'][i];
                x.value = i;
                x.label = x.name.label + ' (hospital)';
                option_list.push(x);
              }
            }
          }
        } else if (this.param === 'wdm_version') {
          let wdm_version = JSON.parse(localStorage.getItem('wdm_version'));
          let selected_wdm_group = this.data['wdm_group'] && this.data['wdm_group']['val'] ? this.data['wdm_group']['val']['value'] : '';
          let selected_wdm_type = this.data['wdm_type'] && this.data['wdm_type']['val'] ? this.data['wdm_type']['val']['value'] : '';
          // console.log('selected_wdm_group ', selected_wdm_group);
          // console.log('selected_wdm_type ', selected_wdm_type);
          option_list = [];
          if (selected_wdm_group && selected_wdm_type) {
            let x = [];
            if (selected_wdm_group === 'wisdom') {
              if (wdm_version[selected_wdm_group]['all'][selected_wdm_type]) {
                x = wdm_version[selected_wdm_group]['all'][selected_wdm_type]['list'];
              } else {
                if (this.wdm_version_wisdomera && this.wdm_version_wisdomera['all'][selected_wdm_type]) {
                  x = this.wdm_version_wisdomera['all'][selected_wdm_type]['list'];
                }
              }
            } else {
              if (wdm_version[selected_wdm_group]['all'][selected_wdm_type]) {
                x = wdm_version[selected_wdm_group]['all'][selected_wdm_type]['list'];
              }
            }
            for (let i in x) {
              /*
              if (x[i].version !== 'draft') {}
              */
              let y = JSON.parse(JSON.stringify(x[i]));
              y.value = y.key;
              y.label = y.version;
              option_list.push(y);
            }
          }
        }
      } else if (['wdm17'].indexOf(this.data_type) !== -1) {
        if (this.param === 'wdm_type') {
          let selected_wdm_group = this.data['wdm_group'] && this.data['wdm_group']['val'] ? this.data['wdm_group']['val']['value'] : '';
          if (selected_wdm_group) {
            for (let i in this.d_wdmTypeAndVersionList[selected_wdm_group]['all']) {
              let x = this.d_wdmTypeAndVersionList[selected_wdm_group]['all'][i];
              x.value = i;
              x.label = x.name.label;
              option_list.push(x);
            }
          }
        } else if (this.param === 'wdm_version') {
          let selected_wdm_group = this.data['wdm_group'] && this.data['wdm_group']['val'] ? this.data['wdm_group']['val']['value'] : '';
          let selected_wdm_type = this.data['wdm_type'] && this.data['wdm_type']['val'] ? this.data['wdm_type']['val']['value'] : '';
          if (selected_wdm_group && selected_wdm_type) {
            let x = this.d_wdmTypeAndVersionList[selected_wdm_group]['all'][selected_wdm_type]['list'];
            for (let i in x) {
              /*
              if (x[i].version !== 'draft') {
              }
              */
              let y = JSON.parse(JSON.stringify(x[i]));
              y.value = y.key;
              y.label = y.version;
              option_list.push(y);
            }
          }
        }
      }
      // console.log('option_list ', JSON.stringify(option_list));
      return option_list;
    },
    f_deleteParamValue: function (recording_data_region, param_ind, option) {
      // WE ANALYZE THE PARAMETER WHETHER IT HAS THE OPTION. IF IT HAS THIS OPTION, WE DELETE IT.
      /*
            console.log('recording_data_region =>', JSON.parse(JSON.stringify(recording_data_region)));
            console.log('param_ind => ', param_ind);
            console.log('option => ', option);
      */
      if (['checkbox'].indexOf(this.option_param_mother.parameters[param_ind].type) !== -1) {
        let opt_ind = '';
        for (let i in recording_data_region[param_ind].val) {
          if (recording_data_region[param_ind].val[i].value === option.value) {
            opt_ind = i;
          }
        }
        if (opt_ind !== '') {
          recording_data_region[param_ind]['val'].pop(opt_ind, 1);
        }
      } else if (['select', 'radiogroup', 'object_list'].indexOf(this.option_param_mother.parameters[param_ind].type) !== -1) {
        if (option.value === recording_data_region[param_ind].val.value) {
          recording_data_region[param_ind] = { 'val': '' };
        }
      }
    },
    f_analyzeOptionFriend: function (f_wdmr, f_wdm, friend_data_i, recording_data_region, param_ind, option) {
      // console.log('f_wdmr: ', JSON.parse(JSON.stringify(f_wdmr)));
      if (!f_wdmr || (f_wdmr && !f_wdmr.val)) {
        // console.log('1');
        this.f_deleteParamValue(recording_data_region, param_ind, option);
        return false;
      } else {
        // IF THE FRIEND PARAMETER EXIST, WE ANALYZE WHETHER IT HAS WHAT WE EXPECT.
        if (friend_data_i.operation && Object.keys(friend_data_i.operation).length > 0) {
          for (let k in friend_data_i.operation) {
            if (k === "or_options") {
              // IF OR OPTION LIST HAS VALUES, IT MEANS THAT, OUR FRIEND PARAMETER MUST HAVE ONE OF THIS VALUES.
              let or_option_list = friend_data_i.operation[k];
              // IF THE FRIEND PARAMETER DOES NOT HAVE ANY OR OPTION VALUES, WE DONT NEED TO ANALYZE.
              // console.log('or_option_list: ', or_option_list);
              if (or_option_list.length > 0) {
                if (['checkbox'].indexOf(f_wdm.type) !== -1) {
                  if (f_wdmr.val.length === 0) {
                    // console.log('2');
                    this.f_deleteParamValue(recording_data_region, param_ind, option);
                    return false;
                  } else {
                    let y_op_det = false;
                    for (let y_op in f_wdmr.val) {
                      if (or_option_list.indexOf(f_wdmr.val[y_op].value) !== -1) {
                        y_op_det = true;
                        break;
                      }
                    }
                    if (!y_op_det) {
                      // console.log('3');
                      this.f_deleteParamValue(recording_data_region, param_ind, option);
                      return false;
                    } else {
                      return true;
                    }
                  }
                } else if (['select', 'radiogroup', 'object_list'].indexOf(f_wdm.type) !== -1) {
                  if (!f_wdmr.val) {
                    // console.log('4');
                    this.f_deleteParamValue(recording_data_region, param_ind, option);
                    return false;
                  } else {
                    // IF FRIEND PARAMETER VALUE IS NOT WHAT WE EXPECTED CONTROL.
                    if (or_option_list.indexOf(f_wdmr.val.value) === -1) {
                      // console.log('5');
                      this.f_deleteParamValue(recording_data_region, param_ind, option);
                      return false;
                    } else {
                      return true;
                    }
                  }
                }
              }
            }
          }
        } else if (friend_data_i.math_operator) {
          if (friend_data_i.math_operator.operator === '' || friend_data_i.math_operator.number_1 === '' || ((friend_data_i.math_operator.operator === 'between' || friend_data_i.math_operator.operator === 'not_between') && friend_data_i.math_operator.number_2 === '')) {
            this.f_deleteParamValue(recording_data_region, param_ind, option);
            return false;
          } else {

            let ret_math = true;
            if (typeof f_wdmr.val === 'object') {
              if (f_wdmr.val.value) {
                if (f_wdmr.val.number_value) {
                  ret_math = this.f_checkFriendMath(f_wdmr.val.number_value, friend_data_i.math_operator);
                } else {
                  ret_math = this.f_checkFriendMath(0.0, friend_data_i.math_operator);
                }
              } else {
                let array_count = 0.0;
                for (let target_i in f_wdmr.val) {
                  if (f_wdmr.val[target_i].number_value) {
                    array_count += parseFloat(f_wdmr.val[target_i].number_value);
                  }
                }
                ret_math = this.f_checkFriendMath(array_count, friend_data_i.math_operator);
              }
            } else {
              if (f_wdmr.val !== '') {
                ret_math = this.f_checkFriendMath(f_wdmr.val, friend_data_i.math_operator);
              } else {
                ret_math = false;
              }
            }
            if (!ret_math) {
              this.f_deleteParamValue(recording_data_region, param_ind, option);
              return false;
            } else {
              return true;
            }

          }
        }
      }
    },
    f_checkFriendMath: function (param_total_value, math_operator) {
      param_total_value = parseFloat(param_total_value);
      let number_1 = parseFloat(math_operator.number_1);
      if (math_operator.operator === 'between' || math_operator.operator === 'not_between') {
        let number_2 = parseFloat(math_operator.number_2);
        let min = Math.min(number_1, number_2);
        let max = Math.max(number_1, number_2);
        if (math_operator.operator === 'between') {
          return param_total_value > min && param_total_value < max;
        } else {
          return param_total_value < min || param_total_value > max;
        }
      } else {
        if ((math_operator.operator === '>' && param_total_value > number_1) || (math_operator.operator === '<' && param_total_value < number_1) || (math_operator.operator === '===' && param_total_value === number_1) || (math_operator.operator === '!==' && param_total_value !== number_1)) {
          return true
        }
        return false;
      }
    },
    calculate_if_option (recording_data_region, param_ind, option, location_parameters, mother = "", anatomy_region) {
      let ret = false;
      if (option.if) {
        /*
                console.log('recording_data_region', JSON.parse(JSON.stringify(recording_data_region)));
                console.log('param_ind', param_ind);
                console.log('option', option);
                console.log('location_parameters', location_parameters);
                console.log('mother', mother);
                console.log('anatomy_region', anatomy_region);
        */
        // friend parameters control
        if (option.if.friend && Object.keys(option.if.friend).length > 0) {
          let friend_ret = true;
          let friend_data = option.if.friend;
          let friend_if_type = option.if.friend_if_type; // all_friend_match, any_one_of_friend_match
          if (!friend_if_type) {
            friend_if_type = 'all_friend_match';
          }
          for (let i in friend_data) {
            if (friend_data[i].parent_param === "yes") {
              let f_wdm = this.option_param_mother.parameters[i];
              let f_wdmr = recording_data_region[i];
              friend_ret = this.f_analyzeOptionFriend(f_wdmr, f_wdm, friend_data[i], recording_data_region, param_ind, option);
              if (!friend_ret && friend_if_type === 'all_friend_match') {
                return false;
              }
              if (friend_ret && !ret && friend_if_type === 'any_one_of_friend_match') {
                ret = true;
              }
            } else {
              // TODAY WE GIVE ONLY FIRST LAYER PARAMETER PERMISSION, SO WE DO NOT USE FOREVER FUNCTION TO GET THE PARAM DATA
              let group_ind = friend_data[i].index_list[0];
              let first_layer_param_index = friend_data[i].index_list[1];
              let f_wdm = this.option_data[this.data_type][group_ind].parameters[first_layer_param_index];
              let f_wdmr = this.p_wdmrData[group_ind][i];
              friend_ret = this.f_analyzeOptionFriend(f_wdmr, f_wdm, friend_data[i], recording_data_region, param_ind, option);
              if (!friend_ret && friend_if_type === 'all_friend_match') {
                return false;
              }
              if (friend_ret && !ret && friend_if_type === 'any_one_of_friend_match') {
                ret = true;
              }
            }
          }
          if (!ret && friend_if_type === 'any_one_of_friend_match') {
            return false;
          }
        } else {
          ret = true;
        }

        // patient control
        if (option.if.patient && option.if.patient.length > 0) {
          let det = false;
          for (let i in option.if.patient) {
            if (option.if.patient[i].type === 'sex') {
              if (option.if.patient[i].value === 'm' && this.patient.sex === '0') {
                det = true;
                break;
              } else if (option.if.patient[i].value === 'f' && this.patient.sex !== '0') {
                det = true;
                break;
              }
            }
          }
          if (det) {
            ret = true;
          } else {
            this.f_deleteParamValue(recording_data_region, param_ind, option);
            return false;
          }
        } else {
          ret = true;
        }

        // mother control
        if (option.if.mother && Object.keys(option.if.mother).length > 0 && mother) {
          if (option.if.mother[mother] === undefined) {
            this.f_deleteParamValue(recording_data_region, param_ind, option);
            return false;
          } else {
            ret = true;
          }
        } else {
          ret = true;
        }
        // mother anatomy control
        if (option.if.mother_anatomy && option.if.mother_anatomy.length > 0) {
          let det = false;
          for (let i in option.if.mother_anatomy) {
            if (option.if.mother_anatomy[i].value === anatomy_region.value) {
              det = true;
              break;
            }
          }
          if (det) {
            ret = true;
          } else {
            this.f_deleteParamValue(recording_data_region, param_ind, option);
            return false;
          }
        } else {
          ret = true;
        }
        // ana_parent control
        if (option.if.ana_parent && option.if.ana_parent.length > 0) {
          let det = false;
          for (let i in option.if.ana_parent) {
            if (anatomy_region.parent && anatomy_region.parent.indexOf(option.if.ana_parent[i].value) !== -1) {
              det = true;
              break;
            }
          }
          if (det) {
            ret = true;
          } else {
            this.f_deleteParamValue(recording_data_region, param_ind, option);
            return false;
          }
        } else {
          ret = true;
        }
        //
      } else {
        ret = true;
      }
      return ret;
    },
    add_to_list (list, count) {
      for (let i = 0; i < count; i++) {
        list.push({});
      }
      this.$forceUpdate();
    },
    f_selectProtocolForWdm30: function (location, object_option, param) {
      if (this.data['protocol_list'].val && this.data['protocol_list'].val.value) {
        this.data['selected_protocol'] = { 'val': JSON.parse(JSON.stringify(this.data['protocol_list'].val)) };
        this.f_paramChangeFunc();
      } else {
        alert('lütfen önce seçim yapınız');
      }
    },
    f_addOptionAsObject: function (location, object_option, param) {
      if (object_option && object_option['val'] && object_option['val'].value) {
        let lbl = object_option['val']['value'];
        if (location[param.object_param.value] === undefined) {
          location[param.object_param.value] = {};
        }
        if (location[param.object_param.value][lbl] === undefined) {
          if (param.object_type === "list") {
            location[param.object_param.value][lbl] = {
              name: JSON.parse(JSON.stringify(object_option['val'])),
              list: []
            };
          } else if (param.object_type === "object") {
            location[param.object_param.value][lbl] = {
              name: JSON.parse(JSON.stringify(object_option['val'])),
              detail: {}
            };
          }
          // this.$forceUpdate();
          this.f_paramChangeFunc('olo');
        }
        if (location[param.object_param.value][lbl]['val'] !== undefined) {
          this.$delete(location[param.object_param.vaue][lbl], 'val');
        }
      } else {
        alert('lütfen önce seçim yapınız');
      }
    },
    f_clearAiRobotMatchParameterResult: function () {
      this.p_aiRobotMatchParameterResult.pr = [];
      this.p_aiRobotMatchParameterResult.op = '';
      this.p_aiRobotMatchParameterResult.op_acc = 0;
      this.p_aiRobotMatchParameterResult.acc = 0;
      this.p_aiRobotMatchParameterResult.op_data = '';
    },
    f_calculateApacheII: function () {
      // https://www.researchgate.net/publication/19234189_APACHE_II_a_severity_of_disease_classification_system
      if (this.data['history_of_severe_organ_failure'].val && this.data['history_of_severe_organ_failure'].val.value && this.data['age'].val && this.data['temperature'].val && this.data['mean_arterial_pressure'].val && this.data['ph'].val && this.data['pulse'].val && this.data['respiratory_rate'].val && this.data['sodium'].val && this.data['potassium'].val && this.data['creatinin'].val && this.data['acute_renal_failure'].val && this.data['acute_renal_failure'].val.value && this.data['hematocrit'].val && this.data['wbc'].val && this.data['gcs'].val && this.data['fio2'].val && this.data['fio2'].val.value) {

        if (this.data['history_of_severe_organ_failure'].val.value === 'yes' && (!this.data['surgery_type'].val || !this.data['surgery_type'].val.value)) {
          this.data['total_score'] = { 'val': '' };
          this.data['approximated_mortality'] = { 'val': '' };
          return;
        }

        if (this.data['fio2'].val.value === 'less_then_50' && (!this.data['pao2'].val || !this.data['pao2'].val.value)) {
          this.data['total_score'] = { 'val': '' };
          this.data['approximated_mortality'] = { 'val': '' };
          return;
        }

        if (this.data['fio2'].val.value === 'more_equal_then_50' && (!this.data['a-a_gradient'].val || !this.data['a-a_gradient'].val.value)) {
          this.data['total_score'] = { 'val': '' };
          this.data['approximated_mortality'] = { 'val': '' };
          return;
        }

        let point = 0;
        let percent_type = '';

        let age = parseInt(this.data['age'].val);
        let history_of_severe_organ_failure = this.data['history_of_severe_organ_failure'].val.value;
        let temperature = parseFloat(this.data['temperature'].val);
        let mean_arterial_pressure = parseInt(this.data['mean_arterial_pressure'].val);
        let pulse = parseInt(this.data['pulse'].val);
        let respiratory_rate = parseInt(this.data['respiratory_rate'].val);
        let fio2 = this.data['fio2'].val.value;
        let ph = parseFloat(this.data['ph'].val);
        let sodium = parseFloat(this.data['sodium'].val);
        let potassium = parseFloat(this.data['potassium'].val);
        let creatinin = parseFloat(this.data['creatinin'].val);
        let hematocrit = parseFloat(this.data['hematocrit'].val);
        let wbc = parseFloat(this.data['wbc'].val);
        let gcs = parseInt(this.data['gcs'].val);
        let acute_renal_failure = this.data['acute_renal_failure'].val.value;

        if (age <= 44) {
          point += 0;
        } else if (age > 44 && age < 55) {
          point += 2;
        } else if (age > 54 && age < 65) {
          point += 3;
        } else if (age > 64 && age < 75) {
          point += 5;
        } else if (age > 74) {
          point += 6;
        }

        if (history_of_severe_organ_failure === 'no') {
          point += 0;
          percent_type = 'nonoperative';
        } else if (history_of_severe_organ_failure === 'yes') {
          let surgery_type = this.data['surgery_type'].val.value;
          if (surgery_type === 'elective') {
            point += 2;
            percent_type = 'operative';
          } else if (surgery_type === 'emergency') {
            point += 5;
            percent_type = 'operative';
          } else if (surgery_type === 'nonoperative') {
            point += 5;
            percent_type = 'nonoperative';
          }
        }

        if (temperature >= 41) {
          point += 4;
        } else if (temperature >= 39 && temperature < 41) {
          point += 3;
        } else if (temperature >= 38.5 && temperature < 39) {
          point += 1;
        } else if (temperature >= 36 && temperature < 38.5) {
          point += 0;
        } else if (temperature >= 34 && temperature < 36) {
          point += 1;
        } else if (temperature >= 32 && temperature < 34) {
          point += 2;
        } else if (temperature >= 30 && temperature < 32) {
          point += 3;
        } else if (temperature < 30) {
          point += 4;
        }

        if (mean_arterial_pressure > 159) {
          point += 4;
        } else if (mean_arterial_pressure > 129 && mean_arterial_pressure <= 159) {
          point += 3;
        } else if (mean_arterial_pressure > 109 && mean_arterial_pressure <= 129) {
          point += 2;
        } else if (mean_arterial_pressure > 69 && mean_arterial_pressure <= 109) {
          point += 0;
        } else if (mean_arterial_pressure > 49 && mean_arterial_pressure <= 69) {
          point += 2;
        } else if (mean_arterial_pressure <= 49) {
          point += 4;
        }

        if (pulse >= 180) {
          point += 4;
        } else if (pulse >= 140 && pulse < 180) {
          point += 3;
        } else if (pulse >= 110 && pulse < 140) {
          point += 2;
        } else if (pulse >= 70 && pulse < 110) {
          point += 0;
        } else if (pulse >= 55 && pulse < 70) {
          point += 2;
        } else if (pulse >= 40 && pulse < 55) {
          point += 3;
        } else if (pulse < 40) {
          point += 4;
        }

        if (respiratory_rate >= 50) {
          point += 4;
        } else if (respiratory_rate >= 35 && respiratory_rate < 50) {
          point += 3;
        } else if (respiratory_rate >= 25 && respiratory_rate < 35) {
          point += 1;
        } else if (respiratory_rate >= 12 && respiratory_rate < 25) {
          point += 0;
        } else if (respiratory_rate >= 10 && respiratory_rate < 12) {
          point += 1;
        } else if (respiratory_rate >= 6 && respiratory_rate < 10) {
          point += 2;
        } else if (respiratory_rate < 6) {
          point += 4;
        }

        if (fio2 === 'less_then_50') {
          let pao2 = this.data['pao2'].val.value;
          if (pao2 === 'more_then_70') {
            point += 0;
          } else if (pao2 === '61_70') {
            point += 1;
          } else if (pao2 === '55_60') {
            point += 3;
          } else if (pao2 === 'less_then_55') {
            point += 4;
          }
        } else if (fio2 === 'more_equal_then_50') {
          let aa_gradient = this.data['a-a_gradient'].val.value;
          if (aa_gradient === 'less_then_200') {
            point += 0;
          } else if (aa_gradient === '200_349') {
            point += 2;
          } else if (aa_gradient === '350_499') {
            point += 3;
          } else if (aa_gradient === 'more_then_499') {
            point += 4;
          }
        }

        if (ph >= 7.7) {
          point += 4;
        } else if (ph >= 7.6 && ph < 7.7) {
          point += 3;
        } else if (ph >= 7.5 && ph < 7.6) {
          point += 1;
        } else if (ph >= 7.33 && ph < 7.5) {
          point += 0;
        } else if (ph >= 7.25 && ph < 7.33) {
          point += 2;
        } else if (ph >= 7.15 && ph < 7.25) {
          point += 3;
        } else if (ph < 7.15) {
          point += 4;
        }

        if (sodium >= 180) {
          point += 4;
        } else if (sodium >= 160 && sodium < 180) {
          point += 3;
        } else if (sodium >= 155 && sodium < 160) {
          point += 2;
        } else if (sodium >= 150 && sodium < 155) {
          point += 1;
        } else if (sodium >= 130 && sodium < 150) {
          point += 0;
        } else if (sodium >= 120 && sodium < 130) {
          point += 2;
        } else if (sodium >= 111 && sodium < 120) {
          point += 3;
        } else if (sodium < 111) {
          point += 4;
        }

        if (potassium >= 7) {
          point += 4;
        } else if (potassium >= 6 && potassium < 7) {
          point += 3;
        } else if (potassium >= 5.5 && potassium < 6) {
          point += 1;
        } else if (potassium >= 3.5 && potassium < 5.5) {
          point += 0;
        } else if (potassium >= 3.0 && potassium < 3.5) {
          point += 1;
        } else if (potassium >= 2.5 && potassium < 3.0) {
          point += 2;
        } else if (potassium < 2.5) {
          point += 4;
        }

        if (hematocrit >= 60) {
          point += 4;
        } else if (hematocrit >= 50 && hematocrit < 60) {
          point += 2;
        } else if (hematocrit >= 46 && hematocrit < 50) {
          point += 1;
        } else if (hematocrit >= 30 && hematocrit < 46) {
          point += 0;
        } else if (hematocrit >= 20 && hematocrit < 30) {
          point += 2;
        } else if (hematocrit < 20) {
          point += 4;
        }

        if (wbc >= 40) {
          point += 4;
        } else if (wbc >= 20 && wbc < 40) {
          point += 2;
        } else if (wbc >= 15 && wbc < 20) {
          point += 1;
        } else if (wbc >= 3 && wbc < 15) {
          point += 0;
        } else if (wbc >= 1 && wbc < 3) {
          point += 2;
        } else if (wbc < 1) {
          point += 4;
        }

        point += 15 - gcs;

        if (creatinin < 0.6) {
          point += 2;
        } else if (creatinin >= 0.6 && creatinin < 1.5) {
          point += 0;
        } else {
          let creatinin_point = 0;
          if (creatinin >= 1.5 && creatinin < 2) {
            creatinin_point = 2;
          } else if (creatinin >= 2 && creatinin < 3.5) {
            creatinin_point = 3;
          } else if (creatinin >= 3.5) {
            creatinin_point = 4;
          }
          if (acute_renal_failure === 'yes') {
            creatinin_point += creatinin_point;
          }
          point += creatinin_point;
        }

        this.data['total_score'] = { 'val': point.toString() };
        if (point >= 0 && point <= 4) {
          if (percent_type === 'nonoperative') {
            this.data['approximated_mortality'] = { 'val': '4' };
          } else {
            this.data['approximated_mortality'] = { 'val': '1' };
          }
        } else if (point >= 5 && point <= 6) {
          if (percent_type === 'nonoperative') {
            this.data['approximated_mortality'] = { 'val': '8' };
          } else {
            this.data['approximated_mortality'] = { 'val': '3' };
          }
        } else if (point >= 10 && point <= 14) {
          if (percent_type === 'nonoperative') {
            this.data['approximated_mortality'] = { 'val': '15' };
          } else {
            this.data['approximated_mortality'] = { 'val': '7' };
          }
        } else if (point >= 15 && point <= 19) {
          if (percent_type === 'nonoperative') {
            this.data['approximated_mortality'] = { 'val': '25' };
          } else {
            this.data['approximated_mortality'] = { 'val': '12' };
          }
        } else if (point >= 20 && point <= 24) {
          if (percent_type === 'nonoperative') {
            this.data['approximated_mortality'] = { 'val': '40' };
          } else {
            this.data['approximated_mortality'] = { 'val': '30' };
          }
        } else if (point >= 25 && point <= 29) {
          if (percent_type === 'nonoperative') {
            this.data['approximated_mortality'] = { 'val': '55' };
          } else {
            this.data['approximated_mortality'] = { 'val': '35' };
          }
        } else if (point >= 30 && point <= 34) {
          if (percent_type === 'nonoperative') {
            this.data['approximated_mortality'] = { 'val': '73' };
          } else {
            this.data['approximated_mortality'] = { 'val': '73' };
          }
        } else if (point > 34) {
          if (percent_type === 'nonoperative') {
            this.data['approximated_mortality'] = { 'val': '85' };
          } else {
            this.data['approximated_mortality'] = { 'val': '88' };
          }
        }
      } else {
        this.data['total_score'] = { 'val': '' };
        this.data['approximated_mortality'] = { 'val': '' };
      }
    }
  },
  watch: {
    'd_valTextTimer.text': function () {
      // console.log(this.d_valTextTimer.text);
      clearTimeout(this.d_valTextTimer.timeout);
      this.d_valTextTimer.timeout = 0;
      this.d_valTextTimer.timeout = setTimeout(function () {
        if (['radiogroup'].indexOf(this.option_param_mother.parameters[this.param].type) !== -1) {
          let options = this.calculate_options(this.param, this.option_param_mother.parameters[this.param]);
          for (let i in options) {
            if (options[i].value === this.d_valTextTimer.text) {
              this.data[this.param].val = options[i];
              break;
            }
          }
        } else if (['select', 'object_list'].indexOf(this.option_param_mother.parameters[this.param].type) !== -1) {
          let options = this.calculate_options(this.param, this.option_param_mother.parameters[this.param]);
          for (let i in options) {
            if (options[i].value === this.d_valTextTimer.text.value) {
              /*
              let y = Object.keys(this.d_valTextTimer.text);
              for (let y_ind in y) {
                this.$delete(this.d_valTextTimer.text, y[y_ind]);
              }
              for (let x in options[i]) {
                this.d_valTextTimer.text[x] = options[i][x];
              }
              */
              // this.d_valTextTimer.text = options[i];
              this.d_valTextTimer.text.label = options[i].label;
              this.data[this.param].val = options[i];
              break;
            }
          }
        } else if (['checkbox'].indexOf(this.option_param_mother.parameters[this.param].type) !== -1) {
          let options = this.calculate_options(this.param, this.option_param_mother.parameters[this.param]);
          let opt_list = [];
          for (let k in this.d_valTextTimer.text) {
            for (let i in options) {
              if (options[i].value === this.d_valTextTimer.text[k]) {
                opt_list.push(options[i]);
                break;
              }
            }
          }
          this.data[this.param].val = opt_list;
        } else {
          this.data[this.param].val = this.d_valTextTimer.text;
        }
        this.f_paramChangeFunc();
      }.bind(this), 250);
    },
    "d_paramData.val": function () {
      if (this.option_param_mother.parameters[this.param].show_dosage_units && !this.data[this.param].unit) {
        this.data[this.param].unit = { 'value': 'mg', 'label': 'mg' };
      }
      if (this.option_param_mother.parameters[this.param].default && ((this.data[this.param].val && (Object.keys(this.data[this.param].val).length === 0 || !this.data[this.param].val.value)) || this.data[this.param].val === undefined || this.data[this.param].val === '')) {
        this.option_selection(this.option_param_mother.parameters[this.param].default);
      } else {
        this.f_paramChangeFunc();
      }
      if (this.data_type === 'wdm75') {
        this.f_calculateApacheII();
      }
    },
    "param_change.id": function (id) {
      // console.log("changed in wisdom param, id => ", this.param_change.id);
      /*
        if (this.option_param_mother.parameters[this.param].type === 'textarea' && this.data[this.param] && (this.data[this.param].val || this.data[this.param].val === '')) {
          this.d_valTextTimer.text = this.data[this.param].val;
        }
      */
      this.$forceUpdate();
    },
    "p_aiRobotMatchParameterResult.pr": function () {
      // console.log('pr changed');
      if (this.f_aiParamControl()) {
        this.f_controlToOpenSelectByAi();
      } else {
        this.f_controlToCloseSelectByAi();
      }
      this.$forceUpdate();
      this.f_paramChangeFunc();
    },
    "p_aiRobotMatchParameterResult.op": function () {
      // console.log('this.p_aiRobotMatchParameterResult.op: ', this.p_aiRobotMatchParameterResult.op);
      if (this.f_aiParamControl() && this.p_aiRobotMatchParameterResult.op_data !== '') {
        // console.log('option included');
        this.data[this.param].val = this.p_aiRobotMatchParameterResult.op_data;
        if (this.option_param_mother.parameters[this.param].type === 'object_list') {
          this.f_addOptionAsObject(this.data, this.data[this.param], this.option_param_mother.parameters[this.param]);
        }
        this.f_controlToCloseSelectByAi();
        this.f_clearAiRobotMatchParameterResult();
        this.$forceUpdate();
        this.f_paramChangeFunc();
      }
    }
  },
  components: {
    Anatomy,
    Datepicker,
    HtmlEditor,
    Modal,
    vSelect,
    WisdomDataPoleStar,
    WisdomDataRule,
    WisdomDataRuleB
  }
};

</script>

<style>
.level-1-param-caption {
  color: black;
  font-size: 10px;
  font-weight: bold;
}

.level-2-param-caption {
  color: #20a8d8;
}

.level-3-param-caption {
  color: green;
}

</style>


<template>
  <div>
    <b-card no-body header-tag="header" :style="'margin-bottom: 2px; background-color:' + p_polestarSettings.style.backgroundColor" key="upper_side">
      <b-row>
        <b-col sm="12" md="11">
          <template v-for="(menu, menu_ind) in d_menuList">
            <template v-if="menu.active">
              <span :style="d_selectedMenu === menu.value ?'cursor: pointer; border-bottom: solid 3px #05b4c5; margin: 5px; background-color: #e2f4f9;' :'cursor: pointer; margin: 5px;'" @click="f_selectMenu(menu)">
                <img v-if="menu.value === 'sms_menu'" src="@/icon/1013986.png" style="width: 1.5em;" />
                <img v-if="menu.value === 'mail_menu'" src="@/icon/110598.png" style="width: 1.5em;" />
                <img v-if="menu.value === 'decisionmakingpackage'" src="@/icon/1430869.png" style="width: 1.5em;" />
                <img v-if="menu.value === 'file'" src="@/icon/2976082.png" style="width: 1.5em;" />
                <img v-if="menu.value === 'general'" src="@/icon/2979152.png" style="width: 1.5em;" />
                <img v-if="menu.value === 'statistics'" src="@/icon/1240861.png" style="width: 1.5em;" />
                <img v-if="menu.value === 'article'" src="@/icon/70094.png" style="width: 1.5em;" />
                <img v-if="menu.value === 'view'" src="@/icon/680848.png" style="width: 1.5em;" />
                <img v-if="menu.value === 'data'" src="@/icon/2556829.png" style="width: 1.5em;" />
                <img v-if="menu.value === 'help'" src="@/icon/863488.png" style="width: 1.5em;" />
                <img v-if="menu.value === 'settings'" src="@/icon/3532765.png" style="width: 1.5em;" />
                <img v-if="menu.value === 'machine_learning'" src="@/icon/1368397.png" style="width: 1.5em;" />
                <strong>{{ menu.label }}</strong>
              </span>
            </template>
          </template>
        </b-col>
        <b-col sm="12" md="1">
          <b-button class="pull-right" size="md" :variant="d_upperPartShowMode ? 'warning' : 'secondary'" :style="d_upperPartShowMode ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_upperPartShowMode ? d_upperPartShowMode = false : d_upperPartShowMode = true">
            <img src="@/icon/45774.png" title="Üst Alanı Aç Kapa" style="width: 2em;" />
          </b-button>
        </b-col>
      </b-row>
      <template v-if="d_upperPartShowMode">
        <template v-if="d_selectedMenu ==='file'">
          <b-row style="height: 75px; margin-left: 0px;">
            <b-col cols="12">
              <div style="float: left;">
                <img src="@/icon/2976082.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                İçeri
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown v-if="user && user.permissions_result && (user.permissions_result.indexOf('polestar_wisdomstats_convert-selected-dataset-to-excel') !== -1 || user.permissions_result.indexOf('polestar_wisdomstats_convert-all-dataset-to-excel') !== -1)" variant="white" style="padding: 0px;">
                    <template slot="button-content">
                      <img src="@/icon/1387515.png" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item @click="f_addDataFromExcel()">
                      <img src="@/icon/1387515.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Excelden verileri aktar
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_openModalForAddClientIdByExcel()">
                      <img src="@/icon/1387515.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" title="Excel içerisinde kolon adı client_id veya wdmr olan kolon ve altında satırlar halinde client idler eklenir." /> Client id veya Wdmr id leri excelden ekle
                    </b-dropdown-item>
                    <!-- <b-dropdown-item @click="f_addDataFromCsv()">
                      <img src="@/icon/1387515.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Csvden verileri aktar
                    </b-dropdown-item> -->
                  </b-dropdown>
                </div>
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                Dışarı
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown variant="white" style="padding: 0px;">
                    <template slot="button-content">
                      <img src="@/icon/201295.png" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item @click="f_convertSelectedDataSetToExcel()">
                      <img src="@/icon/201295.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Seçili Verileri Excele Dönüştürerek İndir
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_convertAllDataSetToExcel()">
                      <img src="@/icon/201295.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Tüm Dataseti Excele Dönüştürerek İndir
                    </b-dropdown-item>
<!--                     <b-dropdown-item @click="f_convertAllDataSetToCsv()">
                      <img src="@/icon/201295.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Tüm Dataseti CSV Dosyasına Dönüştürerek İndir
                    </b-dropdown-item> -->
                  </b-dropdown>
                </div>
              </div>
              <!--  
                <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                  Hücre
                  <br>
                  <div style="float: left; min-width: 50px;">
                    <b-dropdown variant="white" style="padding: 0px;">
                      <template slot="button-content">
                        <img src="@/icon/1961831.png" style="border-radius: 50%; width: 2em;" />
                      </template>
                      <b-dropdown-item @click="f_analyzeProjectCellFillMethods()">
                        <img src="@/icon/1961831.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Hücre doldurma yöntem analizi
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              -->
            </b-col>
          </b-row>
        </template>
        <template v-if="d_selectedMenu ==='general'">
          <b-row style="height: 75px; margin-left: 0px;;">
            <b-col cols="12">
              <div style="float: left;">
                <img src="@/icon/2979152.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                Proje
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-button variant="white" size="sm" @click="f_getUserPolestarProjects()" title="Proje Listemi Aç">
                    <img src="@/icon/2504347.png" style="width: 2em;" />
                  </b-button>
                </div>
                <div style="float: left; min-width: 50px;">
                  <b-button variant="white" size="sm" @click="f_editProject()" title="Projeyi düzenle">
                    <img src="@/icon/3094236.png" style="width: 2em;" />
                  </b-button>
                </div>
                <div style="float: left; min-width: 50px;">
                  <b-button variant="white" size="sm" @click="f_copyPolestarProject()" title="Proje & Dataset Kopyala">
                    <img src="@/icon/2832792.png" style="width: 2em;" />
                  </b-button>
                </div>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="trash_dataset" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/2960590.png" style="width: 2em;" />
                    </template>
                    <b-dropdown-item @click="f_deleteProjectDatasetList()" title="Proje Datasetlerini Sil">
                      <img src="@/icon/2960590.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Proje Datasetlerini Sil
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_deleteProjectDatasetList('yes')" title="Proje Datasetlerini Sil (Kolonları da sil)">
                      <img src="@/icon/2960590.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Proje Datasetlerini Sil (Kolonları da sil)
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div style="float: left; min-width: 50px;" v-if="p_selectedProjectData.data && p_selectedProjectData.data.general.project_data_type && p_selectedProjectData.data.general.project_data_type.val && p_selectedProjectData.data.general.project_data_type.val.value && ['layer'].indexOf(p_selectedProjectData.data.general.project_data_type.val.value) !== -1">
                  <b-dropdown id="new" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/1430869.png" style="width: 2em;" />
                    </template>
                    <b-dropdown-item @click="f_editMainRuleAlgorithm()" title="Katmana ana filtre algoritma kuralı tanımla">
                      <img src="@/icon/1430869.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Ana kural algoritması düzenle
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_getSqlOfMainRule()" title="Katmana ana filtre algoritma kuralı tanımla">
                      <img src="@/icon/1430869.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Ana kural algoritması SQLini göster
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <!-- <div style="float: left; min-width: 50px;">
                  <b-dropdown variant="white" style="padding: 0px;">
                    <template slot="button-content">
                      <img src="@/icon/2976082.png" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item @click="go_to_this_project_details()">
                      <img src="@/icon/2957896.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Proje Detaylarına Git
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_goToProjectList()">
                      <img src="@/icon/2957896.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Proje Listesine Git
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_getUserPolestarProjects()">
                      <img src="@/icon/2957896.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Proje Listemi Aç</b-dropdown-item>
                    <b-dropdown-item @click="f_editProject()">
                      <img src="@/icon/2957896.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Proje Düzenle
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_copyPolestarProject()">
                      <img src="@/icon/2957896.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Proje & Dataset Kopyala
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_editMainRuleAlgorithm()">
                      <img src="@/icon/2957896.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Katmana ana filtre algoritma kuralı tanımla
                    </b-dropdown-item>
                  </b-dropdown>
                </div> -->
              </div>
              <!--               <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                Wai
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown variant="white" style="padding: 0px;">
                    <template slot="button-content">
                      <img src="@/icon/2324511.png" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item @click="f_publishAsWaim()">
                      <img src="@/icon/2957896.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Yapay Zeka Modeli Olarak Yayınla</b-dropdown-item>
                  </b-dropdown>
                </div>
              </div> -->
<!--               <div style="float: left; min-width: 75px; height: 100%; padding: 3px; border-left: solid 1px #baafaf;">
                Hücre
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-button variant="white" size="sm" @click="f_menuStatusControl('cell_selection_mode', 'one_cell_select_mode')" title="Tek hücre seçim modu">
                    <img src="@/icon/1733173.png" :style="d_dataset.menu && d_dataset.menu.data.cell_selection_mode && d_dataset.menu.data.cell_selection_mode.status ==='one_cell_select_mode' ?'border: solid 1px #66af99; width: 2em;' :'width: 2em;'" />
                  </b-button>
                </div>
                <div style="float: left; min-width: 50px;">
                  <b-button variant="white" size="sm" @click="f_menuStatusControl('cell_selection_mode', 'many_cell_select_mode')" title="Çoklu hücre seçim modu">
                    <img src="@/icon/2751062.png" :style="d_dataset.menu && d_dataset.menu.data.cell_selection_mode && d_dataset.menu.data.cell_selection_mode.status ==='many_cell_select_mode' ?'border: solid 1px #66af99; width: 2em;' :'width: 2em;'" />
                  </b-button>
                </div>
              </div> -->
            </b-col>
          </b-row>
        </template>
        <template v-if="d_selectedMenu ==='statistics'">
          <b-row key="up_side_statistics" style="height: 75px; margin-left: 0px;;">
            <b-col cols="12">
              <div style="float: left;">
                <img src="@/icon/1240861.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                Hızlı test
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="new" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/2956643.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item @click="p_f_fastAnalyzeMode()">
                      <img src="@/icon/2063326.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Hızlı Analiz Modu
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                Tanımlayıcı
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="descriptive" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/1240861.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item @click="f_selectAnalyze('mean')">
                      <img src="@/icon/986801.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Ortalama (mean)
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_selectAnalyze('median')">
                      <img src="@/icon/986801.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Ortanca (median)
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_selectAnalyze('frequency')">
                      <img src="@/icon/986801.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Oran & Sıklık (frequency)
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_selectAnalyze('cross_columns')">
                      <img src="@/icon/986801.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Çapraz Kolonlar (cross columns)
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="article" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/680848.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item @click="f_selectAnalyze('detailed_descriptive_stats','i_will_select_columns')">
                      <img src="@/icon/2063326.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Detaylı Tanımlayıcı İstatistik Yap [tüm testler] (kolonları ben seçeyim)
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_selectAnalyze('detailed_descriptive_stats','numeric_columns')">
                      <img src="@/icon/2063326.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Detaylı tanımlayıcı istatistik yap [tüm testler] (sayısal kolonlar)
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_selectAnalyze('detailed_descriptive_stats','categorical_columns')">
                      <img src="@/icon/2063326.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Detaylı tanımlayıcı istatistik yap [tüm testler] (kategorik kolonlar)
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_selectAnalyze('detailed_descriptive_stats','all_columns')">
                      <img src="@/icon/2063326.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Detaylı tanımlayıcı istatistik yap [tüm testler] (tüm kolonlar otomatik seçilsin)
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_selectAnalyze('detailed_descriptive_stats','i_will_select_columns_test_group_1')">
                      <img src="@/icon/2063326.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Detaylı Tanımlayıcı İstatistik Yap [test grubu - 1 (ortalama, ortanca, standart sapma, min, max, skewness, kurtosis, sıklık, shapiro )] (kolonları ben seçeyim)
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_selectAnalyze('detailed_descriptive_stats','all_columns_test_group_1')">
                      <img src="@/icon/2063326.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Detaylı tanımlayıcı istatistik yap [test grubu - 1 (ortalama, ortanca, standart sapma, min, max, skewness, kurtosis, sıklık, shapiro )] (tüm kolonlar otomatik seçilsin)
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                İşlem
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="math_operators" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/1053029.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item @click="f_selectAnalyze('sum')">
                      <img src="@/icon/109638.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Değerleri Topla (+)
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                Önce-sonra (sayısal)
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="comparison" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/453632.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item @click="f_selectAnalyze('before_after_comparison')">
                      <img src="@/icon/986801.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Önce Sonra Analizi Yap
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px; border-left: solid 1px #baafaf;">
                Kategorik
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="descriptive" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/1841897.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item @click="f_selectAnalyze('frequency')">
                      <img src="@/icon/1841897.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Oran & Sıklık (frequency)
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;border-left: solid 1px #baafaf;">
                Sayısal
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="descriptive" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/2557738.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item @click="f_selectAnalyze('mean')">
                      <img src="@/icon/2557738.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Ortalama (mean)
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_selectAnalyze('median')">
                      <img src="@/icon/2557738.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Ortanca (median)
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_selectAnalyze('max')">
                      <img src="@/icon/2557738.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Maksimum değer (max)
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_selectAnalyze('min')">
                      <img src="@/icon/2557738.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Minimum değer (min)
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_selectAnalyze('standart_deviation')">
                      <img src="@/icon/2557738.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Standart sapma
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="descriptive" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/1425410.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item @click="f_selectAnalyze('outliers_zindex')">
                      <img src="@/icon/1425410.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Aykırı değer (z-index)
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_selectAnalyze('outliers_interquartile_range')">
                      <img src="@/icon/1425410.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Aykırı değer (interquartile range)
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_selectAnalyze('skewness')">
                      <img src="@/icon/1425410.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Veri dağılımı (eğim, skewness)
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_selectAnalyze('kurtosis')">
                      <img src="@/icon/1425410.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Veri dağılımı (basıklık, kurtosis)
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_selectAnalyze('kolmogorov_smirnov')">
                      <img src="@/icon/1425410.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Veri dağılımı (kolmogorov smirnov)
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_selectAnalyze('shapiro_wilk')">
                      <img src="@/icon/1425410.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Veri dağılımı (shapiro wilk)
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px; border-left: solid 1px #baafaf;">
                <span title="Kategorik & Kategorik">Kat. & Kat.</span>
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="categorical_categorical" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/570172.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item @click="f_selectAnalyze('cross_columns', 'categorical_categorical_descriptive')" title="Bu seçimi yaptıktan sonra kategorik 2 kolon seçtiğinizde sıklıklarını karşılaştırarak gösterecektir.">
                      <img src="@/icon/570172.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Tanımlayıcı istatistik (sıklık karşılaştırması)
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_selectAnalyze('cross_columns', 'categorical_categorical_chisquare')" title="Kategorik 2 kolon seçildiğinde sıklıklarını kikare ile karşılaştırarak istatistiksel olarak analiz edilecektir.">
                      <img src="@/icon/570172.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Kikare
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_selectAnalyze('cross_columns', 'categorical_categorical_fisherexact')" title="Kategorik 2 kolon seçildiğinde sıklıklarını fisher exact ile karşılaştırarak analiz edilecektir.">
                      <img src="@/icon/570172.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Fisher Exact
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_selectAnalyze('cross_columns', 'categorical_categorical_chisquare_fisherexact')" title="Kategorik 2 kolon seçildiğinde sıklıklarını hem kikare hem de fisher exact ile karşılaştırarak analiz edilecektir.">
                      <img src="@/icon/570172.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Kikare + Fisher Exact
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                <span title="Kategorik & Sayısal">Kat. & Say.</span>
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="descriptive" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/categorical_numeric.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item @click="f_selectAnalyze('cross_columns', 'categorical_numeric_descriptive')" title="Bu seçimi yaptıktan sonra 1 kategorik kolon 1 de sayısal kolon seçtiğinizde kategorilerdeki ortalamaları karşılaştırarak gösterecektir.">
                      <img src="@/icon/categorical_numeric.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Tanımlayıcı istatistik (ortalamaların karşılaştırılması)
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_selectAnalyze('cross_columns', 'categorical_numeric_t_test')" title="Bu seçimi yaptıktan sonra 1 kategorik kolon 1 de sayısal kolon seçtiğinizde kategorilerdeki ortalamaları istatistiksel olarak karşılaştırarak gösterecektir.">
                      <img src="@/icon/categorical_numeric.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> t test (2 grup)
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_selectAnalyze('cross_columns', 'categorical_numeric_mann_whitney_u')" title="Bu seçimi yaptıktan sonra 1 kategorik kolon 1 de sayısal kolon seçtiğinizde kategorilerdeki ortalamaları istatistiksel olarak karşılaştırarak gösterecektir.">
                      <img src="@/icon/categorical_numeric.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Mann Whitney U (2 grup)
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_selectAnalyze('cross_columns', 'categorical_numeric_oneway_anova')" title="Bu seçimi yaptıktan sonra 1 kategorik kolon 1 de sayısal kolon seçtiğinizde kategorilerdeki ortalamaları istatistiksel olarak karşılaştırarak gösterecektir.">
                      <img src="@/icon/categorical_numeric.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Oneway ANOVA (2 ya da daha fazla grup)
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_selectAnalyze('cross_columns', 'categorical_numeric_kruskal_wallis')" title="Bu seçimi yaptıktan sonra 1 kategorik kolon 1 de sayısal kolon seçtiğinizde kategorilerdeki ortalamaları istatistiksel olarak karşılaştırarak gösterecektir.">
                      <img src="@/icon/categorical_numeric.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Kruskal Wallis (2 ya da daha fazla grup)
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                <span title="Sayısal & Sayısal">Say. & Say.</span>
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="descriptive" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/3543991.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item @click="f_selectAnalyze('cross_columns', 'numeric_numeric_pearson_corelation')" title="İki adet sayısal kolon seçimi yapılarak korelasyon analizi pearson korelasyon istatistiksel testi kullanılarak uygulanır.">
                      <img src="@/icon/3543991.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Pearson Korelasyon
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_selectAnalyze('cross_columns', 'numeric_numeric_spearman')" title="İki adet sayısal kolon seçimi yapılarak korelasyon analizi spearman korelasyon istatistiksel testi kullanılarak uygulanır.">
                      <img src="@/icon/3543991.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Spearman Korelasyon
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_selectAnalyze('before_after_comparison', 'numeric_numeric_pairs_sample_t_test')" title="Bağımlı örneklem t-testi, bir değişkenin, iki farklı durumda gözlemlenen değerlerinin ortalamalarını karşılaştırır.">
                      <img src="@/icon/3543991.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Paired sample t test (önce-sonra analizi) (bağımlı örneklem t testi)
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                <span title="Sayısal & Kategorik">Say. & Kat.</span>
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="descriptive" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/numeric_categorical.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item @click="f_selectAnalyze('cross_columns', 'numeric_categorical_logistic_regression')" title="Bir sayısal bir de kategorik kolon seçimi yapıldığında sayısal verilerin kategorik verilerdeki etkisi lojistik regresyon analizi ile değerlendirilir.">
                      <img src="@/icon/numeric_categorical.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Lojistik regresyon
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                <span title="Sayısal & Kategorik">Otomatik</span>
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="descriptive" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/numeric_categorical.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <!-- <b-dropdown-item @click="f_selectAnalyze('cross_columns', 'xxxxxxxxxxx')" title="Seçilen kolonlar arasında otomatik ikili kombinasyonlar üreterek karşılaştırmalar yapılmaktadır. Ayrıca seçilen istatistiksel karşılaştırma testleri de var ise bu analizleri de ek olarak gerçekleştirmektedir. Uygun olan tüm kolonlara kombinasyon analzlerine alınmaktadır.">
                      <img src="@/icon/numeric_categorical.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Otomatik ikili kombinasyon (tüm kolonlar)
                    </b-dropdown-item> -->
                    <b-dropdown-item @click="f_selectAnalyze('cross_columns', 'auto_1')" title="Seçilen kolonlar arasında otomatik ikili kombinasyonlar üreterek karşılaştırmalar yapılmaktadır. Ayrıca seçilen istatistiksel karşılaştırma testleri de var ise bu analizleri de ek olarak gerçekleştirmektedir. ">
                      <img src="@/icon/numeric_categorical.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Otomatik-1 [Oneway ANOVA, Kruskal Wallis, Mann Whitney U, t-test, Kikare, Fisher Exact, Pearson Korelasyon, Spearman Korelasyon]
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </b-col>
          </b-row>
        </template>
        <template v-if="d_selectedMenu ==='machine_learning'">
          <b-row key="up_side_statistics" style="height: 75px; margin-left: 0px;;">
            <b-col cols="12">
              <div style="float: left;">
                <img src="@/icon/1368397.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                İşlemler
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="descriptive" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/1368397.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item @click="f_addMachineLearningModel()">
                      <img src="@/icon/986801.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Yeni makina öğrenmesi modeli ekle
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                Regresyon
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="descriptive" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/2312011.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item @click="f_addMachineLearningModel('logistic_regression')">
                      <img src="@/icon/2312011.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Yeni logistic regresyon modeli oluştur
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_addMachineLearningModel('linear_regression')">
                      <img src="@/icon/2312011.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Yeni lineer regresyon modeli oluştur
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                Destek Vektör Makina
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="descriptive" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/1368397.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item @click="f_addMachineLearningModel('support_vector_machine')">
                      <img src="@/icon/986801.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Yeni destek vektör makina öğrenme modeli oluştur
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                Karar Ağacı
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="descriptive" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/1368397.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item @click="f_addMachineLearningModel('decision_tree')">
                      <img src="@/icon/986801.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Yeni karar ağacı makina öğrenme modeli oluştur
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                K en yakın komşu
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="descriptive" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/1368397.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item @click="f_addMachineLearningModel('k_nearest_neighbor')">
                      <img src="@/icon/986801.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Yeni KNN makina öğrenme modeli oluştur
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                Rassal Orman
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="descriptive" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/1368397.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item @click="f_addMachineLearningModel('random_forest')">
                      <img src="@/icon/986801.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Yeni Rassal Orman makina öğrenme modeli oluştur
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <!--               <div style="float: left; min-width: 75px; height: 100%; padding: 3px; border-left: solid 1px #baafaf;">
                Sınıflandırma
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="descriptive" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/1368397.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item @click="f_addMachineLearningModel('logistic_regression')">
                      <img src="@/icon/986801.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Lojistik regresyon
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px; border-left: solid 1px #baafaf;">
                Tahmin
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="descriptive" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/1368397.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item @click="f_addMachineLearningModel('logistic_regression')">
                      <img src="@/icon/986801.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Lojistik regresyon
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div> -->
            </b-col>
          </b-row>
        </template>
        <template v-if="d_selectedMenu ==='view'">
          <b-row key="up_side_view" style="height: 75px; margin-left: 0px;;">
            <b-col cols="12">
              <!-- 
              <img src="@/icon/633951.png" @click="f_menuStatusControl('anonym_mode')" :style="d_dataset.menu && d_dataset.menu.data.anonym_mode  && d_dataset.menu.data.anonym_mode.status ?'width: 2em; border: solid 1px #66af99;' :'width: 2em;'" id="anonym_mode" />
              <b-popover :target="'anonym_mode'" triggers="hover focus">
                <template slot="title">wisdom stats</template>
                Anonimize veriler
              </b-popover>
               -->
              <div style="float: left; height: 100%; padding: 3px;">
                <img src="@/icon/680848.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
              </div>
              <div style="float: left; border-left: solid 1px #baafaf; height: 100%; padding: 3px;">
                Dataset:
                <br>
                <!-- <div style="float: left; width: 50px;">
                  <div style="width: 50px; float: left;">back:</div>
                  <img src="@/icon/3082135.png" @click="f_menuStatusControl('labels_wdm_location_format')" :style="d_dataset.menu && d_dataset.menu.data.labels_wdm_location_format  && d_dataset.menu.data.labels_wdm_location_format.status ?'width: 2em; border: solid 1px #66af99;' :'width: 2em;'" id="labels_wdm_location_format" />
                  <b-popover :target="'labels_wdm_location_format'" triggers="hover focus">
                    <template slot="title">wisdom stats</template>
                    Başlıkları parametre lokasyonu şeklinde göster
                  </b-popover>
                </div> -->
                <div style="float: left; width: 50px;">
                  <img src="@/icon/2301012.png" @click="f_menuStatusControl('show_date_mode')" :style="d_dataset.menu && d_dataset.menu.data.show_date_mode && d_dataset.menu.data.show_date_mode.status ?'width: 2em; border: solid 1px #66af99;' :'width: 2em;'" id="show_date_mode" />
                  <b-popover :target="'show_date_mode'" triggers="hover focus">
                    <template slot="title">wisdom stats</template>
                    Hücrelerdeki verileri tarihleriyle birlikte gösterir
                  </b-popover>
                </div>
                <div style="float: left; width: 50px;">
                  <img src="@/icon/2991711.png" @click="f_menuStatusControl('row_id_details')" :style="d_dataset.menu && d_dataset.menu.data.row_id_details && d_dataset.menu.data.row_id_details.status ?'width: 2em; border: solid 1px #66af99;' :'width: 2em;'" id="row_id_details" />
                  <b-popover :target="'row_id_details'" triggers="hover focus">
                    <template slot="title">wisdom stats</template>
                    Satır başlarında detayları göster
                  </b-popover>
                </div>
                <!-- <div style="float: left; width: 50px;">
                  <img src="@/icon/453632.png" @click="f_menuStatusControl('show_hims_auto_match')" :style="d_dataset.menu && d_dataset.menu.data.show_hims_auto_match && d_dataset.menu.data.show_hims_auto_match.status ?'width: 2em; border: solid 1px #66af99;' :'width: 2em;'" id="show_hims_auto_match" />
                  <b-popover :target="'show_hims_auto_match'" triggers="hover focus">
                    <template slot="title">wisdom stats</template>
                    HBYS verilerinden otomatik olarak getirilen hücreleri göster
                  </b-popover>
                </div> -->
              </div>
              <div style="float: left; border-left: solid 1px #baafaf; height: 100%; padding: 3px;">
                Stil:
                <br>
                <div style="float: left;">
                  <div style="width: 100px; float: left;">arkaplan:</div>
                  <input style="border: 0px; padding: 0px; margin-right: 5px;" type="color" title="Genel arka plan" v-model="p_polestarSettings.style.backgroundColor" @change="f_changeStyle()">
                  <br>
                  <div style="width: 100px; float: left;">yazı:</div>
                  <input style="border: 0px; padding: 0px; margin-right: 5px;" type="color" title="Genel yazı rengi" v-model="p_polestarSettings.style.color" @change="f_changeStyle()">
                </div>
                <!--                 <div style="float: left;">
                  <div style="width: 50px; float: left;">sms:</div>
                  <input style="border: 0px; padding: 0px; margin-right: 5px;" type="color" title="Genel arka plan" v-model="p_polestarSettings.style.sms.backgroundColor" @change="f_changeStyle()">
                  <br>
                  <div style="width: 50px; float: left;">mail:</div>
                  <input style="border: 0px; padding: 0px; margin-right: 5px;" type="color" title="Genel yazı rengi" v-model="p_polestarSettings.style.mail.backgroundColor" @change="f_changeStyle()">
                </div> -->
                <div style="float: left;">
                  <div style="width: 100px; float: left;">başlık-arkaplan:</div>
                  <input style="border: 0px; padding: 0px; margin-right: 5px;" type="color" title="Genel arka plan" v-model="p_polestarSettings.style.project_header.backgroundColor" @change="f_changeStyle()">
                  <br>
                  <div style="width: 100px; float: left;">başlık-yazı:</div>
                  <input style="border: 0px; padding: 0px; margin-right: 5px;" type="color" title="Genel yazı rengi" v-model="p_polestarSettings.style.project_header.color" @change="f_changeStyle()">
                </div>
              </div>
              <div style="float: left; border-left: solid 1px #baafaf; height: 100%; padding: 3px;">
                İşlemler:
                <br>
                <b-dropdown variant="white" style="padding: 0px;">
                  <template slot="button-content">
                    <img src="@/icon/3532765.png" style="border-radius: 50%; width: 2em;" />
                  </template>
                  <b-dropdown-item @click="f_saveProjectStyleForMe()" title="Bu stili sadece kaydeden kullanıcı görüntüler">
                    <img src="@/icon/719135.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> stili kaydet
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-col>
          </b-row>
        </template>
        <template v-if="d_selectedMenu ==='data'">
          <b-row key="up_side_data" style="height: 75px; margin-left: 0px;;">
            <b-col cols="12">
              <div style="float: left;">
                <img src="@/icon/2556829.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
              </div>
              <!-- 
                <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                  İşlemler
                  <br>
                  <div style="float: left; min-width: 50px;">
                    <b-button variant="white" size="sm" @click="f_saveDataset()" title="Dataseti kaydet">
                      <img src="@/icon/2087726.png" style="width: 2em;" />
                    </b-button>
                    <br>
                  </div>
                </div>
               -->
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;border-left: solid 1px #baafaf;" v-if="p_selectedProjectData.data && p_selectedProjectData.data.general.project_data_type && p_selectedProjectData.data.general.project_data_type.val && p_selectedProjectData.data.general.project_data_type.val.value && ['layer', 'mssql'].indexOf(p_selectedProjectData.data.general.project_data_type.val.value) !== -1">
                Veri Hazırla
                <br>
                <div style="float: left; width: 50px;">
                  <b-dropdown variant="white" title="Ana veri katmanından veri hazırla (tüm verileri silerek). Projeye tanımladığınız ana katmanda veri varsa ekler. Öncelikle tüm satırları temizler. Ana algoritma kuralı hazırladıysanız öncelikle her ana katman için bu kuralı çalıştırır. Bu algoritma kuralına uygun olan verilerden satır üretir.">
                    <template slot="button-content">
                      <img src="@/icon/2556829.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" />
                    </template>
                    <b-dropdown-item @click="f_prepareProjectCases('delete_all_and_recreate', 'manuel')">
                      <img src="@/icon/2556829.png" style="width: 2em;" /> sadece veri güncelleme
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_prepareProjectCases('delete_all_and_recreate', 'full')">
                      <img src="@/icon/2556829.png" style="width: 2em;" /> veri güncelleme + proje ayarlarına göre diğer işlemleri (algoritma analizi v.b) de yap
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div style="float: left; width: 50px;">
                  <b-dropdown variant="white" title="Ana veri katmanından veri hazırla (sadece yeni veri ekler). Projeye tanımladığınız ana katmanda yeni satır oluşturacak veri varsa ekler. Eski satırları silmez. Ana algoritma kuralı hazırladıysanız öncelikle her ana katman için bu kuralı çalıştırır. Bu algoritma kuralına uygun olan verilerden yeni satır üretir.">
                    <template slot="button-content">
                      <img src="@/icon/1723098.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" />
                    </template>
                    <b-dropdown-item @click="f_prepareProjectCases('only_add_new_cases', 'manuel')">
                      <img src="@/icon/1723098.png" style="width: 2em;" /> sadece veri güncelleme
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_prepareProjectCases('only_add_new_cases', 'full')">
                      <img src="@/icon/1723098.png" style="width: 2em;" /> veri güncelleme + proje ayarlarına göre diğer işlemleri (algoritma analizi v.b) de yap
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div style="float: left; width: 50px;">
                  <b-dropdown variant="white" title="Sadece mevcut satır verilerini günceller. Yeni veri eklemez. Eski satırları silmez. Ana algoritma kuralını kontrol etmez. Algoritma kolon analiz verilerini temizler.">
                    <template slot="button-content">
                      <img src="@/icon/3094236.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" />
                    </template>
                    <b-dropdown-item @click="f_prepareProjectCases('delete_manuel_edit', 'manuel')">
                      <img src="@/icon/3094236.png" style="width: 2em;" /> sadece veri güncelleme
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_prepareProjectCases('delete_manuel_edit', 'full')">
                      <img src="@/icon/3094236.png" style="width: 2em;" /> veri güncelleme + proje ayarlarına göre diğer işlemleri (algoritma analizi v.b) de yap
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div style="float: left; width: 50px;">
                  <b-dropdown variant="white" title="Sadece mevcut satır verilerini günceller. Yeni veri eklemez. Eski satırları silmez. Ana algoritma kuralını kontrol etmez. Algoritma kolon analiz verilerini silmez.">
                    <template slot="button-content">
                      <img src="@/icon/3092008.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" />
                    </template>
                    <b-dropdown-item @click="f_prepareProjectCases('delete_manuel_edit_not_delete_algorithm_columns', 'manuel')">
                      <img src="@/icon/3092008.png" style="width: 2em;" /> sadece veri güncelleme
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_prepareProjectCases('delete_manuel_edit_not_delete_algorithm_columns', 'full')">
                      <img src="@/icon/3092008.png" style="width: 2em;" /> veri güncelleme + proje ayarlarına göre diğer işlemleri (algoritma analizi v.b) de yap
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div style="float: left; width: 50px;">
                  <b-dropdown variant="white" title="Mevcut verileri güncelle (tüm hücreleri değiştir) ve yeni katman verileri tespit ederek ekle. Ana algoritma kuralı varsa kullanır. Algoritma kolon analiz verilerini temizler.">
                    <template slot="button-content">
                      <img src="@/icon/1368397.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" />
                    </template>
                    <b-dropdown-item @click="f_prepareProjectCases('update_dataset_all_cells_add_new_data', 'manuel')">
                      <img src="@/icon/1368397.png" style="width: 2em;" /> sadece veri güncelleme
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_prepareProjectCases('update_dataset_all_cells_add_new_data', 'full')">
                      <img src="@/icon/1368397.png" style="width: 2em;" /> veri güncelleme + proje ayarlarına göre diğer işlemleri (algoritma analizi v.b) de yap
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div style="float: left; width: 50px;">
                  <b-dropdown variant="white" title="Mevcut verileri güncelle (tüm hücreleri değiştir) ve yeni katman verileri tespit ederek ekle. Ana algoritma kuralı varsa kullanır. Algoritma kolon analiz verilerini silmez">
                    <template slot="button-content">
                      <img src="@/icon/139088.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" />
                    </template>
                    <b-dropdown-item @click="f_prepareProjectCases('update_dataset_all_cells_add_new_data_not_delete_algorithm_columns', 'manuel')">
                      <img src="@/icon/139088.png" style="width: 2em;" /> sadece veri güncelleme
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_prepareProjectCases('update_dataset_all_cells_add_new_data_not_delete_algorithm_columns', 'full')">
                      <img src="@/icon/139088.png" style="width: 2em;" /> veri güncelleme + proje ayarlarına göre diğer işlemleri (algoritma analizi v.b) de yap
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div style="float: left; width: 50px;">
                  <b-button variant="white" size="sm" @click="f_cancelProjectUpdate()" title="İşlemleri durdur">
                    <img src="@/icon/1890926.png" style="width: 2em;" />
                  </b-button>
                </div>
              </div>
              <!-- <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                Update
                <br>
                <div style="float: left; width: 50px;">
                  <b-dropdown variant="white">
                    <template slot="button-content">
                      <img src="@/icon/1686902.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item @click="f_updateWdmrCells('', 'not_delete_manuel_edit', 'all')">
                      <img src="@/icon/1686902.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> satır verilerini güncelle (yeni satır eklenmez, manuel wdm düzenlemelerini güncellemeden)</b-dropdown-item>
                    <b-dropdown-item @click="f_updateWdmrCells('', 'delete_manuel_edit', 'all')">
                      <img src="@/icon/1686902.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> satır verilerini güncelle (yeni satır eklenmez, manuel wdm düzenlemelerini güncelleyerek)</b-dropdown-item>
                  </b-dropdown>
                  <br>
                </div>
              </div> -->
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;border-left: solid 1px #baafaf;" v-if="p_selectedProjectData.data && p_selectedProjectData.data.general.project_data_type && p_selectedProjectData.data.general.project_data_type.val && p_selectedProjectData.data.general.project_data_type.val.value && ['layer'].indexOf(p_selectedProjectData.data.general.project_data_type.val.value) !== -1 && p_selectedProjectData.data.general.layer_region && p_selectedProjectData.data.general.layer_region.val && p_selectedProjectData.data.general.layer_region.val.value && ['layer_tree'].indexOf(p_selectedProjectData.data.general.layer_region.val.value) !== -1">
                Veri Adedi
                <br>
                <div style="float: left; width: 50px;">
                  <b-dropdown variant="white">
                    <template slot="button-content">
                      <img src="@/icon/1584256.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" />
                    </template>
                    <!--                     <b-dropdown-item @click="f_searchTextAnalyzeOnWdmrs()" title="Tüm kolonlara eklenmiş olan ifade çalışmalarını, projeye eklenmiş olan veri modellerine ait kayıtlarda, yani veri katmanındaki veriler üzerinde yapar. Datasete ilgili veri modellerine ait parametre verilerini datasete çekmeden ifade analizi bu sayede yapılabilmektedir.">
                      <img src="@/icon/1584256.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Veri katmanında ifade çalışması
                    </b-dropdown-item>
 -->
                    <b-dropdown-item @click="f_prepareProjectCases('calculate_case_count', 'manuel')" title="Ana kurala uygun veri adedini hesaplar.">
                      <img src="@/icon/1584256.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Veri Adedi Hesapla
                    </b-dropdown-item>
                  </b-dropdown>
                  <br>
                </div>
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;border-left: solid 1px #baafaf;">
                İfade
                <br>
                <div style="float: left; width: 50px;">
                  <b-dropdown variant="white">
                    <template slot="button-content">
                      <img src="@/icon/1584256.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" />
                    </template>
                    <!--                     <b-dropdown-item @click="f_searchTextAnalyzeOnWdmrs()" title="Tüm kolonlara eklenmiş olan ifade çalışmalarını, projeye eklenmiş olan veri modellerine ait kayıtlarda, yani veri katmanındaki veriler üzerinde yapar. Datasete ilgili veri modellerine ait parametre verilerini datasete çekmeden ifade analizi bu sayede yapılabilmektedir.">
                      <img src="@/icon/1584256.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Veri katmanında ifade çalışması
                    </b-dropdown-item>
 -->
                    <b-dropdown-item @click="f_addNewPhraseModal()" title="Yeni ifade çalışması ekle">
                      <img src="@/icon/1584256.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Yeni ifade çalışması ekle
                    </b-dropdown-item>
                  </b-dropdown>
                  <br>
                </div>
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px; border-left: solid 1px #baafaf;">
                Otomatik seçenek seçimi
                <br>
                <div style="float: left;">
                  <!-- <b-dropdown variant="white">
                    <template slot="button-content">
                      <img src="@/icon/2903969.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Katman verisi ile
                    </template>
                    <b-dropdown-item @click="f_updateCellsByWdmrRule('all', '', 'every_cell')">
                      <img src="@/icon/2903969.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Seçenekli kolonlarda, katman verisinden metin madenciliği yapılarak eşleştirilen seçeneği seç (dolu ve boş hücreler)
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_updateCellsByWdmrRule('all', '', 'only_free')">
                      <img src="@/icon/2903969.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Seçenekli kolonlarda, katman verisinden metin madenciliği yapılarak eşleştirilen seçeneği seç (sadece boş hücreler)
                    </b-dropdown-item>
                  </b-dropdown> -->
                  <b-dropdown variant="white">
                    <template slot="button-content">
                      <img src="@/icon/1430869.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Kolon kural alg ile
                    </template>
                    <b-dropdown-item @click="f_updateCellsByColumnOptionRule('all', '', 'every_cell')">
                      <img src="@/icon/1430869.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Seçenekli kolonlarda, kolon kural algoritması ile seçeneği seç (dolu ve boş hücreler)
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_updateCellsByColumnOptionRule('all', '', 'only_free')">
                      <img src="@/icon/1430869.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Seçenekli kolonlarda, kolon kural algoritması ile seçeneği seç (sadece boş hücreler)
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_updateCellsByAllManuelColumns()">
                      <img src="@/icon/1430869.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Tüm manuel kolonlara ait hücre güncelleme işlemlerini başlat
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;border-left: solid 1px #baafaf;" v-if="p_selectedProjectData.data && p_selectedProjectData.data.general.api_name && p_selectedProjectData.data.general.api_name.val && p_selectedProjectData.data.general.api_name.val.value && ['pubmed'].indexOf(p_selectedProjectData.data.general.api_name.val.value) !== -1">
                Pubmed
                <br>
                <div style="float: left; width: 50px;">
                  <b-dropdown variant="white">
                    <template slot="button-content">
                      <img src="@/icon/1584256.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" />
                    </template>
                    <b-dropdown-item @click="f_pubmedArticleDatasetCreate()" title="Yeni ifade çalışması ekle">
                      <img src="@/icon/1584256.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Projeye Tanımlı Pubmed Dergilerini analiz ederek dataset oluştur.
                    </b-dropdown-item>
                  </b-dropdown>
                  <br>
                </div>
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;border-left: solid 1px #baafaf;">
                Proje Birleştir
                <br>
                <div style="float: left; width: 50px;">
                  <b-dropdown variant="white">
                    <template slot="button-content">
                      <img src="@/icon/1584256.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" />
                    </template>
                    <b-dropdown-item @click="f_mergeProjects()" title="Projeleri Birleştir">
                      <img src="@/icon/1584256.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Projeleri birleştirerek verileri hazırla
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_openProjectlistForColumnSelectionMerge()" title="Proje kolon seçim ekranını aç">
                      <img src="@/icon/1584256.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Projeleri birleştirmek için kolon seçimlerini yap
                    </b-dropdown-item>
                  </b-dropdown>
                  <br>
                </div>
              </div>
              <!-- <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                Wdmr & Kolon
                <br>
                <div style="float: left; width: 50px;">
                  <b-dropdown variant="white">
                    <template slot="button-content">
                      <img src="@/icon/3428345.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" />
                    </template>
                    <b-dropdown-item @click="f_updateCellsByColumnOptionMixedWdmrRule('all', '', 'every_cell')">
                      <img src="@/icon/3428345.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> tüm seçenekleri kolon algoritma ve wdmr algoritma analizleri beraber sağlandığında otomatik doldur (dolu olan hücreleri de değiştir)
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_updateCellsByColumnOptionMixedWdmrRule('all', '', 'only_free')">
                      <img src="@/icon/3428345.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> tüm seçenekleri kolon algoritma ve wdmr algoritma analizleri beraber sağlandığında otomatik doldur (sadece boş olan hücreleri doldur)
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div> -->
              <!-- <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                Hiyerarşik
                <br>
                <div style="float: left; width: 50px;">
                  <b-dropdown variant="white">
                    <template slot="button-content">
                      <img src="@/icon/1705440.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" />
                    </template>
                    <b-dropdown-item @click="f_updateCellsByFollowingRuleHiearchy('all', '', 'every_cell')">
                      <img src="@/icon/1705440.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> tüm seçenekleri rule hiyerarşisine göre otomatik doldur (dolu olan hücreleri de değiştir)
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_updateCellsByFollowingRuleHiearchy('all', '', 'only_free')">
                      <img src="@/icon/1705440.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> tüm seçenekleri rule hiyerarşisine göre otomatik doldur (sadece boş olan hücreleri doldur)
                    </b-dropdown-item>
                    <b-dropdown-item @click="f_updateSelectedCellsByFollowingRuleHiearchy()">
                      <img src="@/icon/1705440.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> seçili seçenekleri rule hiyerarşisine göre otomatik doldur (sadece boş olan hücreleri doldur)
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div> -->
            </b-col>
          </b-row>
        </template>
        <template v-if="d_selectedMenu ==='settings'">
          <b-row key="up_side_settings" style="height: 75px; margin-left: 0px;;">
            <b-col cols="12">
              <div style="float: left;">
                <img src="@/icon/3532765.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
              </div>
              <!--               <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                Sms
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="project_sms_provider" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/1013986.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item @click="f_openProjectSmsProviderModal()">
                      <img src="@/icon/934385.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Sms Sağlayıcı Ekle
                    </b-dropdown-item>
                  </b-dropdown>
                  <br>
                </div>
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                Mail
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="project_sms_provider" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/110598.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item @click="f_openProjectMailProviderModal()">
                      <img src="@/icon/110598.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Mail Sağlayıcı Ekle
                    </b-dropdown-item>
                  </b-dropdown>
                  <br>
                </div>
              </div> -->
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                Kullanıcı
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="project_sms_provider" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/2940373.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item @click="f_editProject()">
                      <img src="@/icon/2940373.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Kullanıcı yetkileri düzenle
                    </b-dropdown-item>
                  </b-dropdown>
                  <br>
                </div>
              </div>
            </b-col>
          </b-row>
        </template>
        <template v-if="d_selectedMenu ==='help'">
          <b-row key="up_side_help" style="height: 75px; margin-left: 0px;;">
            <b-col cols="12">
              <div style="float: left;">
                <img src="@/icon/863488.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
              </div>
              <div style="float: left; min-width: 50px;">
                <b-dropdown variant="white" style="padding: 0px;">
                  <template slot="button-content">
                    <img src="@/icon/2979152.png" style="border-radius: 50%; width: 2em;" />
                    <span :style="'color:' + p_polestarSettings.style.color">Genel</span>
                  </template>
                  <b-dropdown-item @click="f_goToDocsWisdomera('https://docs.wisdomera.io/#/documenttree?wdmr231=66')">
                    <img src="@/icon/2979152.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> genel dokümantasyon
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div style="float: left; min-width: 50px;">
                <b-dropdown variant="white" style="padding: 0px;">
                  <template slot="button-content">
                    <img src="@/icon/1430869.png" style="border-radius: 50%; width: 2em;" />
                    <span :style="'color:' + p_polestarSettings.style.color">Wanalyzer projesi</span>
                  </template>
                  <b-dropdown-item @click="f_goToDocsWisdomera('https://docs.wisdomera.io/#/documenttree?wdmr231=70')">
                    <img src="@/icon/1430869.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> proje hakkında
                  </b-dropdown-item>
                  <b-dropdown-item @click="f_goToDocsWisdomera('https://docs.wisdomera.io/#/documenttree?wdmr231=82')">
                    <img src="@/icon/1430869.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> proje nasıl oluşturulur ?
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div style="float: left; min-width: 50px;">
                <b-dropdown variant="white" style="padding: 0px;">
                  <template slot="button-content">
                    <img src="@/icon/2884572.png" style="border-radius: 50%; width: 2em;" />
                    <span :style="'color:' + p_polestarSettings.style.color">İstatistik</span>
                  </template>
                  <b-dropdown-item @click="f_goToDocsWisdomera('https://docs.wisdomera.io/#/documenttree?wdmr231=84')">
                    <img src="@/icon/2884572.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> istatistik oluşturma
                  </b-dropdown-item>
                  <b-dropdown-item @click="f_goToDocsWisdomera('https://docs.wisdomera.io/#/documenttree?wdmr231=88')">
                    <img src="@/icon/2884572.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> istatistik sonuçlarına bakma
                  </b-dropdown-item>
                  <b-dropdown-item @click="f_goToDocsWisdomera('https://docs.wisdomera.io/#/documenttree?wdmr231=102')">
                    <img src="@/icon/2884572.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> hızlı istatistik
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div style="float: left; min-width: 50px;">
                <b-dropdown variant="white" style="padding: 0px;">
                  <template slot="button-content">
                    <img src="@/icon/2884572.png" style="border-radius: 50%; width: 2em;" />
                    <span :style="'color:' + p_polestarSettings.style.color">Makina öğrenmesi</span>
                  </template>
                  <b-dropdown-item @click="f_goToDocsWisdomera('https://docs.wisdomera.io/#/documenttree?wdmr231=92')">
                    <img src="@/icon/2884572.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> yeni model oluşturma
                  </b-dropdown-item>
                  <b-dropdown-item @click="f_goToDocsWisdomera('https://docs.wisdomera.io/#/documenttree?wdmr231=93')">
                    <img src="@/icon/2884572.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> modeli test etme
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div style="float: left; min-width: 50px;">
                <b-dropdown variant="white" style="padding: 0px;">
                  <template slot="button-content">
                    <img src="@/icon/2448909.png" style="border-radius: 50%; width: 2em;" />
                    <span :style="'color:' + p_polestarSettings.style.color">Kolon</span>
                  </template>
                  <b-dropdown-item @click="f_goToDocsWisdomera('https://docs.wisdomera.io/#/documenttree?wdmr231=95')">
                    <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> yeni wdm kolonu oluşturma
                  </b-dropdown-item>
                  <b-dropdown-item @click="f_goToDocsWisdomera('https://docs.wisdomera.io/#/documenttree?wdmr231=96')">
                    <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> yeni manuel kolon oluşturma
                  </b-dropdown-item>
                  <b-dropdown-item @click="f_goToDocsWisdomera('https://docs.wisdomera.io/#/documenttree?wdmr231=97')">
                    <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> kurallar ile yeni manuel kolon oluşturma
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div style="float: left; min-width: 50px;">
                <b-dropdown variant="white" style="padding: 0px;">
                  <template slot="button-content">
                    <img src="@/icon/2486994.png" style="border-radius: 50%; width: 2em;" />
                    <span :style="'color:' + p_polestarSettings.style.color">İfade çalışması</span>
                  </template>
                  <b-dropdown-item @click="f_goToDocsWisdomera('https://docs.wisdomera.io/#/documenttree?wdmr231=107')">
                    <img src="@/icon/2486994.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> ifade çalışması hakkında
                  </b-dropdown-item>
                  <b-dropdown-item @click="f_goToDocsWisdomera('https://docs.wisdomera.io/#/documenttree?wdmr231=108')">
                    <img src="@/icon/2486994.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> Wdmr parametrelerinde ifade çalışması
                  </b-dropdown-item>
                  <b-dropdown-item @click="f_goToDocsWisdomera('https://docs.wisdomera.io/#/documenttree?wdmr231=110')">
                    <img src="@/icon/2486994.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> ifade eşleştirme
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-col>
          </b-row>
        </template>
        <template v-if="d_selectedMenu ==='decisionmakingpackage'">
          <b-row key="up_side_decisionmakingpackage" style="height: 75px; margin-left: 0px;;">
            <b-col cols="12">
              <!-- 
                <img src="@/icon/2324511.png" @click="f_talkWithAiRobots()" style="width: 4em; cursor: pointer;" id="talk_with_airobots" />
                <b-popover :target="'talk_with_airobots'" triggers="hover focus">
                  <template slot="title">wisdom stats</template>
                  Yapay Zeka Robotlarına bu verileri öğretir ve konuşmanızı sağlar.
                </b-popover>
               -->
              <div style="float: left;">
                <img src="@/icon/1430869.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                Paket
                <br>
                <!-- <div style="float: left; min-width: 50px;">
                  <b-button variant="white" size="sm" title="Proje Karar Destek Paketi Listesi">
                    <img src="@/icon/2504347.png" style="width: 2em;" />
                  </b-button>
                </div> -->
                <div style="float: left; min-width: 50px;">
                  <b-button :title="$t('wdm16.11140')" variant="white" size="sm" @click="f_addNewChildWisdomData('wdm24')" title="Yeni karar destek paketi ekle">
                    <img src="@/icon/2766751.png" style="width: 2em;" />
                  </b-button>
                </div>
              </div>
              <!-- <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                Test
                <br>
                <b-dropdown id="wai_dmp_list" variant="white">
                  <template slot="button-content">
                    <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                  </template>
                  <template v-if="p_selectedProjectData.data && p_selectedProjectData.data.dmp && p_selectedProjectData.data.dmp.dmp_list && p_selectedProjectData.data.dmp.dmp_list.list && p_selectedProjectData.data.dmp.dmp_list.list.length > 0">
                    <template v-for="(wdmr24, wdmr24_ind) in p_selectedProjectData.data.dmp.dmp_list.list">
                      <b-dropdown-item @click="p_f_analyzeThisPaginationRowsByThisDecisionMakingPackage(wdmr24.dmp_id.val)">
                        <img src="@/icon/934385.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Bu sayfadaki tüm satırları <small style="color: red;">"{{ wdmr24.dmp_name.val }} [ {{ wdmr24.dmp_id.val }} ]"</small> karar destek paketi ile analiz et
                      </b-dropdown-item>
                    </template>
                  </template>
                  <template v-else>
                    <b-dropdown-item> Ekli karar destek paketi bulunmamaktadır. </b-dropdown-item>
                  </template>
                </b-dropdown>
              </div> -->
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                Alg. Analiz
                <br>
                <b-dropdown id="wai_dmp_list" variant="white">
                  <template slot="button-content">
                    <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                  </template>
                  <template v-if="column_list.length > 0">
                    <template v-for="(col, col_ind) in column_list">
                      <b-dropdown-item v-if="col.rule_id !== undefined" @click="p_f_analyzeAlgorithmAndSendResultWithApi(col_ind, 'selected', '3')">
                        <img src="@/icon/934385.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Tüm proje verilerini <small style="color: red;">"{{ col.label }} [ {{ col.wdmr24_id  }} - {{ col.rule_id  }} ]"</small> algoritması ile analiz eder. Api işlemlerini gerçekleştirmez.
                      </b-dropdown-item>
                    </template>
                  </template>
                </b-dropdown>
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                Alg. analiz + api
                <br>
                <b-dropdown id="wai_dmp_list" variant="white">
                  <template slot="button-content">
                    <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                  </template>
                  <template v-if="column_list.length > 0">
                    <template v-for="(col, col_ind) in column_list">
                      <b-dropdown-item v-if="col.rule_id !== undefined" @click="p_f_analyzeAlgorithmAndSendResultWithApi(col_ind, 'selected', '1')">
                        <img src="@/icon/934385.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Tüm proje verilerini <small style="color: red;">"{{ col.label }} [ {{ col.wdmr24_id  }} - {{ col.rule_id  }} ]"</small> algoritması ile analiz ederek api işlemlerini gerçekleştir.
                      </b-dropdown-item>
                    </template>
                    <b-dropdown-item @click="p_f_analyzeAlgorithmAndSendResultWithApi('', 'all', '1')">
                      <img src="@/icon/136161.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Tüm proje verilerini tüm algoritmalarla analiz ederek api işlemlerini gerçekleştir.
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
              </div>
            </b-col>
          </b-row>
        </template>
        <template v-if="d_selectedMenu ==='article'">
          <b-row key="up_side_article" style="height: 75px; margin-left: 0px;;">
            <b-col cols="12">
              <!-- 
                <img src="@/icon/2324511.png" @click="f_talkWithAiRobots()" style="width: 4em; cursor: pointer;" id="talk_with_airobots" />
                <b-popover :target="'talk_with_airobots'" triggers="hover focus">
                  <template slot="title">wisdom stats</template>
                  Yapay Zeka Robotlarına bu verileri öğretir ve konuşmanızı sağlar.
                </b-popover>
               -->
              <div style="float: left;">
                <img src="@/icon/70094.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                Makale
                <br>
                <!-- <div style="float: left; min-width: 50px;">
                  <b-button variant="white" size="sm" title="Proje Karar Destek Paketi Listesi">
                    <img src="@/icon/2504347.png" style="width: 2em;" />
                  </b-button>
                </div> -->
                <div style="float: left; min-width: 50px;">
                  <b-button :title="$t('wdm16.11140')" variant="white" size="sm" @click="f_prepareWdm73()" title="Yeni makale & rapor ekle">
                    <img src="@/icon/2766751.png" style="width: 2em;" />
                  </b-button>
                </div>
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                Yazdır PDF
                <br>
                <b-dropdown id="wai_dmp_list" variant="white">
                  <template slot="button-content">
                    <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                  </template>
                  <template v-if="p_selectedProjectData.data && p_selectedProjectData.data.articles && p_selectedProjectData.data.articles.article && p_selectedProjectData.data.articles.article.list && p_selectedProjectData.data.articles.article.list.length > 0">
                    <template v-for="(article, article_ind) in p_selectedProjectData.data.articles.article.list">
                      <b-dropdown-item @click="f_printArticlePdf(article.wdm73_key.val)">
                        <img src="@/icon/934385.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> {{ article.wdm73_name.val }}
                      </b-dropdown-item>
                    </template>
                  </template>
                  <template v-else>
                    <b-dropdown-item> Ekli makale bulunmamaktadır. </b-dropdown-item>
                  </template>
                </b-dropdown>
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                Makale yazısı
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="article" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/1834158.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <!--                 <b-dropdown-item @click="f_prepareDatasetRuleAlgoritmForArticle()">
                  <img src="@/icon/2063326.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Veri seti tüm hazırlama bilgilerini projeye aktar
                </b-dropdown-item>
 -->
                    <b-dropdown-item @click="f_createTextOfWisdomEraPolestarStatisticsTool()">
                      <img src="@/icon/2063326.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> İstatistik kullanım paketi bilgisi makale yazısı
                    </b-dropdown-item>
                    <b-dropdown-item v-if="p_selectedProjectData.rule && p_selectedProjectData.rule.main_rule && p_selectedProjectData.rule.main_rule.query_list.length > 0" @click="f_createTextOfRuleAlgoritm(p_selectedProjectData.rule.main_rule)">
                      <img src="@/icon/2063326.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Veri seti hazırlama algoritmasını makaleye aktar
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </b-col>
          </b-row>
        </template>
        <template v-if="d_selectedMenu ==='sms_menu'">
          <b-row key="up_side_decisionmakingpackage" style="height: 75px; margin-left: 0px;;">
            <b-col cols="12">
              <!-- 
                <img src="@/icon/2324511.png" @click="f_talkWithAiRobots()" style="width: 4em; cursor: pointer;" id="talk_with_airobots" />
                <b-popover :target="'talk_with_airobots'" triggers="hover focus">
                  <template slot="title">wisdom stats</template>
                  Yapay Zeka Robotlarına bu verileri öğretir ve konuşmanızı sağlar.
                </b-popover>
               -->
              <div style="float: left;">
                <img src="@/icon/1013986.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                View
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="dmp_sms_operations_show_table_selected_column" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/1925502.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <template v-if="column_list.length > 0">
                      <template v-for="(col, col_ind) in column_list">
                        <b-dropdown-item v-if="col.dmp_sms_operation" @click="p_f_analyzeAllDatasetByDmpSmsOperation(col_ind, 'first_show_table', 'selected')">
                          <img src="@/icon/934385.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                          <small style="color: red;"> "{{ col.label }}" </small> sms kolonu için tüm satırları analiz et ve ekranda tablo halinde göster
                        </b-dropdown-item>
                      </template>
                    </template>
                  </b-dropdown>
                  <br>
                </div>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="dmp_sms_operations_show_table_all_columns" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/1925502.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item v-if="column_list.length > 0" @click="p_f_analyzeAllDatasetByDmpSmsOperation('', 'first_show_table', 'all')">
                      <img src="@/icon/934385.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> tüm sms kolonları için tüm satırları analiz et ve ekranda tablo halinde göster
                    </b-dropdown-item>
                  </b-dropdown>
                  <br>
                </div>
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                Send
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="dmp_sms_operations_perform_sms_operation_selected" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/2566224.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <template v-if="column_list.length > 0">
                      <template v-for="(col, col_ind) in column_list">
                        <b-dropdown-item v-if="col.dmp_sms_operation" @click="p_f_analyzeAllDatasetByDmpSmsOperation(col_ind, 'perform_sms_operation_when_rule_returns_true', 'selected')">
                          <img src="@/icon/934385.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                          <small style="color: red;"> "{{ col.label }}" </small> sms kolonu için tüm satırları analiz et ve direkt ilgili sms operasyonunu gerçekleştir
                        </b-dropdown-item>
                      </template>
                    </template>
                  </b-dropdown>
                  <br>
                </div>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="dmp_sms_operations_perform_sms_operation_selected" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/2566224.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item v-if="column_list.length > 0" @click="p_f_analyzeAllDatasetByDmpSmsOperation('', 'perform_sms_operation_when_rule_returns_true', 'all')">
                      <img src="@/icon/934385.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> tüm sms kolonları için tüm satırları analiz et ve direkt ilgili sms operasyonunu gerçekleştir
                    </b-dropdown-item>
                  </b-dropdown>
                  <br>
                </div>
              </div>
            </b-col>
          </b-row>
        </template>
        <template v-if="d_selectedMenu ==='mail_menu'">
          <b-row key="up_side_mail_menu" style="height: 75px; margin-left: 0px;;">
            <b-col cols="12">
              <!-- 
                <img src="@/icon/2324511.png" @click="f_talkWithAiRobots()" style="width: 4em; cursor: pointer;" id="talk_with_airobots" />
                <b-popover :target="'talk_with_airobots'" triggers="hover focus">
                  <template slot="title">wisdom stats</template>
                  Yapay Zeka Robotlarına bu verileri öğretir ve konuşmanızı sağlar.
                </b-popover>
               -->
              <div style="float: left;">
                <img src="@/icon/1013986.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                View
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="dmp_sms_operations_show_table_selected_column" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/1925502.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <template v-if="column_list.length > 0">
                      <template v-for="(col, col_ind) in column_list">
                        <b-dropdown-item v-if="col.dmp_sms_operation" @click="p_f_analyzeAllDatasetByDmpSmsOperation(col_ind, 'first_show_table', 'selected')">
                          <img src="@/icon/934385.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                          <small style="color: red;"> "{{ col.label }}" </small> sms kolonu için tüm satırları analiz et ve ekranda tablo halinde göster
                        </b-dropdown-item>
                      </template>
                    </template>
                  </b-dropdown>
                  <br>
                </div>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="dmp_sms_operations_show_table_all_columns" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/1925502.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item v-if="column_list.length > 0" @click="p_f_analyzeAllDatasetByDmpSmsOperation('', 'first_show_table', 'all')">
                      <img src="@/icon/934385.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> tüm sms kolonları için tüm satırları analiz et ve ekranda tablo halinde göster
                    </b-dropdown-item>
                  </b-dropdown>
                  <br>
                </div>
              </div>
              <div style="float: left; min-width: 75px; height: 100%; padding: 3px;">
                Send
                <br>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="dmp_sms_operations_perform_sms_operation_selected" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/2566224.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <template v-if="column_list.length > 0">
                      <template v-for="(col, col_ind) in column_list">
                        <b-dropdown-item v-if="col.dmp_sms_operation" @click="p_f_analyzeAllDatasetByDmpSmsOperation(col_ind, 'perform_sms_operation_when_rule_returns_true', 'selected')">
                          <img src="@/icon/934385.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                          <small style="color: red;"> "{{ col.label }}" </small> sms kolonu için tüm satırları analiz et ve direkt ilgili sms operasyonunu gerçekleştir
                        </b-dropdown-item>
                      </template>
                    </template>
                  </b-dropdown>
                  <br>
                </div>
                <div style="float: left; min-width: 50px;">
                  <b-dropdown id="dmp_sms_operations_perform_sms_operation_selected" variant="white">
                    <template slot="button-content">
                      <img src="@/icon/2566224.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </template>
                    <b-dropdown-item v-if="column_list.length > 0" @click="p_f_analyzeAllDatasetByDmpSmsOperation('', 'perform_sms_operation_when_rule_returns_true', 'all')">
                      <img src="@/icon/934385.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> tüm sms kolonları için tüm satırları analiz et ve direkt ilgili sms operasyonunu gerçekleştir
                    </b-dropdown-item>
                  </b-dropdown>
                  <br>
                </div>
              </div>
            </b-col>
          </b-row>
        </template>
      </template>
    </b-card>
    <modal v-if="d_showStatisticsModal" @close="d_showStatisticsModal = false" :width="'750'">
      <h3 slot="header">İstatistik Ekranı</h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :data="d_statisticWisdomData" :data_type="'wdm72'" :option_data="option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_saveWdm72()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_showStatisticsModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_projectSmsProvider.show" @close="d_projectSmsProvider.show = false" :width="'1000'">
      <h3 slot="header">Projeye Default Sms Sağlayıcı Ekleme işlemi</h3>
      <div slot="body">
        <user-sms :p_wdmr22="d_wdmr22" :p_selectedSmsProvider="d_projectSmsProvider.selected_sms_provider"></user-sms>
        <b-row style="margin: 5px; min-height: 30px;">
          <b-col sm="12" lg="5">
            <strong>Projeye Ait Aktif Default Sms Sağlayıcı</strong>
          </b-col>
          <b-col sm="12" lg="7">
            {{ p_selectedProjectData.sms_provider && p_selectedProjectData.sms_provider.label ? p_selectedProjectData.sms_provider.label : 'yok' }}
          </b-col>
        </b-row>
        <b-row style="margin: 5px; min-height: 30px;">
          <b-col sm="12" lg="5">
            <strong>Yeni servis sağlayıcı</strong>
          </b-col>
          <b-col sm="12" lg="7">
            {{ d_projectSmsProvider.selected_sms_provider && d_projectSmsProvider.selected_sms_provider.label ? d_projectSmsProvider.selected_sms_provider.label : 'henüz seçim yapılmadı' }}
          </b-col>
        </b-row>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_addThisSmsProviderToProject()" style="margin-right: 5px;">sms sağlayıcıyı projeye tanımla</button>
        <button type="button" class="btn btn-danger" @click="d_projectSmsProvider.show = false" style="margin-right: 5px;">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_projectListData.show" @close="d_projectListData.show = false" :width="'750'">
      <h3 slot="header">Proje Listesi Ekranı</h3>
      <div slot="body">
        <template v-for="(project, project_ind) in d_projectListData.list">
          <b-row :style="d_projectListData.index === project_ind ? 'background-color: #e6fddf; cursor: pointer; padding: 5px; border: 1px solid rgba(158, 193, 179, 0.3);' : 'cursor: pointer; padding: 5px; border: 1px solid rgba(158, 193, 179, 0.3);'">
            <b-col sm="12" md="12" @click="f_selectThisProject(project_ind)" @dblclick="f_goToWisdomStats(project.id)">
              <strong>{{ project_ind + 1 }} -</strong>
              <img src="@/icon/2930471.png" style="width: 1.5em;" /> {{ project.name }},
              <img src="@/icon/9370.png" style="width: 1.5em;" /> {{ project.owner }}
              <img src="@/icon/2080590.png" style="width: 1.5em;" /> {{ DateFormat(project.date) }}
            </b-col>
            <!--
            <b-col sm="12" md="1">
              <b-dropdown variant="link" size="lg" no-caret>
                <template style="" slot="button-content">
                  &#x1f50d;
                </template>
                <b-dropdown-item @click="f_editProject(project_ind)"> Projeyi düzenle </b-dropdown-item>
                <b-dropdown-item @click="go_to_this_project_details(project.id)"> Proje Detaylarına Git </b-dropdown-item>
                <b-dropdown-item @click="f_deletePolestarProject(project.id)"> Projeyi Sil </b-dropdown-item>
              </b-dropdown>
            </b-col>
          -->
          </b-row>
        </template>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_projectListData.show = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_polestarProjectCopy.show" @close="d_polestarProjectCopy.show = false" :width="'1000'">
      <h3 slot="header">Proje Kopyalama</h3>
      <div slot="body">
        <polestar-project-copy :p_selectedProjectData="p_selectedProjectData" :p_polestarSettings="p_polestarSettings" :p_userPolestarPermissions="p_userPolestarPermissions"></polestar-project-copy>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_polestarProjectCopy.show = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_selectExcelData.show" @close="d_selectExcelData.show = false" :width="'500'">
      <h3 slot="header">Excel Dökümanı Ekle</h3>
      <div slot="body">
        <b-row>
          <b-col cols="3">
            Excel Dökümanı
          </b-col>
          <b-col cols="9">
            <input class="pull-right" id="new_excel_document" title="Excel dökümanı yükle" type="file" ref="fileinput" @change="f_selectExcelDocument()" accept=".xls,.xlsx">
          </b-col>
        </b-row>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_selectExcelData.show = false">{{ $t('wdm16.4053') }}</button>
        <button type="button" class="btn btn-success" @click="f_saveExcelFileToPolestar()">{{ $t('wdm16.4061') }}</button>
      </div>
    </modal>
    <modal v-if="d_selectExcelDataForIdList.show" @close="d_selectExcelDataForIdList.show = false" :width="'500'">
      <h3 slot="header">Excel Dökümanı Ekle</h3>
      <div slot="body">
        <b-row>
          <b-col cols="3">
            Excel Dökümanı
          </b-col>
          <b-col cols="9">
            <input class="pull-right" id="new_excel_document_id_list" title="Excel dökümanı yükle" type="file" ref="fileinput" @change="f_selectExcelDocumentForClientId()" accept=".xls,.xlsx">
          </b-col>
        </b-row>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_selectExcelDataForIdList.show = false">{{ $t('wdm16.4053') }}</button>
        <button type="button" class="btn btn-success" @click="f_addIdListByExcelFile()">{{ $t('wdm16.4061') }}</button>
      </div>
    </modal>
    <modal v-if="d_machineLearningData.show" @close="d_machineLearningData.show = false" :width="'1000'">
      <h3 slot="header">Makina Öğrenmesi Ekranı</h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :data="d_machineLearningData.wisdom_data" :data_type="'wdm163'" :option_data="option_data" :rule_engine="false" :query_list="[]"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_saveWdm163()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_machineLearningData.show = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_projectListDataMerge.show" @close="d_projectListDataMerge.show = false" :width="'1500'">
      <h3 slot="header">Proje Listesi Ekranı</h3>
      <div slot="body">
        <b-row style="margin: 0px;">
          <b-col cols="4">
            <b-card no-body>
              <b-card-header class="p-1">
                <strong>Proje listesi</strong>
              </b-card-header>
              <b-form-input v-model="d_projectListDataMerge.search_text" type="text" placeholder="filtrelemek için yazınız"></b-form-input>
              <div style="overflow-x: hidden; overflow-y: auto; height: 250px;">
                <b-row v-for="(project, project_ind) in d_projectListDataMerge.list" :style="d_projectListDataMerge.selected_project_ind === project_ind ? 'background-color: #e6fddf; cursor: pointer; padding: 5px; border: 1px solid rgba(158, 193, 179, 0.3); margin: 0px;' : 'cursor: pointer; padding: 5px; border: 1px solid rgba(158, 193, 179, 0.3); margin: 0px;'" v-if="d_projectListDataMerge.search_text ? project.name.toLocaleLowerCase().indexOf(d_projectListDataMerge.search_text.toLocaleLowerCase()) !== -1 : true">
                  <b-col sm="12" md="12" @click="f_getProjectAndColumnListMerge(project_ind)">
                    <strong>{{ project_ind + 1 }} -</strong>
                    <img src="@/icon/2930471.png" style="width: 1.5em;" /> {{ project.name }},
                    <img src="@/icon/9370.png" style="width: 1.5em;" /> {{ project.owner }}
                    <img src="@/icon/2080590.png" style="width: 1.5em;" /> {{ DateFormat(project.date) }}
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-col>
          <b-col cols="4">
            <b-card no-body>
              <b-card-header class="p-1">
                <strong>Kolon Listesi</strong>
              </b-card-header>
              <div style="overflow-x: hidden; overflow-y: auto; height: 250px;">
                <b-row v-for="(col, col_ind) in d_projectListDataMerge.column_list" :style="d_projectListDataMerge.selected_column_ind === col_ind ? 'background-color: #e6fddf; cursor: pointer; padding: 5px; border: 1px solid rgba(158, 193, 179, 0.3); margin: 0px;' : 'cursor: pointer; padding: 5px; border: 1px solid rgba(158, 193, 179, 0.3); margin: 0px;'">
                  <b-col sm="12" md="10">
                    <img src="@/icon/2930471.png" style="width: 1.5em;" />
                    <strong>{{ col_ind + 1 }} -</strong>
                    <span :style="col.rule_id === undefined ? '' : 'color: blue;'">{{ col.label }}</span>
                  </b-col>
                  <b-col sm="12" md="2">
                    <b-button @click="f_addThisColumn(col_ind)" variant="success">ekle</b-button>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-col>
          <b-col cols="4">
            <b-card no-body>
              <b-card-header class="p-1">
                <strong>Yeni Kolonlar</strong>
              </b-card-header>
              <div style="overflow-x: hidden; overflow-y: auto; height: 250px;">
                <b-row>
                  <b-col sm="12" md="10">
                    <b-form-input v-model="d_projectListDataMerge.new_target_name" type="text" placeholder="yeni kolon adı"></b-form-input>
                  </b-col>
                  <b-col sm="12" md="2">
                    <b-button @click="f_addTargetName()" variant="success">ekle</b-button>
                  </b-col>
                </b-row>
                <b-row v-for="target_name in d_projectListDataMerge.target_column_name_list">
                  <b-col sm="12" md="10">
                    {{ target_name }}
                  </b-col>
                  <b-col sm="12" md="2">
                    <b-button @click="f_deleteTargetName(target_name)" variant="danger">sil</b-button>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-row style="margin: 0px;">
          <b-col cols="12">
            <b-card no-body>
              <b-card-header class="p-1">
                <strong>Proje listesi</strong>
              </b-card-header>
              <div style="overflow-x: hidden; overflow-y: auto; height: 250px;">
                <b-row v-for="(project, project_ind) in d_projectListDataMerge.selected_project_list">
                  <b-col sm="12" md="12">
                    <b-card-header class="p-1" header-bg-variant="success" header-text-variant="dark">
                      <strong>{{ project_ind + 1 }} - {{ project.merge_project_name }} </strong>
                    </b-card-header>
                    <b-card no-body style="margin: 10px;">
                      <b-card-header class="p-1">
                        <strong>Kolon listesi</strong>
                      </b-card-header>
                      <b-row v-for="(column, column_ind) in project.column_list" style="margin: 0px;">
                        <b-col sm="12" md="4">
                          <strong>{{ column_ind + 1 }} - {{ column.merge_column_name }} </strong>
                        </b-col>
                        <b-col sm="12" md="4">
                          <b-form-select :plain="true" v-model="column.target_name">
                            <option v-for="(target_name, target_name_index) in d_projectListDataMerge.target_column_name_list" :value="target_name">{{ target_name }}</option>
                          </b-form-select>
                        </b-col>
                        <b-col sm="12" md="4">
                          <b-button @click="f_deleteTargetColumn(project_ind, column_ind)" variant="danger">sil</b-button>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <div slot="footer">
        <button type="button" style="margin-right: 5px;" class="btn btn-danger" @click="d_projectListDataMerge.show = false">{{ $t('wdm16.4053') }}</button>
        <button type="button" style="margin-right: 5px;" class="btn btn-success" @click="f_addProjectMergeColumns()">güncelle</button>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  default as PolestarProjectCopy
} from "@/components/widgets/PolestarProjectCopy";
import { ClsPolestar } from "@/services/public/polestar";
import {
  default as UserSms
} from "@/components/widgets/UserSms";
import PubmedService from '@/services/pubmed';
import PhraseService from '@/services/phrase';
import SmsService from '@/services/sms';
import RuleEngineService from '@/services/rule_engine';
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import {
  default as Modal
} from "@/components/widgets/Modal";
import FileSaver from 'file-saver';
import { ClsWdmrForever, ClsWdmrSave } from "@/services/public/wdm";
import PoleStarService from '@/services/polestar';
import MachineLearningService from '@/services/machine_learning';
import FiletransferService from '@/services/file_transfer';
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'WisdomStatsMenu',
  computed: mapGetters({
    lang: 'lang',
    device: 'device'
  }),
  props: {
    p_polestarSettings: {
      type: Object,
      required: true
    },
    f_printArticlePdf: {
      type: Function,
      required: true
    },
    f_controlAndCreateTimer: {
      type: Function,
      required: true
    },
    f_savePoleStarProject: {
      type: Function,
      required: true
    },
    f_prepareWdm73: {
      type: Function,
      required: true
    },
    f_addNewChildWisdomData: {
      type: Function,
      required: true
    },
    f_editMainRuleAlgorithm: {
      type: Function,
      required: true
    },
    f_getSqlOfMainRule: {
      type: Function,
      required: true
    },
    f_createNewMachineLearning: {
      type: Function,
      required: true
    },
    f_prepareProjectCases: {
      type: Function,
      required: true
    },
    f_cancelProjectUpdate: {
      type: Function,
      required: true
    },
    f_getCases: {
      type: Function,
      required: true
    },
    f_editProject: {
      type: Function,
      required: true
    },
    f_deleteProjectDatasetList: {
      type: Function,
      required: true
    },
    p_f_fastAnalyzeMode: {
      type: Function,
      required: true
    },
    p_f_showArticleList: {
      type: Function,
      required: true
    },
    p_f_analyzeThisPaginationRowsByThisDecisionMakingPackage: {
      type: Function,
      required: true
    },
    f_mergeProjects: {
      type: Function,
      required: true
    },
    p_f_analyzeAlgorithmAndSendResultWithApi: {
      type: Function,
      required: true
    },
    p_f_analyzeAllDatasetByDmpSmsOperation: {
      type: Function,
      required: true
    },
    f_goToThisProject: {
      type: Function,
      required: true
    },
    f_addNewPhraseModal: {
      type: Function,
      required: true
    },
    d_dataset: {
      type: Object,
      required: true
    },
    p_selectedProjectData: {
      type: Object,
      required: true
    },
    option_data: {
      type: Object,
      required: true
    },
    mother_children: {
      type: Object,
      required: true
    },
    d_eligibleForWmdrRuleSearch: {
      type: Boolean,
      required: true
    },
    d_eligibleForColumnOptionRuleMatch: {
      type: Boolean,
      required: true
    },
    d_eligibleForWdmrRuleMatch: {
      type: Boolean,
      required: true
    },
    d_eligibleForColumnOptionMixedWdmrRuleMatch: {
      type: Boolean,
      required: true
    },
    column_list: {
      type: Array,
      required: true
    },
    p_userPolestarPermissions: {
      type: Array,
      required: true
    },
    d_selected_cells: {
      type: Array,
      required: true
    }
  },
  components: {
    Modal,
    WisdomDataModal,
    UserSms,
    PolestarProjectCopy
  },
  data () {
    return {
      d_projectListDataMerge: {
        'selected_project_list': [],
        'target_column_name_list': [],
        'list': [],
        'search_text': '',
        'show': false,
        'project_data': '',
        'column_list': [],
        'selected_project_ind': '',
        'selected_column_ind': ''
      },
      d_machineLearningData: { 'show': false, 'wisdom_data': {} },
      w_presentation: false,
      d_selectExcelData: { 'file': '', 'show': false },
      d_selectExcelDataForIdList: { 'file': '', 'show': false },
      d_polestarProjectCopy: { 'show': false },
      d_wdmr22: {},
      d_projectSmsProvider: {
        'show': false,
        'selected_sms_provider': { 'label': '', 'key': '' }
      },
      d_projectListData: { 'list': [], 'show': false },
      d_showStatisticsModal: false,
      d_selectedAnalyzeModel: { 'analyze_model': '', 'type': '', 'row_parameter_group_type': '1' },
      d_cell_selection_mode: 'one_cell_select_mode',
      d_upperPartShowMode: true,
      d_selectedMenu: 'file',
      analyze_column_list: [],
      column_parameter_list: [],
      row_parameter_list: [],
      d_selectedAnatomySchemaList: [],
      user: {},
      d_menuList: [{
        'label': 'Dosya',
        'translation': {},
        'value': 'file',
        'active': 1
      }, {
        'label': 'Genel',
        'translation': {},
        'value': 'general',
        'active': 1
      }, {
        'label': 'Veriler',
        'translation': {},
        'value': 'data',
        'active': 1
      }, {
        'label': 'Görünüm',
        'translation': {},
        'value': 'view',
        'active': 1
      }, {
        'label': 'İstatistik',
        'translation': {},
        'value': 'statistics',
        'active': 1
      }, {
        'label': 'Makina öğr',
        'translation': {},
        'value': 'machine_learning',
        'active': 1
      }, {
        'label': 'Karar Destek',
        'translation': {},
        'value': 'decisionmakingpackage',
        'active': 1
      }, {
        'label': 'Makale & Rapor',
        'translation': {},
        'value': 'article',
        'active': 1
      }, {
        'label': 'Sms',
        'translation': {},
        'value': 'sms_menu',
        'active': 0
      }, {
        'label': 'Mail',
        'translation': {},
        'value': 'mail_menu',
        'active': 0
      }, {
        'label': 'Ayarlar',
        'translation': {},
        'value': 'settings',
        'active': 0
      }, {
        'label': 'Yardım',
        'translation': {},
        'value': 'help',
        'active': 0
      }],
    };
  },
  created: function () {
    this.w_presentation = JSON.parse(localStorage.getItem('presentation'));
    this.d_wdmr22 = JSON.parse(localStorage.getItem('wdm22'));
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted: function () {},
  methods: {
    f_addProjectMergeColumns: function () {
      this.p_selectedProjectData.data.general.merge_project = { 'list': [] };
      if (this.d_projectListDataMerge.selected_project_list.length > 0) {
        for (let project_ind in this.d_projectListDataMerge.selected_project_list) {
          let new_merge_project = {
            'merge_project_name': { 'val': this.d_projectListDataMerge.selected_project_list[project_ind].merge_project_name },
            'merge_project_id': { 'val': this.d_projectListDataMerge.selected_project_list[project_ind].merge_project_id },
            'merge_column': { 'list': [] }
          }
          for (let col_ind in this.d_projectListDataMerge.selected_project_list[project_ind].column_list) {
            let new_merge_column = {
              'merge_column_name': { 'val': this.d_projectListDataMerge.selected_project_list[project_ind].column_list[col_ind].merge_column_name },
              'merge_column_id': { 'val': this.d_projectListDataMerge.selected_project_list[project_ind].column_list[col_ind].merge_column_id },
              'target_column_name': { 'val': this.d_projectListDataMerge.selected_project_list[project_ind].column_list[col_ind].target_name }
            };
            new_merge_project.merge_column.list.push(new_merge_column);
          }
          this.p_selectedProjectData.data.general.merge_project.list.push(new_merge_project);
        }
      }
      // console.log(this.p_selectedProjectData);
      this.$forceUpdate();
      this.f_savePoleStarProject();
      this.d_projectListDataMerge.show = false;
    },
    f_deleteTargetColumn: function (project_ind, column_ind) {
      this.d_projectListDataMerge.selected_project_list[project_ind].column_list.splice(column_ind, 1);
      if (this.d_projectListDataMerge.selected_project_list[project_ind].column_list.length === 0) {
        this.d_projectListDataMerge.selected_project_list.splice(project_ind, 1);
      }
      this.$forceUpdate();
    },
    f_addThisColumn: function (col_ind) {
      let project_det = false;
      let project_ind = '';
      for (let i in this.d_projectListDataMerge.selected_project_list) {
        if (this.d_projectListDataMerge.selected_project_list[i].merge_project_id === this.d_projectListDataMerge.project_data.id) {
          project_ind = i;
          project_det = true;
        }
      }
      if (!project_det) {
        this.d_projectListDataMerge.selected_project_list.push({ 'merge_project_id': this.d_projectListDataMerge.project_data.id, 'merge_project_name': this.d_projectListDataMerge.project_data.label, 'column_list': [] });
        project_ind = this.d_projectListDataMerge.selected_project_list.length - 1;
      }
      if (!this.d_projectListDataMerge.selected_project_list[project_ind].column_list) {
        this.d_projectListDataMerge.selected_project_list[project_ind].column_list = [];
      }
      let col_det = false;
      for (let c in this.d_projectListDataMerge.selected_project_list[project_ind].column_list) {
        if (this.d_projectListDataMerge.selected_project_list[project_ind].column_list[c].merge_column_id === this.d_projectListDataMerge.column_list[col_ind].id) {
          col_det = true;
          break;
        }
      }
      if (!col_det) {
        this.d_projectListDataMerge.selected_project_list[project_ind].column_list.push({ 'merge_column_name': this.d_projectListDataMerge.column_list[col_ind].label, 'merge_column_id': this.d_projectListDataMerge.column_list[col_ind].id, 'target_name': '' });
      }
      this.$forceUpdate();
    },
    f_addTargetName: function () {
      if (this.d_projectListDataMerge.target_column_name_list.indexOf(this.d_projectListDataMerge.new_target_name) === -1) {
        this.d_projectListDataMerge.target_column_name_list.push(this.d_projectListDataMerge.new_target_name);
        this.$forceUpdate();
      }
    },
    f_deleteTargetName: function (target_name) {
      this.d_projectListDataMerge.target_column_name_list.splice(this.d_projectListDataMerge.target_column_name_list.indexOf(target_name), 1);
      this.$forceUpdate();
    },
    f_getProjectAndColumnListMerge: function (project_index) {
      // this.d_selectedProjectData = '';
      this.d_projectListDataMerge.selected_project_ind = project_index;
      this.d_projectListDataMerge.column_list = [];
      let wdm3_id = this.d_projectListDataMerge.list[project_index].id;
      PoleStarService.get_this_project({ 'wdm3_id': wdm3_id })
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_projectListDataMerge.project_data = resp.data.result;
            this.d_projectListDataMerge.column_list = resp.data.result.design.column_list;
          } else {
            alert('error', resp.data.message);
          }
        });
    },
    f_openProjectlistForColumnSelectionMerge: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Proje listeniz getiriliyor. Lütfen bekleyiniz.' } });
      ClsPolestar.f_getUserPolestarProjects(this.user.username)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          // console.log(resp);
          this.d_projectListDataMerge.list = resp;
          this.d_projectListDataMerge.show = true;
          this.d_projectListDataMerge.selected_project_list = [];
          if (this.p_selectedProjectData.data.general.merge_project) {
            for (let i in this.p_selectedProjectData.data.general.merge_project.list) {
              try {
                let merge_project = {
                  'merge_project_id': this.p_selectedProjectData.data.general.merge_project.list[i].merge_project_id.val,
                  'merge_project_name': this.p_selectedProjectData.data.general.merge_project.list[i].merge_project_name.val,
                  'column_list': []
                };
                for (let col_ind in this.p_selectedProjectData.data.general.merge_project.list[i].merge_column.list) {
                  let merge_column = {
                    'merge_column_id': this.p_selectedProjectData.data.general.merge_project.list[i].merge_column.list[col_ind].merge_column_id.val,
                    'merge_column_name': this.p_selectedProjectData.data.general.merge_project.list[i].merge_column.list[col_ind].merge_column_name.val,
                    'target_name': this.p_selectedProjectData.data.general.merge_project.list[i].merge_column.list[col_ind].target_column_name.val
                  };
                  if (this.d_projectListDataMerge.target_column_name_list.indexOf(this.p_selectedProjectData.data.general.merge_project.list[i].merge_column.list[col_ind].target_column_name.val) === -1) {
                    this.d_projectListDataMerge.target_column_name_list.push(this.p_selectedProjectData.data.general.merge_project.list[i].merge_column.list[col_ind].target_column_name.val);
                  }
                  merge_project.column_list.push(merge_column);
                }
                this.d_projectListDataMerge.selected_project_list.push(merge_project);
              } catch (err) {}
            }
          }
        }, resp => {
          alert('error : ', resp);
        });
    },
    f_pubmedArticleDatasetCreate: function (uri) {
      let query = 'project_id=' + this.p_selectedProjectData.id
      PubmedService.pubmed_article_dataset_create(query)
        .then(resp => {
          let msg = '';
          if (resp.data.status_code === "2000") {
            this.f_getCases();
            msg = 'Dergi analiz işleminiz başlatıldı.';
          } else {
            msg = 'status_code: ' + resp.data.status_code + ', status_message: ' + resp.data.status_message;
          }
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': this.$t('wdm16.13274') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        });
    },
    f_goToDocsWisdomera: function (uri) {
      window.open(uri, '_blank');
    },
    f_saveWdm163: function () {
      let return_data = ClsWdmrForever.turn(this.d_machineLearningData.wisdom_data, this.option_data['wdm163'], ['name', 'delete']);
      let wdmr_name = return_data.name;
      if (wdmr_name) {
        this.d_machineLearningData.wisdom_data.label = wdmr_name;
      }
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      if (!only_one_param_is_filled) {
        let modal_data = {
          'type': 'alert',
          'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.',
          'centered': true,
          'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.'
        };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
        let require_txt = '';
        for (let r in wdmr_require_alerts) {
          require_txt += ' *' + wdmr_require_alerts[r].label;
        }
        let modal_data = {
          'type': 'alert',
          'text': require_txt,
          'centered': true,
          'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.'
        };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      let data = {};
      data = { 'data': this.d_machineLearningData.wisdom_data };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kayıt yapılıyor. Lütfen bekleyiniz.' } });
      MachineLearningService.save(data).then(resp => {
        if (resp.data.status === "success") {
          this.f_motherChildren('update_project_data', resp.data.result.project_data);
          this.d_machineLearningData.show = false;
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        } else {
          let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        }
        this.$forceUpdate();
      });
    },
    f_prepareWdm163: function (type) {
      let pre_data = {
        'id': 'new',
        'data': {},
        'type': 'wdm163',
        'reference_wdm': {},
        'wdm3_id': this.p_selectedProjectData.id
      };
      pre_data['reference_wdm']['version'] = this.option_data['wdm163']['version'];
      pre_data['reference_wdm']['owner_type'] = this.option_data['wdm163']['owner_type'];
      if (this.option_data['wdm163']['owner_type'] === 'wisdom') {
        pre_data['reference_wdm']['key'] = 'wdm_wisdom_wdm163_v' + this.option_data['wdm163'].version;
      }
      for (let i in this.option_data['wdm163'].param_group.param_order) {
        pre_data['data'][this.option_data['wdm163'].param_group.param_order[i]] = {};
      }
      pre_data['data']['general']['wdm3_name'] = { 'val': this.p_selectedProjectData.data.general.name.val };
      pre_data['data']['general']['wdm3_key'] = { 'val': 'wdm3_' + this.p_selectedProjectData.id };
      this.f_createWdm163ColList();
      this.d_machineLearningData.wisdom_data = pre_data;
      this.d_machineLearningData.show = true;
      this.$store.commit('Loading', { 'status': false, 'data': {} });

      let machine_learning_model = '';
      for (let i in this.option_data.wdm163.general.parameters.machine_learning_model.options) {
        if (this.option_data.wdm163.general.parameters.machine_learning_model.options[i].value === type) {
          machine_learning_model = this.option_data.wdm163.general.parameters.machine_learning_model.options[i];
          break;
        }
      }
      pre_data['data']['general']['wdm3_name'] = { 'val': this.p_selectedProjectData.data.general.name.val };
      pre_data['data']['general']['wdm3_key'] = { 'val': 'wdm3_' + this.p_selectedProjectData.id };
      pre_data['data']['general']['machine_learning_model'] = { 'val': machine_learning_model };
      if (type === 'logistic_regression') {
        pre_data['data']['general']['output_variable_type'] = { 'val': { 'label': 'kategorik', 'value': 'categorical' } };
        pre_data['data']['general']['categorical_parameter_usage_type'] = { 'val': { 'label': 'Otomatik', 'value': 'auto' } };
      } else if (type === 'support_vector_machine') {
        pre_data['data']['general']['output_variable_type'] = { 'val': { 'label': 'kategorik', 'value': 'categorical' } };
        pre_data['data']['general']['categorical_parameter_usage_type'] = { 'val': { 'label': 'Otomatik', 'value': 'auto' } };
      } else if (type === 'linear_regression') {
        pre_data['data']['general']['output_variable_type'] = { 'val': { 'label': 'numerik', 'value': 'numeric' } };
        pre_data['data']['general']['numeric_parameter_usage_type'] = { 'val': { 'label': 'Otomatik', 'value': 'auto' } };
      } else if (type === 'random_forest') {
        pre_data['data']['general']['output_variable_type'] = { 'val': { 'label': 'numerik', 'value': 'numeric' } };
        pre_data['data']['general']['numeric_parameter_usage_type'] = { 'val': { 'label': 'Otomatik', 'value': 'auto' } };
      } else if (type === 'k_nearest_neighbor') {
        pre_data['data']['general']['output_variable_type'] = { 'val': { 'label': 'numerik', 'value': 'numeric' } };
        pre_data['data']['general']['numeric_parameter_usage_type'] = { 'val': { 'label': 'Otomatik', 'value': 'auto' } };
      } else if (type === 'decision_tree') {
        pre_data['data']['general']['output_variable_type'] = { 'val': { 'label': 'numerik', 'value': 'numeric' } };
        pre_data['data']['general']['numeric_parameter_usage_type'] = { 'val': { 'label': 'Otomatik', 'value': 'auto' } };
      }
    },
    f_createWdm163ColList: function () {
      let col_list = [];
      for (let i in this.column_list) {
        let lbl = this.column_list[i].label + '( no:' + i + ' ) -' + this.column_list[i].param_type + ' -';
        // We took the column index in value. So when changing we can use value field.
        let x = {
          'label': lbl,
          'value': i.toString(),
          'param_type': this.column_list[i].param_type
        }
        col_list.push(x);
      }
      this.option_data['wdm163'].general.parameters.input_variables.options = col_list;
      this.option_data['wdm163'].general.parameters.numeric_output_variable.options = col_list;
      this.option_data['wdm163'].general.parameters.categorical_output_variable.options = col_list;
    },
    f_addMachineLearningModel: function (type = '') {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Makina öğrenmesi modeliniz hazırlanıyor. Lütfen bekleyiniz.' } });
      setTimeout(function () {
        this.f_prepareWdm163(type);
      }.bind(this), 500)
    },
    f_openProjectMailProviderModal: function () {
      return;
    },
    f_addDataFromCsv: function () {
      return;
    },
    f_saveProjectStyleForMe: function () {
      if (!this.p_selectedProjectData.settings) {
        this.p_selectedProjectData.settings = {};
      }
      this.p_selectedProjectData.settings[this.user.username] = this.p_polestarSettings;
      this.f_savePoleStarProject();
    },
    f_changeStyle: function () {
      return;
      setTimeout(function () {
        this.$forceUpdate();
      }.bind(this), 300)
    },
    f_saveExcelFileToPolestar: function () {
      if (this.d_selectExcelData.file) {
        let data = {
          'file': this.d_selectExcelData.file,
          'wdm3_id': this.p_selectedProjectData.id
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Excel dökümanı analizi başlatıldı. Lütfen bekleyiniz.' } });
        PoleStarService.save_excel_file_to_polestar(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status === 'success') {
              if (!this.p_selectedProjectData.design) {
                this.p_selectedProjectData.design = { 'column_list': [] };
              }
              if (!this.p_selectedProjectData.design) {
                this.p_selectedProjectData.design = { 'column_list': [] };
              }
              this.p_selectedProjectData.design.column_list = resp.data.result.column_list;
              this.column_list.splice(0, this.column_list.length);
              for (let i in this.p_selectedProjectData.design.column_list) {
                this.column_list.push(this.p_selectedProjectData.design.column_list[i]);
              }
              this.f_getCases();
              this.d_selectExcelData.show = false;
            } else {
              alert('Error :' + resp.data.message);
            }
          });
      } else {
        alert('Lütfen öncelikle excel dökümanı seçimi yapınnız');
      }
    },
    f_addIdListByExcelFile: function () {
      if (this.d_selectExcelDataForIdList.file) {
        let data = {
          'file': this.d_selectExcelDataForIdList.file,
          'wdm3_id': this.p_selectedProjectData.id
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Excel dökümanı analizi başlatıldı. Lütfen bekleyiniz.' } });
        PoleStarService.add_id_list_to_dataset(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status === 'success') {
              this.f_getCases();
              this.d_selectExcelDataForIdList.show = false;
            } else {
              alert('Error :' + resp.data.message);
            }
          });
      } else {
        alert('Lütfen öncelikle excel dökümanı seçimi yapınnız');
      }
    },
    f_selectExcelDocument: function () {
      if (document.getElementById('new_excel_document').value) {
        let arr = document.getElementById('new_excel_document').value.split('.');
        let fileExtension = arr[arr.length - 1];
        if (['xlsx', 'xls'].indexOf(fileExtension.toLowerCase()) === -1) {
          document.getElementById('new_excel_document').value = '';
          alert('Lütfen xlsx, xls formatını kullanınız');
          return;
        }
        var files = document.getElementById('new_excel_document').files;
        if (!files.length) {
          return;
        }
        var reader = new FileReader();
        let vm = this;
        reader.onload = (e) => {
          vm.d_selectExcelData.file = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.$forceUpdate();
      }
    },
    f_selectExcelDocumentForClientId: function () {
      if (document.getElementById('new_excel_document_id_list').value) {
        let arr = document.getElementById('new_excel_document_id_list').value.split('.');
        let fileExtension = arr[arr.length - 1];
        if (['xlsx', 'xls'].indexOf(fileExtension.toLowerCase()) === -1) {
          document.getElementById('new_excel_document_id_list').value = '';
          alert('Lütfen xlsx, xls formatını kullanınız');
          return;
        }
        var files = document.getElementById('new_excel_document_id_list').files;
        if (!files.length) {
          return;
        }
        var reader = new FileReader();
        let vm = this;
        reader.onload = (e) => {
          vm.d_selectExcelDataForIdList.file = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.$forceUpdate();
      }
    },
    f_addDataFromExcel: function () {
      this.d_selectExcelData = {
        'show': true,
        'file': ''
      };
    },
    f_openModalForAddClientIdByExcel: function () {
      this.d_selectExcelDataForIdList = {
        'show': true,
        'file': ''
      };
    },
    f_updateSelectedCellsByFollowingRuleHiearchy: function (cell_type = 'only_free') {
      if (this.d_selected_cells.length > 0) {
        let data = {
          'wdm3_id': this.p_selectedProjectData.id,
          'cell_type': cell_type,
          'cells_list': []
        };
        for (let i in this.d_selected_cells) {
          let append_obj = {};
          append_obj.row_ind = parseInt(this.d_selected_cells[i].split('-')[0]);
          append_obj.col_ind = parseInt(this.d_selected_cells[i].split('-')[1]);
          append_obj.dataset_key = this.d_dataset.case_id_list[append_obj.row_ind].dataset_key;
          append_obj.row_no = this.d_dataset.case_id_list[append_obj.row_ind].no;
          data.cells_list.push(append_obj);
        }
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Seçeneklere ait kural algoritmaları ile çalışma yapılıyor. Lütfen bekleyiniz' } });
        PoleStarService.update_selected_project_cells_by_following_rule_hierarchy(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = '';
            if (resp.data.status === 'success') {
              msg = 'Analiz işlemleri tamamlandı';
              this.f_getCases();
              // this.p_selectedProjectData.design.column_list = resp.data.result.column_list;
              // this.column_list.splice(0, this.column_list.length);
            } else {
              msg = resp.data.message;
            }
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Seçeneklere ait kural algoritmaları ile çalışma işlemleri hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          });
      } else {
        let msg = 'Seçili hücre bulunmamaktadır. Lütfen öncelikle hücre seçim işleminizi gerçekleştiriniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Seçili hücrelerin kural algoritma hiyerarşisine göre güncellenmesi işlemleri hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_updateCellsByFollowingRuleHiearchy: function (col_type, col_ind = '', cell_type) {
      let data = {
        'col_type': col_type,
        'col_ind': col_ind,
        'wdm3_id': this.p_selectedProjectData.id,
        'cell_type': cell_type
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Seçeneklere ait kural algoritmaları ile çalışma yapılıyor. Lütfen bekleyiniz' } });
      PoleStarService.update_all_project_cells_by_following_rule_hierarchy(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          let msg = '';
          if (resp.data.status === 'success') {
            msg = 'Analiz işlemleri tamamlandı';
            this.f_getCases();
            // this.p_selectedProjectData.design.column_list = resp.data.result.column_list;
            // this.column_list.splice(0, this.column_list.length);
          } else {
            msg = resp.data.message;
          }
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Seçeneklere ait kural algoritmaları ile çalışma işlemleri hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        });
    },
    f_updateCellsByColumnOptionMixedWdmrRule: function (col_type, col_ind = '', cell_type) {
      if (this.d_eligibleForColumnOptionMixedWdmrRuleMatch) {
        let data = {
          'col_type': col_type,
          'col_ind': col_ind,
          'wdm3_id': this.p_selectedProjectData.id,
          'cell_type': cell_type
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Seçeneklere ait kural algoritmaları ile çalışma yapılıyor. Lütfen bekleyiniz' } });
        PoleStarService.update_cells_by_column_option_mixed_wdmr_rule(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = '';
            if (resp.data.status === 'success') {
              msg = 'Analiz işlemleri tamamlandı';
              this.f_getCases();
              // this.p_selectedProjectData.design.column_list = resp.data.result.column_list;
              // this.column_list.splice(0, this.column_list.length);
            } else {
              msg = resp.data.message;
            }
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Seçeneklere ait kural algoritmaları ile çalışma işlemleri hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          });
      } else {
        let msg = 'Tüm seçenekleri kolon algoritma kuralı ve wdmr algoritma kuralı analizleri beraber sağlandığında otomatik doldur işlemi uygun değil. Lütfen kontrol ediniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Wdmr algoritma kuralı ve kolon algoritma kuralı birlikte hücreleri güncelleme hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_updateCellsByWdmrRule: function (col_type, col_ind = '', cell_type) {
      if (this.d_eligibleForWdmrRuleMatch) {
        let data = {
          'col_type': col_type,
          'col_ind': col_ind,
          'wdm3_id': this.p_selectedProjectData.id,
          'cell_type': cell_type
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Seçeneklere ait kural algoritmaları ile çalışma yapılıyor. Lütfen bekleyiniz' } });
        PoleStarService.update_cells_by_wdmr_rule(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = '';
            if (resp.data.status === 'success') {
              msg = 'İşlem başlatılmıştır. Tamamlandığında bilgi verilecektir.';
              this.p_selectedProjectData['data']['general']['data_wdmr_option_algorithm_status'] = {
                "val": {
                  "label": "devam ediyor",
                  "value": "continuing"
                }
              };
              this.f_controlAndCreateTimer();
              this.$forceUpdate();
            } else {
              msg = resp.data.message;
            }
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Seçeneklere ait kural algoritmaları ile çalışma işlemleri hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          });
      } else {
        let msg = 'Wdmr algoritma kuralı ile hücreleri güncelleme işlemi uygun değil. Lütfen kontrol ediniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Wdmr algoritma kuralı ile hücreleri güncelleme hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_searchTextAnalyzeOnWdmrs: function () {
      if (this.d_eligibleForWmdrRuleSearch) {
        let data = {
          'col_type': 'all',
          'col_ind': '',
          'wdm3_id': this.p_selectedProjectData.id
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İfade analiz işlemleri gerçekleştiriliyor. Lütfen bekleyiniz.' } });
        PoleStarService.search_text_analyze_on_wdmrs(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = '';
            if (resp.data.status === 'success') {
              msg = 'İfade analiz işlemleri başlatıldı. Tamamalandığında bilgi verilecektir.';
              this.p_selectedProjectData['data']['general']['data_mining_status'] = {
                "val": {
                  "label": "devam ediyor",
                  "value": "continuing"
                }
              };
              this.f_controlAndCreateTimer();
              this.$forceUpdate();

              // this.f_motherChildren('get_project_data');
              // this.p_selectedProjectData.design.column_list = resp.data.result.column_list;
              // this.column_list.splice(0, this.column_list.length);
            } else {
              msg = resp.data.message;
            }
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İfade analiz işlemleri hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          });
      } else {
        let msg = 'Verilerde ifade çalışma yapılması işlemi uygun değil. Lütfen kontrol ediniz.';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Verilerde (WDMR) ifade çalışması yapma hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_updateCellsByColumnOptionRule: function (col_type, col_ind = '', cell_type) {
      if (this.d_eligibleForColumnOptionRuleMatch) {
        let data = {
          'col_type': col_type,
          'col_ind': col_ind,
          'wdm3_id': this.p_selectedProjectData.id,
          'cell_type': cell_type
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Seçeneklere ait kural algoritmaları ile çalışma yapılıyor. Lütfen bekleyiniz' } });
        PoleStarService.update_cells_by_column_option_rule(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = '';
            if (resp.data.status === 'success') {
              msg = 'Kolonlardaki verilerden algoritmalarla hücre doldurma işlemi başlamıştır. Tamamalandığında bilgi verilecektir.';
              this.p_selectedProjectData['data']['general']['data_option_algorithm_status'] = {
                "val": {
                  "label": "devam ediyor",
                  "value": "continuing"
                }
              };
              this.f_controlAndCreateTimer();
              this.$forceUpdate();
            } else {
              msg = resp.data.message;
            }
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Seçeneklere ait kural algoritmaları ile çalışma işlemleri hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          });
      } else {
        let msg = 'Seçenekleri hücrelere kural algoritması ile yerleştirme işlemi için seçenek kural algoritmalarınızı lütfen kontrol ediniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Seçeneklere yazılan kural algoritmaları işlemi' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_updateCellsByAllManuelColumns: function () {
      let data = {
        'wdm3_id': this.p_selectedProjectData.id
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Seçeneklere ait kural algoritmaları ile çalışma yapılıyor. Lütfen bekleyiniz' } });
      PoleStarService.update_cells_for_all_manuel_columns(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          let msg = '';
          if (resp.data.status === 'success') {
            msg = 'İşlem başlatılmıştır. Tamamlandığında bilgi verilecektir.';
            this.p_selectedProjectData['data']['general']['data_wdmr_option_algorithm_status'] = {
              "val": {
                "label": "devam ediyor",
                "value": "continuing"
              }
            };
            this.f_controlAndCreateTimer();
            this.$forceUpdate();
          } else {
            msg = resp.data.message;
          }
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Tüm manuel kolon verileri güncelleme çalışması hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        });
    },
    f_saveDataset: function (type = '') {
      if (this.p_userPolestarPermissions.indexOf('owner') !== -1 || this.p_userPolestarPermissions.indexOf('admin') !== -1 || this.p_userPolestarPermissions.indexOf('can_edit_manually_project_cells') !== -1) {
        let dataset = JSON.parse(JSON.stringify(this.d_dataset));
        dataset.selected_cells = this.d_selected_cells;
        let data = {
          'data': dataset
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Veri seti kayıt işlemleri gerçekleştiriliyor. Lütfen bekleyiniz.' } });
        PoleStarService.save_data_set(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = '';
            if (resp.data.status === 'success') {
              msg = 'Kayıt işlemi gerçekleştirildi';
            } else {
              msg = resp.data.message;
            }
            /*
              if (type === 'get_cases') {
                this.f_getCases();
              }
            */
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Veri Seti Kayıt işlemi' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          });
      } else {
        let msg = 'Proje datasetini kaydetme yetkiniz bulunmamaktadır. Lütfen proje yöneticisiyle temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Dataset kayıt işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_updateWdmrCells: function (row_ind = '', type, row_type = 'this') {
      // type => delete_manuel_edit or not_delete_manuel_edit
      // console.log(row_ind, type);
      if (this.p_selectedProjectData && this.p_selectedProjectData.data.general && this.p_selectedProjectData.data.general.layer && this.p_selectedProjectData.data.general.layer.val && this.p_selectedProjectData.data.general.layer.val.value) {
        if (this.column_list.length > 0) {
          let det_wdmr_col = false;
          for (let i in this.column_list) {
            if (this.column_list[i].type === 'wdm') {
              det_wdmr_col = true;
              break;
            }
          }
          if (det_wdmr_col) {
            let data = {};
            if (row_ind !== '' && row_type === 'this') {
              data = this.d_dataset.case_id_list[row_ind];
            }
            data.type = type;
            data.row_type = row_type;
            data.wdm3_id = this.p_selectedProjectData.id;
            this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Dataset Güncelleniyor. Lütfen bekleyiniz.' } });
            PoleStarService.update_wdmr_cells(data)
              .then(resp => {
                this.$store.commit('Loading', { 'status': false, 'data': {} });
                if (resp.data.status === 'success') {
                  this.f_getCases();
                  if (row_type === 'this') {
                    // this.d_dataset.case_rows[row_ind] = resp.data.result.case_rows;
                    // this.d_dataset.cell_dates[row_ind] = resp.data.result.cell_dates;
                  }
                  this.$forceUpdate();
                } else {
                  alert('Error :' + resp.data.message);
                }
              });
          } else {
            let msg = 'Lütfen wdmr kolonları ekleyiniz.';
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Veri güncelleme işlemi hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          }
        } else {
          let msg = 'Lütfen wdmr kolonları ekleyiniz.';
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Veri güncelleme işlemi hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        }
      } else {
        let msg = 'Katman tanımlı değil. Lütfen öncelikle katman tanımlayıp wdmr kolonları ekleyiniz.';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Veri güncelleme işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_publishAsWaim: function () {
      if (this.user && this.user.permissions_result && this.user.permissions_result.indexOf('polestar_wisdomstats_publish-polestar-project-as-wai') !== -1) {
        if (this.p_userPolestarPermissions.indexOf('owner') !== -1 || this.p_userPolestarPermissions.indexOf('admin') !== -1 || this.p_userPolestarPermissions.indexOf('can_publish_as_wai_model') !== -1) {
          let data = {
            'wdm3_id': this.p_selectedProjectData.id
          };
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Proje yapay zeka modeli olarak yayınlama işlemi başlatıldı. Lütfen bekleyiniz.' } });
          PoleStarService.publish_as_waim(data)
            .then(resp => {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              if (resp.data.status === 'success') {
                let msg = 'Yayınlama işleminiz başarıyla gerçekleştirildi';
                let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Yapay zeka modeli yayınlama işlemi hakkında' };
                this.$store.commit('modal', modal_data);
                this.$store.commit('modal_show', true);
              } else {
                alert('Error :' + resp.data.message)
              }
            });
        } else {
          let msg = 'Waide yapay zeka modeli olarak yayınlamak için proje yetkilisinden yayınlama yetkisi almanız gerekmektedir.';
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Wai yapay zeka modeli yayınlama hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        }
      } else {
        let msg = 'Waide yapay zeka modeli olarak yayınlamak için yayınlama yetkisi almanız gerekmektedir.';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Wai yapay zeka modeli yayınlama hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_copyPolestarProject: function () {
      if (this.user && this.user.permissions_result && this.user.permissions_result.indexOf('polestar_wisdomstats_copy-polestar-project') !== -1) {
        if (this.p_userPolestarPermissions.indexOf('owner') !== -1 || this.p_userPolestarPermissions.indexOf('admin') !== -1 || this.p_userPolestarPermissions.indexOf('project_copy_control') !== -1) {
          this.d_polestarProjectCopy.show = true;
        } else {
          let msg = 'Bu projeye ait kopyalama yetkiniz bulunmamaktadır. Lütfen proje yetkilisi ile temasa geçiniz';
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Proje kopyalama işlemi hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        }
      } else {
        let msg = 'Proje Kopyalama yetkiniz bulunmamaktadır. Lütfen sistem yetkilisi ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Proje kopyalama işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_goToProjectList: function () {
      this.$router.push({ path: '/polestar/projects' });
    },
    f_goToWisdomStats: function (project_id) {
      this.$router.push({ path: '/polestar/wisdomstats', query: { project_id: project_id } });
      this.f_goToThisProject(project_id);
      this.d_projectListData.show = false;
    },
    DateFormat: function (date) {
      return moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
    },
    f_selectThisProject: function (project_ind) {
      this.d_projectListData.index = project_ind;
      this.$forceUpdate();
    },
    f_getUserPolestarProjects: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Proje listeniz getiriliyor. Lütfen bekleyiniz.' } });
      ClsPolestar.f_getUserPolestarProjects(this.user.username).then(resp => {
        this.$store.commit('Loading', { 'status': false, 'data': {} });
        // console.log(resp);
        this.d_projectListData.list = resp;
        this.d_projectListData.show = true;
      }, resp => {
        alert('error : ', resp);
      });
    },
    go_to_this_project_details: function () {
      this.$router.push({ path: '/polestar/projects/details', query: { project_id: this.p_selectedProjectData.id } });
    },
    f_openProjectSmsProviderModal: function () {
      this.d_projectSmsProvider.show = true;
    },
    f_addThisSmsProviderToProject: function () {
      if (this.user && this.user.permissions_result && this.user.permissions_result.indexOf('sms_send-sms') !== -1) {
        let data = {
          'wdm3_id': this.p_selectedProjectData.id,
          'sms_provider': this.d_projectSmsProvider.selected_sms_provider
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Projeye sms sağlayıcı ekleniyor. Lütfen bekleyiniz.' } });
        SmsService.add_sms_provider_to_project(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = '';
            if (resp.data.status === 'success') {
              this.p_selectedProjectData.sms_provider = JSON.parse(JSON.stringify(this.d_projectSmsProvider.selected_sms_provider));
              this.f_motherChildren('forceUpdate');
              msg = 'Başarıyla tanımlandı.';
            } else {
              msg = resp.data.message;
            }
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Sms gönderme işlemi hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          });
      } else {
        let msg = 'Sms tanımlama işlemi yapabilmeniz için sms gönderim yetkisi almanız gerekmektedir.';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Sms tanımlama işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_talkWithAiRobots: function () {
      return;
      let data = {
        'project_id': this.p_selectedProjectData.id,
        // 'main_column_val_list': this.d_dataset.main_column_val_list,
        'column_list': this.column_list,
        'receiver': 'airobot_drwisdom',
        'message': 'Merhabalar seninle konuşmak istiyorum.'
      };
      MessengerService.prepare_polestar_project_to_talk(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_showMessenger = true;
          } else {
            console.log('error', resp.data.message);
          }
        })
    },
    f_createTextOfRuleAlgoritm: function (rule) {
      let data = {
        'list': [rule],
        'type': 'standart'
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Algoritma yazı üretim işlemi başlatıldı. Lütfen bekleyiniz.' } });
      PoleStarService.create_text_of_rule_algoritm(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            if (resp.data.result.status === 'success') {
              let other_data = {
                'text_list': [{ 'text': resp.data.result.result.text, 'selected': 1 }]
              };
              this.p_f_showArticleList(false, [other_data]);
              // this.f_showArticleList(false, [other_data]);
            }
          } else {
            alert('Error :' + resp.data.message)
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          }
        });
    },
    f_menuStatusControl: function (x, status_type = 'bool') {
      if (!this.d_dataset.menu) {
        this.d_dataset.menu = {};
      }
      if (this.d_dataset.menu.data === undefined) {
        this.d_dataset.menu.data = {};
      }
      if (this.d_dataset.menu.data[x] === undefined) {
        this.d_dataset.menu.data[x] = {};
      }
      if (status_type === 'bool') {
        if (!this.d_dataset.menu.data[x].status) {
          this.d_dataset.menu.data[x].status = 1;
        } else {
          this.d_dataset.menu.data[x].status = 0;
        }
      } else {
        this.d_dataset.menu.data[x].status = status_type;
      }
      if (x === 'anonym_mode') {
        alert('anonimizasyon işlemini gerçekleştirmek için lütfen veri setini kaydediniz.');
      }
      this.f_motherChildren('forceUpdate');
      this.$forceUpdate();
    },
    f_createTextOfWisdomEraPolestarStatisticsTool: function () {
      let vers = JSON.parse(localStorage.getItem('version'));
      let txt = 'We used WisdomEra\'s Wanalyzer Application ' + vers.version + ' for making statistics and preparing the manuscript of this article. As statistics package, Wanalyzer uses python "statsmodels" module.';
      let other_data = { 'text_list': [{ 'text': txt, 'selected': 1 }] };
      this.p_f_showArticleList(false, [other_data]);
    },
    f_saveWdm72: function () {
      let return_data = ClsWdmrForever.turn(this.d_statisticWisdomData, this.option_data['wdm72'], ['name', 'delete']);
      let wdmr_name = return_data.name;
      if (wdmr_name) {
        this.d_statisticWisdomData.label = wdmr_name;
      }
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      if (!only_one_param_is_filled) {
        let modal_data = {
          'type': 'alert',
          'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.',
          'centered': true,
          'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.'
        };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
        let require_txt = '';
        for (let r in wdmr_require_alerts) {
          require_txt += ' *' + wdmr_require_alerts[r].label;
        }
        let modal_data = {
          'type': 'alert',
          'text': require_txt,
          'centered': true,
          'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.'
        };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      let data = {};
      data = { 'data': this.d_statisticWisdomData };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kayıt yapılıyor. Lütfen bekleyiniz.' } });
      PoleStarService.save_statistics(data).then(resp => {
        if (resp.data.status === "success") {
          this.f_motherChildren('update_project_data', resp.data.result.project_data);
          this.d_showStatisticsModal = false;
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        } else {
          let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        }
        this.$forceUpdate();
      });
    },
    f_createWdm72ColList: function () {
      let col_list = [];
      for (let i in this.column_list) {
        let lbl = this.column_list[i].label + '( no:' + i + ' ) -' + this.column_list[i].param_type + ' -';
        // We took the column index in value. So when changing we can use value field.
        let x = { 'label': lbl, 'value': i.toString(), 'param_type': this.column_list[i].param_type }
        col_list.push(x);
      }
      this.option_data['wdm72'].general.parameters.analyze_column_list.options = col_list;
      this.option_data['wdm72'].general.parameters.row_parameter_list.options = col_list;
      this.option_data['wdm72'].general.parameters.column_parameter_list.options = col_list;
      this.option_data['wdm72'].general.parameters.before_after.parameters.first_column.options = col_list;
      this.option_data['wdm72'].general.parameters.before_after.parameters.second_column.options = col_list;
    },
    f_prepareWdm72: function (type, easy_stats_type = '', column_selection_type = '') {
      this.analyze_column_list = [];
      this.column_parameter_list = [];
      this.row_parameter_list = [];
      let pre_data = {
        'id': 'new',
        'data': {},
        'type': 'wdm72',
        'reference_wdm': {},
        'wdm3_id': this.p_selectedProjectData.id
      };
      pre_data['reference_wdm']['version'] = this.option_data['wdm72']['version'];
      pre_data['reference_wdm']['owner_type'] = this.option_data['wdm72']['owner_type'];
      if (this.option_data['wdm72']['owner_type'] === 'wisdom') {
        pre_data['reference_wdm']['key'] = 'wdm_wisdom_wdm72_v' + this.option_data['wdm72'].version;
      }
      for (let i in this.option_data['wdm72'].param_group.param_order) {
        pre_data['data'][this.option_data['wdm72'].param_group.param_order[i]] = {};
      }
      this.d_selectedAnalyzeModel.type = type;
      let analyze_type = '';
      for (let i in this.option_data.wdm72.general.parameters.analyze_type.options) {
        if (this.option_data.wdm72.general.parameters.analyze_type.options[i].value === type) {
          analyze_type = this.option_data.wdm72.general.parameters.analyze_type.options[i];
          break;
        }
      }
      pre_data['data']['general']['wdm3_name'] = { 'val': this.p_selectedProjectData.data.general.name.val };
      pre_data['data']['general']['wdm3_key'] = { 'val': 'wdm3_' + this.p_selectedProjectData.id };
      pre_data['data']['general']['analyze_type'] = { 'val': analyze_type };
      pre_data['data']['general']['analyze_time_type'] = { 'val': { 'value': 'manuel', 'label': 'manuel' } };
      pre_data['data']['general']['auto_combination_cross_analysis'] = { 'val': { 'value': 'no', 'label': 'hayır' } };
      // categorical & categorical
      if (type === 'cross_columns') {
        if (easy_stats_type === 'auto_1') {
          pre_data['data']['general']['model_name'] = { 'val': 'Değişkenlerin karşılaştırlması' };
          pre_data['data']['general']['usage_of_comparison_tests'] = { 'val': { 'value': 'yes', 'label': 'evet' } };
          pre_data['data']['general']['selected_categorical_to_nominal'] = {};
          pre_data['data']['general']['selected_categorical_to_categorical'] = {};
          pre_data['data']['general']['selected_nominal_to_nominal'] = {};
          pre_data['data']['general']['cross_column_type'] = { 'val': { 'value': 'auto', 'label': 'otomatik' } };
          pre_data['data']['general']['column_selection_type'] = { 'val': { 'value': 'manuel_column_selection', 'label': 'manuel kolon seçimi' } };
          pre_data['data']['general']['auto_combination_cross_analysis'] = { 'val': { 'value': 'yes', 'label': 'evet' } };
          pre_data['data']['general']['sample_count_control'] = { 'val': { 'value': 'no', 'label': 'hayır' } };
          pre_data['data']['general']['group_count_control'] = { 'val': { 'value': 'no', 'label': 'hayır' } };
          // numeric to numeric
          pre_data['data']['general']['selected_nominal_to_nominal']['pearson_correlation'] = {
            'name': { 'value': 'pearson_correlation', 'label': 'pearson korelasyon' },
            'detail': {
              'numeric_numeric_control_type_before_test': { 'val': { 'value': 'only_total_samples_data_distribution', 'label': 'sadece tüm örneklere ait veri dağılımı' } },
              'numeric_numeric_distribution_test_list_usage_type': { 'val': { 'value': 'all_of_them', 'label': 'tüm seçili testler normal dağılıyorsa kullan' } },
              'numeric_numeric_distribution_skewness': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'numeric_numeric_distribution_skewness_range': { 'val': '1.5' },
              'numeric_numeric_distribution_kurtosis': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'numeric_numeric_distribution_kurtosis_range': { 'val': '1.5' },
              'numeric_numeric_distribution_shapiro_wilk': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'numeric_numeric_distribution_kolmogorov_smirnov': { 'val': { 'value': 'no', 'label': 'hayır' } },
            }
          };
          pre_data['data']['general']['selected_nominal_to_nominal']['spearman'] = {
            'name': { 'value': 'spearman', 'label': 'spearman' },
            'detail': {
              'numeric_numeric_control_type_before_test': { 'val': { 'value': 'only_total_samples_data_distribution', 'label': 'sadece tüm örneklere ait veri dağılımı' } },
              'numeric_numeric_distribution_test_list_usage_type': { 'val': { 'value': 'one_of_them_not_normally_distributed', 'label': 'testlerden birisi normal dağılmıyorsa kullan' } },
              'numeric_numeric_distribution_skewness': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'numeric_numeric_distribution_skewness_range': { 'val': '1.5' },
              'numeric_numeric_distribution_kurtosis': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'numeric_numeric_distribution_kurtosis_range': { 'val': '1.5' },
              'numeric_numeric_distribution_shapiro_wilk': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'numeric_numeric_distribution_kolmogorov_smirnov': { 'val': { 'value': 'no', 'label': 'hayır' } },
            }
          };
          // categorical to categorical
          pre_data['data']['general']['selected_categorical_to_categorical']['fisherexact'] = { 'name': { 'value': 'fisherexact', 'label': 'Fisher Exact' }, 'detail': {} };
          pre_data['data']['general']['selected_categorical_to_categorical']['chisquare'] = { 'name': { 'value': 'chisquare', 'label': 'Chi square' }, 'detail': {} };
          // categorical to numeric
          pre_data['data']['general']['selected_categorical_to_nominal']['oneway_anova'] = {
            'name': { 'value': 'oneway_anova', 'label': 'Oneway ANOVA' },
            'detail': {
              'categorical_numeric_control_type_before_test': { 'val': { 'value': 'only_every_group_data_distribution', 'label': 'sadece her bir gruba ait veri dağılımı' } },
              'categorical_numeric_distribution_test_list_usage_type': { 'val': { 'value': 'all_of_them', 'label': 'tüm seçili testler normal dağılıyorsa kullan' } },
              'categorical_numeric_distribution_skewness': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'categorical_numeric_distribution_skewness_range': { 'val': '1.5' },
              'categorical_numeric_distribution_kurtosis': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'categorical_numeric_distribution_kurtosis_range': { 'val': '1.5' },
              'categorical_numeric_distribution_shapiro_wilk': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'categorical_numeric_distribution_kolmogorov_smirnov': { 'val': { 'value': 'no', 'label': 'hayır' } },
            }
          };
          pre_data['data']['general']['selected_categorical_to_nominal']['kruskal_wallis'] = {
            'name': { 'value': 'kruskal_wallis', 'label': 'Kruskal Wallis' },
            'detail': {
              'categorical_numeric_control_type_before_test': { 'val': { 'value': 'only_every_group_data_distribution', 'label': 'sadece her bir gruba ait veri dağılımı' } },
              'categorical_numeric_distribution_test_list_usage_type': { 'val': { 'value': 'one_of_them_not_normally_distributed', 'label': 'testlerden birisi normal dağılmıyorsa kullan' } },
              'categorical_numeric_distribution_skewness': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'categorical_numeric_distribution_skewness_range': { 'val': '1.5' },
              'categorical_numeric_distribution_kurtosis': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'categorical_numeric_distribution_kurtosis_range': { 'val': '1.5' },
              'categorical_numeric_distribution_shapiro_wilk': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'categorical_numeric_distribution_kolmogorov_smirnov': { 'val': { 'value': 'no', 'label': 'hayır' } },
            }
          };
          pre_data['data']['general']['selected_categorical_to_nominal']['mann_whitney_u'] = {
            'name': { 'value': 'mann_whitney_u', 'label': 'Mann Whitney U' },
            'detail': {
              'categorical_numeric_control_type_before_test': { 'val': { 'value': 'only_every_group_data_distribution', 'label': 'sadece her bir gruba ait veri dağılımı' } },
              'categorical_numeric_distribution_test_list_usage_type': { 'val': { 'value': 'all_of_them_not_normally_distributed', 'label': 'tüm seçili testler normal dağılmıyorsa kullan' } },
              'categorical_numeric_distribution_skewness': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'categorical_numeric_distribution_skewness_range': { 'val': '1.5' },
              'categorical_numeric_distribution_kurtosis': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'categorical_numeric_distribution_kurtosis_range': { 'val': '1.5' },
              'categorical_numeric_distribution_shapiro_wilk': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'categorical_numeric_distribution_kolmogorov_smirnov': { 'val': { 'value': 'no', 'label': 'hayır' } },
            }
          };
          pre_data['data']['general']['selected_categorical_to_nominal']['t_test'] = {
            'name': { 'value': 't_test', 'label': 't test' },
            'detail': {
              'categorical_numeric_control_type_before_test': { 'val': { 'value': 'only_every_group_data_distribution', 'label': 'sadece her bir gruba ait veri dağılımı' } },
              'categorical_numeric_distribution_test_list_usage_type': { 'val': { 'value': 'all_of_them_not_normally_distributed', 'label': 'tüm seçili testler normal dağılmıyorsa kullan' } },
              'categorical_numeric_distribution_skewness': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'categorical_numeric_distribution_skewness_range': { 'val': '1.5' },
              'categorical_numeric_distribution_kurtosis': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'categorical_numeric_distribution_kurtosis_range': { 'val': '1.5' },
              'categorical_numeric_distribution_shapiro_wilk': { 'val': { 'value': 'yes', 'label': 'evet' } },
              'categorical_numeric_distribution_kolmogorov_smirnov': { 'val': { 'value': 'no', 'label': 'hayır' } },
            }
          };
        }
      }
      if (easy_stats_type === 'categorical_categorical_descriptive') {
        pre_data['data']['general']['cross_column_type'] = { 'val': { 'value': 'categorical_categorical', 'label': 'kategorik & kategorik' } };
        pre_data['data']['general']['usage_of_comparison_tests'] = { 'val': { 'value': 'no', 'label': 'hayır' } };
      } else if (easy_stats_type === 'categorical_categorical_fisherexact') {
        pre_data['data']['general']['cross_column_type'] = { 'val': { 'value': 'categorical_categorical', 'label': 'kategorik & kategorik' } };
        pre_data['data']['general']['usage_of_comparison_tests'] = { 'val': { 'value': 'yes', 'label': 'evet' } };
        pre_data['data']['general']['selected_categorical_to_categorical'] = {};
        pre_data['data']['general']['selected_categorical_to_categorical']['fisherexact'] = { 'name': { 'value': 'fisherexact', 'label': 'Fisher Exact' }, 'detail': {} };
      } else if (easy_stats_type === 'categorical_categorical_chisquare') {
        pre_data['data']['general']['cross_column_type'] = { 'val': { 'value': 'categorical_categorical', 'label': 'kategorik & kategorik' } };
        pre_data['data']['general']['usage_of_comparison_tests'] = { 'val': { 'value': 'yes', 'label': 'evet' } };
        pre_data['data']['general']['selected_categorical_to_categorical'] = {};
        pre_data['data']['general']['selected_categorical_to_categorical']['chisquare'] = { 'name': { 'value': 'chisquare', 'label': 'Chi square' }, 'detail': {} };
      } else if (easy_stats_type === 'categorical_categorical_chisquare_fisherexact') {
        pre_data['data']['general']['cross_column_type'] = { 'val': { 'value': 'categorical_categorical', 'label': 'kategorik & kategorik' } };
        pre_data['data']['general']['usage_of_comparison_tests'] = { 'val': { 'value': 'yes', 'label': 'evet' } };
        pre_data['data']['general']['selected_categorical_to_categorical'] = {};
        pre_data['data']['general']['selected_categorical_to_categorical']['fisherexact'] = { 'name': { 'value': 'fisherexact', 'label': 'Fisher Exact' }, 'detail': {} };
        pre_data['data']['general']['selected_categorical_to_categorical']['chisquare'] = { 'name': { 'value': 'chisquare', 'label': 'Chi square' }, 'detail': {} };
      }
      // categorical & numeric
      if (easy_stats_type === 'categorical_numeric_descriptive') {
        pre_data['data']['general']['model_name'] = { 'val': 'Kategorik verilerle sayısal verilerin karşılaştırılması' };
        pre_data['data']['general']['cross_column_type'] = { 'val': { 'value': 'categorical_numeric', 'label': 'kategorik & sayısal' } };
        pre_data['data']['general']['usage_of_comparison_tests'] = { 'val': { 'value': 'no', 'label': 'hayır' } };
      } else if (easy_stats_type === 'categorical_numeric_t_test') {
        pre_data['data']['general']['model_name'] = { 'val': 'Kategorik verilerle sayısal verilerin karşılaştırılması' };
        pre_data['data']['general']['cross_column_type'] = { 'val': { 'value': 'categorical_numeric', 'label': 'kategorik & sayısal' } };
        pre_data['data']['general']['usage_of_comparison_tests'] = { 'val': { 'value': 'yes', 'label': 'evet' } };
        pre_data['data']['general']['selected_categorical_to_nominal'] = {};
        pre_data['data']['general']['selected_categorical_to_nominal']['t_test'] = { 'name': { 'value': 't_test', 'label': 't test' }, 'detail': {} };
      } else if (easy_stats_type === 'categorical_numeric_kruskal_wallis') {
        pre_data['data']['general']['model_name'] = { 'val': 'Kategorik verilerle sayısal verilerin karşılaştırılması' };
        pre_data['data']['general']['cross_column_type'] = { 'val': { 'value': 'categorical_numeric', 'label': 'kategorik & sayısal' } };
        pre_data['data']['general']['usage_of_comparison_tests'] = { 'val': { 'value': 'yes', 'label': 'evet' } };
        pre_data['data']['general']['selected_categorical_to_nominal'] = {};
        pre_data['data']['general']['selected_categorical_to_nominal']['kruskal_wallis'] = { 'name': { 'value': 'kruskal_wallis', 'label': 'Kruskal Wallis' }, 'detail': {} };
      } else if (easy_stats_type === 'categorical_numeric_mann_whitney_u') {
        pre_data['data']['general']['model_name'] = { 'val': 'Kategorik verilerle sayısal verilerin karşılaştırılması' };
        pre_data['data']['general']['cross_column_type'] = { 'val': { 'value': 'categorical_numeric', 'label': 'kategorik & sayısal' } };
        pre_data['data']['general']['usage_of_comparison_tests'] = { 'val': { 'value': 'yes', 'label': 'evet' } };
        pre_data['data']['general']['selected_categorical_to_nominal'] = {};
        pre_data['data']['general']['selected_categorical_to_nominal']['mann_whitney_u'] = { 'name': { 'value': 'mann_whitney_u', 'label': 'Mann Whitney U test' }, 'detail': {} };
      } else if (easy_stats_type === 'categorical_numeric_oneway_anova') {
        pre_data['data']['general']['model_name'] = { 'val': 'Kategorik verilerle sayısal verilerin karşılaştırılması' };
        pre_data['data']['general']['cross_column_type'] = { 'val': { 'value': 'categorical_numeric', 'label': 'kategorik & sayısal' } };
        pre_data['data']['general']['usage_of_comparison_tests'] = { 'val': { 'value': 'yes', 'label': 'evet' } };
        pre_data['data']['general']['selected_categorical_to_nominal'] = {};
        pre_data['data']['general']['selected_categorical_to_nominal']['oneway_anova'] = { 'name': { 'value': 'oneway_anova', 'label': 'Oneway ANOVA' }, 'detail': {} };
      }
      // numeric & numeric
      if (easy_stats_type === 'numeric_numeric_pearson_corelation') {
        pre_data['data']['general']['model_name'] = { 'val': 'Sayısal verilerle sayısal verilerin karşılaştırılarak korelasyon değerlendirilmesi' };
        pre_data['data']['general']['cross_column_type'] = { 'val': { 'value': 'numeric_numeric', 'label': 'sayısal & sayısal' } };
        pre_data['data']['general']['usage_of_comparison_tests'] = { 'val': { 'value': 'yes', 'label': 'evet' } };
        pre_data['data']['general']['selected_nominal_to_nominal'] = {};
        pre_data['data']['general']['selected_nominal_to_nominal']['pearson_correlation'] = { 'name': { 'value': 'pearson_correlation', 'label': 'pearson korelasyon' }, 'detail': {} };
      } else if (easy_stats_type === 'numeric_numeric_spearman') {
        pre_data['data']['general']['model_name'] = { 'val': 'Sayısal verilerle sayısal verilerin karşılaştırılarak korelasyon değerlendirilmesi' };
        pre_data['data']['general']['cross_column_type'] = { 'val': { 'value': 'numeric_numeric', 'label': 'sayısal & sayısal' } };
        pre_data['data']['general']['usage_of_comparison_tests'] = { 'val': { 'value': 'yes', 'label': 'evet' } };
        pre_data['data']['general']['selected_nominal_to_nominal'] = {};
        pre_data['data']['general']['selected_nominal_to_nominal']['spearman'] = { 'name': { 'value': 'spearman', 'label': 'spearman' }, 'detail': {} };
      } else if (easy_stats_type === 'numeric_numeric_pairs_sample_t_test') {
        pre_data['data']['general']['model_name'] = { 'val': 'Sayısal verinin önce -> sonra değişim analizi' };
        pre_data['data']['general']['cross_column_type'] = { 'val': { 'value': 'numeric_numeric', 'label': 'sayısal & sayısal' } };
        pre_data['data']['general']['before_after'] = {
          'list': [{
            'selected_before_after_statistical_test': {
              'ttest_relation_paired_samples': { 'name': { 'value': 'ttest_relation_paired_samples', 'label': 'bağımlı gruplar t testi (paired samples)' }, 'detail': {} }
            }
          }]
        };
      }
      // numeric & categorical
      if (easy_stats_type === 'numeric_categorical_logistic_regression') {
        pre_data['data']['general']['model_name'] = { 'val': 'Verilerle 2 kategorik grup arasındaki ilişkinin logistic regresyon ile değerlendirilmesi.' };
        pre_data['data']['general']['usage_of_comparison_tests'] = { 'val': { 'value': 'no', 'label': 'hayır' } };
        pre_data['data']['general']['cross_column_type'] = { 'val': { 'value': 'numeric_categorical', 'label': 'sayısal & categorical' } };
        pre_data['data']['general']['one_or_multi_independent_one_dependent_categorical_selected'] = {};
        pre_data['data']['general']['one_or_multi_independent_one_dependent_categorical_selected']['logistic_regression'] = {
          'name': { 'value': 'logistic_regression', 'label': 'Lojistik Regresyon' },
          'detail': {
            'oomiodc_variable_selection': {
              'val': [{ 'value': '1', 'label': 'tüm değişkenleri hedef değişken ile toplu analiz yap' }]
            }
          }
        };
      }
      if (type === 'detailed_descriptive_stats') {
        pre_data['data']['general']['model_name'] = { 'val': 'Detaylı Tanımlayıcı İstatistikler' };
        if (easy_stats_type === 'i_will_select_columns') {
          pre_data['data']['general']['column_selection_type'] = { 'val': { 'value': 'manuel_column_selection', 'label': 'manuel kolon seçimi' } };
          pre_data['data']['general']['descriptive_stats'] = {
            'mean': { 'name': { 'value': 'mean', 'label': 'ortalama' }, 'detail': {} },
            'median': { 'name': { 'value': 'median', 'label': 'ortanca (medyan)' }, 'detail': {} },
            'standart_deviation': { 'name': { 'value': 'standart_deviation', 'label': 'standart sapma' }, 'detail': {} },
            'variance': { 'name': { 'value': 'variance', 'label': 'varyans' }, 'detail': {} },
            'min': { 'name': { 'value': 'min', 'label': 'minimum' }, 'detail': {} },
            'max': { 'name': { 'value': 'max', 'label': 'maksimum' }, 'detail': {} },
            'kurtosis': { 'name': { 'value': 'kurtosis', 'label': 'veri dağılımı (basıklık, kurtosis)' }, 'detail': {} },
            'skewness': { 'name': { 'value': 'skewness', 'label': 'veri dağılımı (eğim, skewness)' }, 'detail': {} },
            'frequency': { 'name': { 'value': 'frequency', 'label': 'sıklık' }, 'detail': {} },
            'kolmogorov_smirnov': { 'name': { 'value': 'kolmogorov_smirnov', 'label': 'veri dağılımı (kolmogrov smirnov)' }, 'detail': {} },
            'shapiro_wilk': { 'name': { 'value': 'shapiro_wilk', 'label': 'shapiro wilk' }, 'detail': {} },
            'outliers_zindex': { 'name': { 'value': 'outliers_zindex', 'label': 'aykırı değer saptama (outliers) z-index' }, 'detail': {} }
          };
        } else if (easy_stats_type === 'i_will_select_columns_test_group_1') {
          pre_data['data']['general']['column_selection_type'] = { 'val': { 'value': 'manuel_column_selection', 'label': 'manuel kolon seçimi' } };
          pre_data['data']['general']['descriptive_stats'] = {
            'mean': { 'name': { 'value': 'mean', 'label': 'ortalama' }, 'detail': {} },
            'median': { 'name': { 'value': 'median', 'label': 'ortanca (medyan)' }, 'detail': {} },
            'standart_deviation': { 'name': { 'value': 'standart_deviation', 'label': 'standart sapma' }, 'detail': {} },
            'min': { 'name': { 'value': 'min', 'label': 'minimum' }, 'detail': {} },
            'max': { 'name': { 'value': 'max', 'label': 'maksimum' }, 'detail': {} },
            'kurtosis': { 'name': { 'value': 'kurtosis', 'label': 'veri dağılımı (basıklık, kurtosis)' }, 'detail': {} },
            'skewness': { 'name': { 'value': 'skewness', 'label': 'veri dağılımı (eğim, skewness)' }, 'detail': {} },
            'frequency': { 'name': { 'value': 'frequency', 'label': 'sıklık' }, 'detail': {} },
            'shapiro_wilk': { 'name': { 'value': 'shapiro_wilk', 'label': 'shapiro wilk' }, 'detail': {} }
          };
        } else if (easy_stats_type === 'numeric_columns') {
          // pre_data['data']['general']['selected_columns'] = this.f_addColumnsByParamTypes(['integer', 'float']);
          pre_data['data']['general']['column_selection_type'] = { 'val': { 'value': 'all_numeric_columns', 'label': 'tüm numeric kolonlar' } };
          pre_data['data']['general']['descriptive_stats'] = {
            'mean': { 'name': { 'value': 'mean', 'label': 'ortalama' }, 'detail': {} },
            'median': { 'name': { 'value': 'median', 'label': 'ortanca (medyan)' }, 'detail': {} },
            'standart_deviation': { 'name': { 'value': 'standart_deviation', 'label': 'standart sapma' }, 'detail': {} },
            'variance': { 'name': { 'value': 'variance', 'label': 'varyans' }, 'detail': {} },
            'min': { 'name': { 'value': 'min', 'label': 'minimum' }, 'detail': {} },
            'max': { 'name': { 'value': 'max', 'label': 'maksimum' }, 'detail': {} },
            'kurtosis': { 'name': { 'value': 'kurtosis', 'label': 'veri dağılımı (basıklık, kurtosis)' }, 'detail': {} },
            'skewness': { 'name': { 'value': 'skewness', 'label': 'veri dağılımı (eğim, skewness)' }, 'detail': {} },
            'kolmogorov_smirnov': { 'name': { 'value': 'kolmogorov_smirnov', 'label': 'veri dağılımı (kolmogrov smirnov)' }, 'detail': {} },
            'shapiro_wilk': { 'name': { 'value': 'shapiro_wilk', 'label': 'veri dağılımı (shapiro_wilk)' }, 'detail': {} },
            'outliers_zindex': { 'name': { 'value': 'outliers_zindex', 'label': 'aykırı değer saptama (outliers) z-index' }, 'detail': {} }
          };

        } else if (easy_stats_type === 'categorical_columns') {
          pre_data['data']['general']['column_selection_type'] = { 'val': { 'value': 'all_categorical_columns', 'label': 'tüm kategorik kolonlar' } };
          // pre_data['data']['general']['selected_columns'] = this.f_addColumnsByParamTypes(['select', 'radiogroup', 'object_list_options', 'checkbox']);
          pre_data['data']['general']['descriptive_stats'] = {
            'frequency': { 'name': { 'value': 'frequency', 'label': 'sıklık' }, 'detail': {} }
          };
        } else if (easy_stats_type === 'all_columns') {
          pre_data['data']['general']['column_selection_type'] = { 'val': { 'value': 'all_columns', 'label': 'tüm kolonlar' } };
          // pre_data['data']['general']['selected_columns'] = this.f_addColumnsByParamTypes('all');
          pre_data['data']['general']['descriptive_stats'] = {
            'mean': { 'name': { 'value': 'mean', 'label': 'ortalama' }, 'detail': {} },
            'median': { 'name': { 'value': 'median', 'label': 'ortanca (medyan)' }, 'detail': {} },
            'standart_deviation': { 'name': { 'value': 'standart_deviation', 'label': 'standart sapma' }, 'detail': {} },
            'variance': { 'name': { 'value': 'variance', 'label': 'varyans' }, 'detail': {} },
            'min': { 'name': { 'value': 'min', 'label': 'minimum' }, 'detail': {} },
            'max': { 'name': { 'value': 'max', 'label': 'maksimum' }, 'detail': {} },
            'kurtosis': { 'name': { 'value': 'kurtosis', 'label': 'veri dağılımı (basıklık, kurtosis)' }, 'detail': {} },
            'skewness': { 'name': { 'value': 'skewness', 'label': 'veri dağılımı (eğim, skewness)' }, 'detail': {} },
            'frequency': { 'name': { 'value': 'frequency', 'label': 'sıklık' }, 'detail': {} },
            'kolmogorov_smirnov': { 'name': { 'value': 'kolmogorov_smirnov', 'label': 'veri dağılımı (kolmogrov smirnov)' }, 'detail': {} },
            'shapiro': { 'name': { 'value': 'shapiro', 'label': 'veri dağılımı (shapiro)' }, 'detail': {} },
            'outliers_zindex': { 'name': { 'value': 'outliers_zindex', 'label': 'aykırı değer saptama (outliers) z-index' }, 'detail': {} }
          };
        } else if (easy_stats_type === 'all_columns_test_group_1') {
          pre_data['data']['general']['column_selection_type'] = { 'val': { 'value': 'all_columns', 'label': 'tüm kolonlar' } };
          pre_data['data']['general']['descriptive_stats'] = {
            'mean': { 'name': { 'value': 'mean', 'label': 'ortalama' }, 'detail': {} },
            'median': { 'name': { 'value': 'median', 'label': 'ortanca (medyan)' }, 'detail': {} },
            'standart_deviation': { 'name': { 'value': 'standart_deviation', 'label': 'standart sapma' }, 'detail': {} },
            'min': { 'name': { 'value': 'min', 'label': 'minimum' }, 'detail': {} },
            'max': { 'name': { 'value': 'max', 'label': 'maksimum' }, 'detail': {} },
            'kurtosis': { 'name': { 'value': 'kurtosis', 'label': 'veri dağılımı (basıklık, kurtosis)' }, 'detail': {} },
            'skewness': { 'name': { 'value': 'skewness', 'label': 'veri dağılımı (eğim, skewness)' }, 'detail': {} },
            'frequency': { 'name': { 'value': 'frequency', 'label': 'sıklık' }, 'detail': {} },
            'shapiro_wilk': { 'name': { 'value': 'shapiro_wilk', 'label': 'shapiro wilk' }, 'detail': {} }
          };
        }
      }
      this.f_createWdm72ColList();
      this.d_statisticWisdomData = pre_data;
      this.d_showStatisticsModal = true;
      // this.d_showStatsSelect = true;
      this.$store.commit('Loading', { 'status': false, 'data': {} });
    },
    f_selectAnalyze: function (type, easy_stats_type = '', column_selection_type = '') {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Analiz Modeliniz hazırlanıyor. Lütfen bekleyiniz.' } });
      setTimeout(function () {
        this.f_prepareWdm72(type, easy_stats_type, column_selection_type);
      }.bind(this), 500)
    },
    f_motherChildren: function (op, project_data = {}) {
      if (op === 'update_project_data') {
        this.mother_children.d_selectedProjectData = project_data;
      }
      if (this.mother_children[op]) {
        this.mother_children[op] = 0;
      } else {
        this.mother_children[op] = 1;
      }
    },
    f_analyzeProjectCellFillMethods: function () {
      let data = {
        'wdm3_id': this.p_selectedProjectData.id
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Analiz Yapılıyor. Lütfen bekleyiniz.' } });
      PoleStarService.analyze_project_cell_fill_methods(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          let msg = '';
          if (resp.data.status === 'success') {
            msg = 'Analiz işlemleri tamamlandı.';
            this.f_motherChildren('get_project_cases');
            // this.p_selectedProjectData.design.column_list = resp.data.result.column_list;
            // this.column_list.splice(0, this.column_list.length);
          } else {
            msg = resp.data.message;
          }
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Hücre doldurma yöntemi analizi hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        });
    },
    f_convertAllDataSetToExcel: function () {
      if (this.user && this.user.permissions_result && this.user.permissions_result.indexOf('polestar_wisdomstats_convert-all-dataset-to-excel') !== -1) {
        let data = { 'project_id': this.p_selectedProjectData.id };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Excel dökümanınız hazırlanıyor. Lütfen bekleyiniz.' } });
        FiletransferService.convert_all_dataset_to_excel(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status === 'success') {
              FileSaver.saveAs(resp.data.result.file, "dataset.xlsx");
            } else {
              alert('errror:', resp.data.message);
            }
          });
      } else {
        let msg = 'Verileri export edebilmeniz için yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Veri export işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_convertAllDataSetToCsv: function () {
      if (this.user && this.user.permissions_result && this.user.permissions_result.indexOf('polestar_wisdomstats_convert-all-dataset-to-excel') !== -1) {
        let data = { 'project_id': this.p_selectedProjectData.id };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Csv dökümanınız hazırlanıyor. Lütfen bekleyiniz.' } });
        FiletransferService.convert_all_dataset_to_csv(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status === 'success') {
              FileSaver.saveAs(resp.data.result.file, "dataset.csv");
            } else {
              alert('errror:', resp.data.message);
            }
          });
      } else {
        let msg = 'Verileri export edebilmeniz için yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Veri export işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_convertSelectedDataSetToExcel: function () {
      if (this.user && this.user.permissions_result && this.user.permissions_result.indexOf('polestar_wisdomstats_convert-selected-dataset-to-excel') !== -1) {
        let data = { 'column_list': [], 'dataset': this.d_dataset.case_rows };
        for (let c in this.column_list) {
          if (this.column_list[c].type === 'wdm') {
            data.column_list.push(this.column_list[c].loc[this.column_list[c].loc.length - 1].label);
          } else {
            data.column_list.push(this.column_list[c].label);
          }
        }
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Excel dökümanınız hazırlanıyor. Lütfen bekleyiniz.' } });
        FiletransferService.convert_dataset_to_excel(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status === 'success') {
              FileSaver.saveAs(resp.data.result.file, "dataset.xlsx");
            } else {
              alert('errror:', resp.data.message);
            }
          })
      } else {
        let msg = 'Verileri export edebilmeniz için yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Veri export işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_selectMenu: function (menu) {
      this.d_selectedMenu = menu.value;
    }
  }
};

</script>

